import React from 'react';
import styled from 'styled-components/macro';
import Table from '../molecules/Table';
import Icons from './Icons';

const ColumnWrapper = styled(Table.ColWrap as 'div')<{
  isNotSortable: boolean;
  shouldMakeColHeadBold: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  span {
    ${({ shouldMakeColHeadBold, theme }) =>
      shouldMakeColHeadBold && `font-weight: 500 !important;`}
  }
  svg g {
    ${({ shouldMakeColHeadBold, theme }) =>
      shouldMakeColHeadBold &&
      `stroke: ${theme.colors.charcoal};
       stroke-width: 1;
      `}
  }
  ${({ isNotSortable }) => isNotSortable && 'cursor: default;'}
`;
function HeadCol(props: any) {
  const {
    field,
    handleCols,
    isNotSortable,
    shouldMakeColHeadBold,
    setShouldStartSortAfterClick,
    modifiers,
  } = props;
  const handleClick = () => {
    if (isNotSortable) return;
    setShouldStartSortAfterClick && setShouldStartSortAfterClick(true);
    handleCols(field.id, !field.isSortDesc);
  };

  return (
    <ColumnWrapper
      data-testid={`sortColumn${field.displayName}`}
      onClick={handleClick}
      {...{ shouldMakeColHeadBold, isNotSortable }}
    >
      <Table.ColData {...{ modifiers }}>{field.displayName}</Table.ColData>
      {!isNotSortable && (
        <Icons.Arrow modifiers={['small', field.isSortDesc && 'down']} />
      )}
    </ColumnWrapper>
  );
}

export default HeadCol;
