import {
  Switch as AntSwitcher,
  ConfigProvider,
  SwitchProps,
  ThemeConfig,
} from 'antd';

type Props = SwitchProps & {
  theme?: ThemeConfig;
};

export function Switcher(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntSwitcher {...rest} />
    </ConfigProvider>
  );
}
