import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  COLORS,
  ConfigProvider,
  Drawer,
  Form,
  FormItem,
  Segmented,
  SegmentedProps,
  Select,
  useForm,
  useWatch,
} from '@optii/ui-library';
import {
  assetListWithNotes,
  debounce,
  PredictJobDurationMutationVariables,
  Priorities,
  ServiceActions,
  Statuses,
  useAssetsQuery,
  useDepartmentsQuery,
  useJobDefaultsLazyQuery,
  useJobSettingsQuery,
  usePredictJobDurationMutation,
  usePropertyQuery,
  useRolesQuery,
  type SelectOption,
} from '@optii/global';
import { useTranslation } from 'react-i18next';
import {
  AddJobMutationVariables,
  JobType,
  useAddJobMutation,
} from '@optii/jobs/api/jobs';
import dayjs, { Dayjs } from 'dayjs';
import { Session, UserAccessContext } from '@optii/shared';
import { Title } from './Title';
import { FormFields } from './formFields';
import { Footer } from './Footer';
import { FormValues } from './types';
import { ACTION_OPTIONS } from './formFields/constants';
import { getItems } from '../JobForm/JobForm.helpers';

type JobFormProps = {
  properties: SelectOption[];
  open: boolean;
  onClose: () => void;
  mode?: 'add' | 'edit';
};

const FIELDS_TO_RESET = [
  'items',
  'locations',
  'asset',
  'department',
  'role',
  'assignee',
  'checklistTemplates',
];

export function JobForm({
  properties,
  open,
  onClose,
  mode = 'add',
}: JobFormProps) {
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['common', 'jobs']);
  const [form] = useForm();
  const { user } = useContext(UserAccessContext.Context);
  const shard = useWatch('property', form);

  const isAPConsole = window.document.URL.includes(
    'above-property/jobs-management',
  );

  const { data, loading: isPropertyLoading } = usePropertyQuery({
    variables: {
      id: shard,
    },
    skip: !shard && !isAPConsole,
  });

  const skip = isAPConsole ? !shard : false;

  const timezone = data?.property.regionalSettings?.timeZone;

  const [suggestion, setSuggestion] = useState<Dayjs | undefined>(undefined);

  const TODAY_TIME = useMemo(() => dayjs().tz(timezone), [timezone]);

  const { data: jobSettingsData } = useJobSettingsQuery({
    onError(error) {
      console.error(error);
    },
    context: {
      _shard: isAPConsole ? data?.property?.id : '',
    },
    onCompleted({ jobSettings }) {
      const jobType = form.getFieldValue('jobType');

      form.setFieldValue(
        'doBy',
        jobType === JobType.Guest
          ? TODAY_TIME.add(jobSettings?.guestRequestMinutes, 'minutes')
          : TODAY_TIME.add(jobSettings?.internalRequestMinutes, 'minutes'),
      );
    },
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const { data: assetsData } = useAssetsQuery({
    context: {
      _shard: isAPConsole ? shard : '',
      _instance: 'node',
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip,
    onError(error) {
      console.error(error);
    },
  });

  const { data: departmentsData, loading: departmentsLoading } =
    useDepartmentsQuery({
      context: {
        _shard: isAPConsole ? shard : '',
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      skip,
      onError(error) {
        console.error(error);
      },
    });

  const departments =
    departmentsData?.page.edges?.map(
      ({ node: { label, value, departmentCode } }) => ({
        label,
        value,
        departmentCode,
      }),
    ) || [];

  const { data: rolesData, loading: rolesLoading } = useRolesQuery({
    context: {
      _shard: isAPConsole ? shard : '',
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip,
    onError(error) {
      console.error(error);
    },
  });

  const roles =
    rolesData?.page.edges?.map(({ node: { label, value, description } }) => ({
      label,
      value,
      description,
    })) || [];

  const [addJob, { loading }] = useAddJobMutation({
    context: {
      _shard: isAPConsole ? shard : '',
    },
    onCompleted({ addJob: response }) {
      const action = ACTION_OPTIONS(t).find(
        (actionOption) => response.action === actionOption.value,
      )?.label;
      const items = response.items as { name: string; amount: number }[];

      const message = action
        ? `#${response.id} ${action} ${getItems(items)} ${t('common:has been added')}`
        : `#${response.id} ${getItems(items)} ${response.locations[0]?.shortDisplayName} ${t('common:has been added')}`;

      globalSnack({
        message,
        timeout: 5000,
      });

      onClose();
      form.resetFields();
    },
    onError(error) {
      console.error(error);
      globalSnack({
        message: t('jobs:Something went wrong attempting to create the Job'),
        error: true,
        timeout: 5000,
      });
    },
  });

  const [getJobDefaults] = useJobDefaultsLazyQuery({
    onCompleted({ defaults }) {
      const role = roles.find(({ value }) => value === defaults?.role?.id);
      const department = departments.find(
        ({ value }) => value === defaults?.department?.value,
      );

      const values = {
        durationMinutes: defaults?.durationMin,
        creditValue: defaults?.creditValue,
        checklistTemplates: defaults?.checklistsTemplate,
        department: department
          ? {
              id: department.value,
              displayName: department.label,
              departmentCode: department.departmentCode,
            }
          : undefined,
        role: role
          ? {
              id: role.value,
              name: role.label,
              description: role.description,
            }
          : undefined,
      };

      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];

        if (value) {
          form.setFieldValue(key, value);
        }
      });
    },
    onError(error) {
      console.error(error);
    },
  });

  const [predictJobDuration, { loading: suggestionLoading }] =
    usePredictJobDurationMutation({
      context: {
        _shard: isAPConsole ? shard : '',
        _instance: 'node',
      },
      onError(error) {
        console.error(error);
      },
    });

  const assets = assetsData?.page.edges?.map(({ node }) => node);

  const locationsWithAssetType = assetListWithNotes(assets || []).map(
    (locationWithAsset) => ({
      value: locationWithAsset.value,
      label: locationWithAsset.location?.longDisplayName,
      notes: locationWithAsset?.notes,
      assetTypeDisplayName: locationWithAsset.assetType?.displayName,
      item: locationWithAsset.assetType?.jobItem?.label,
      location: locationWithAsset.location,
    }),
  );

  const JOB_TYPE_OPTIONS: SegmentedProps['options'] = [
    {
      value: JobType.Guest,
      label: t('jobs:Guest Request'),
    },
    {
      value: JobType.Internal,
      label: t('jobs:Internal Request'),
    },
    {
      value: JobType.Housekeeping,
      label: t('jobs:Housekeeping'),
    },
  ];

  const onPopulateJobDefaults = async (
    { items, locations, jobType, action }: FormValues,
    changedValues: { [key: string]: unknown },
  ) => {
    const changedFieldKeys = Object.keys(changedValues);

    if (
      !changedFieldKeys.includes('items') &&
      !changedFieldKeys.includes('location') &&
      !changedFieldKeys.includes('action')
    )
      return;

    const locationID = locations?.[0]?.id
      ? Number(locations?.[0]?.id)
      : undefined;
    const jobItem = items?.[0]?.name;

    if (
      jobType === JobType.Housekeeping &&
      locations &&
      mode === 'add' &&
      action
    ) {
      await getJobDefaults({
        variables: {
          locationID,
          type: jobType,
          action,
          item: jobItem,
        },
      });
    } else if (mode === 'add' && jobItem) {
      await getJobDefaults({
        variables: {
          type: jobType,
          action,
          item: jobItem,
        },
      });
    }
  };

  const formatDateSuggestion = useCallback(
    (value: string | number) => {
      let dateTime;

      const timezoneOffset = dayjs().tz(timezone).utcOffset();

      if (timezoneOffset === 0)
        dateTime = dayjs().add(Number(value), 'minutes').utc();
      else dateTime = dayjs().add(Number(value), 'minutes').tz(timezone);

      return dateTime;
    },
    [timezone],
  );

  const onPredictJobDuration = async (fields: FormValues) => {
    const jobItem = fields.items?.[0]?.name;
    const input: PredictJobDurationMutationVariables['input'] = {
      propertyId: Number(shard),
      jobType: fields.jobType,
      jobPriority: fields.priority,
      jobItem,
      locations: Array.isArray(fields.locations)
        ? fields.locations?.map((location: { name: string }) => ({
            name: location?.name,
          }))
        : fields.locations,
      jobAction: fields.action,
      reporterId: Number(user?.id),
      reporterName: `${user?.firstName} ${user?.lastName}`,
      assigneeId: Number(fields.assignee?.id),
      assigneeName: fields?.assignee
        ? `${fields.assignee?.firstName} ${fields.assignee?.lastName}`
        : undefined,
      roleId: Number(fields.role?.id),
      roleName: fields.role?.name,
      departmentId: Number(fields.department?.id),
      departmentName: fields.department?.displayName,
      attachmentCount: fields.attachments?.length,
      noteCount: fields.note?.length ? 1 : 0,
      displayJob: jobItem,
      timeZone: timezone,
      source: 'adhoc-jobs-mgr',
    };

    await predictJobDuration({
      variables: {
        input,
      },
      onCompleted({ predictJobDuration: response }) {
        const dateSuggestion = formatDateSuggestion(
          response.jobDuration + response.waitTime,
        );

        setSuggestion(dateSuggestion);
      },
      onError(error) {
        console.error(error);
      },
    });
  };

  const debouncedPredictJobDuration = debounce(onPredictJobDuration, 500);

  const onFinish = async (values: FormValues) => {
    const doBy = values.doBy?.utc().unix() || TODAY_TIME.utc().unix();

    const checklistTemplates = values.checklistTemplates?.map((item, i) => ({
      id: item.id,
      order: i,
    }));

    const attachments = values.attachments?.map((item) => item.uid);

    let locations;
    let metadata;
    if (values.asset) {
      const selectedLocationWithAsset = locationsWithAssetType.find(
        (item) => item.value === values.asset,
      );
      locations = [
        {
          id: selectedLocationWithAsset?.location?.id,
          type:
            selectedLocationWithAsset?.location.shortDisplayName ||
            selectedLocationWithAsset?.location?.displayName,
          name: selectedLocationWithAsset?.location?.id,
        },
      ];
      metadata = {
        publicAttributes: {
          asset: {
            id: values.asset,
          },
        },
      };
    } else {
      locations = values.locations;
    }

    const input: AddJobMutationVariables['input'] = {
      type: values.jobType,
      action: values.action,
      items: values.items,
      locations,
      status: values.status,
      priority: values.priority,
      doBy,
      scheduleStartTimeAt: TODAY_TIME.utc().unix(),
      department: values.department,
      role: values.role,
      assignee: values.assignee,
      checklistTemplates,
      note: values.note,
      attachments,
      metadata,
    };
    await addJob({
      variables: {
        input,
      },
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: COLORS.neutral[5],
          },
        },
      }}
    >
      <Drawer
        title={
          <Title
            jobType="job"
            mode={mode}
            onClose={() => {
              onClose();
              form.resetFields();
            }}
          />
        }
        open={open}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        destroyOnClose
        width={675}
        data-testid="jobFormDrawer"
        footer={
          <Footer
            form={form}
            loading={loading}
            onClose={() => {
              onClose();
              form.resetFields();
            }}
          />
        }
      >
        <Form
          form={form}
          name="jobForm"
          onFinish={onFinish}
          initialValues={{
            jobType: JobType.Guest,
            priority: Priorities.high,
            status: Statuses.not_started,
            action: ServiceActions.noAction,
            doBy: TODAY_TIME.add(
              jobSettingsData?.jobSettings?.guestRequestMinutes || 0,
              'minutes',
            ),
            scheduleStartTimeAt: TODAY_TIME.utc().unix(),
            locations: [],
            items: [
              {
                amount: 1,
              },
            ],
          }}
          onValuesChange={(changedValues, values) => {
            debouncedPredictJobDuration(values);
            onPopulateJobDefaults(values, changedValues);
            if (changedValues?.jobType && mode !== 'edit') {
              if (changedValues?.jobType === JobType.Guest) {
                form.setFieldValue('priority', Priorities.high);
                form.setFieldValue(
                  'doBy',
                  TODAY_TIME.add(
                    jobSettingsData?.jobSettings.guestRequestMinutes,
                    'minutes',
                  ),
                );
              } else {
                form.setFieldValue('priority', Priorities.medium);
                form.setFieldValue(
                  'doBy',
                  TODAY_TIME.add(
                    jobSettingsData?.jobSettings.internalRequestMinutes,
                    'minutes',
                  ),
                );
              }
            }
          }}
          data-testid="jobForm"
          scrollToFirstError={{
            block: 'end',
            behavior: 'smooth',
          }}
          autoComplete="off"
        >
          <FormItem
            name="property"
            label={t('fields:Property')}
            required
            rules={[
              {
                required: true,
                message: t('common:This is a required field.'),
              },
            ]}
          >
            <Select
              data-testid="properties"
              placeholder="Properties"
              options={properties.sort((a, b) =>
                a.label.localeCompare(b.label),
              )}
              onChange={() => {
                form.resetFields(FIELDS_TO_RESET);
              }}
              allowClear
              showSearch
              optionFilterProp="label"
            />
          </FormItem>

          <FormItem
            name="jobType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Segmented
              data-testid="jobType"
              options={JOB_TYPE_OPTIONS}
              block
              onChange={(value) => {
                const currentLocations = form.getFieldValue('locations');
                if (value === 'guest' || value === 'internal') {
                  form.setFieldValue(
                    'locations',
                    Array.isArray(currentLocations)
                      ? currentLocations
                      : [currentLocations],
                  );
                }
              }}
            />
          </FormItem>

          <FormItem
            noStyle
            shouldUpdate={(prev, curr) => prev.jobType !== curr.jobType}
          >
            {({ getFieldValue }) =>
              !isPropertyLoading ? (
                <FormFields
                  assets={assets}
                  suggestion={suggestion}
                  roles={roles}
                  rolesLoading={rolesLoading}
                  departments={departments}
                  departmentsLoading={departmentsLoading}
                  suggestionLoading={suggestionLoading}
                  locationsWithAssetType={locationsWithAssetType}
                  form={form}
                  jobType={getFieldValue('jobType')}
                  shard={shard}
                />
              ) : null
            }
          </FormItem>
        </Form>
      </Drawer>
    </ConfigProvider>
  );
}
