import {
  COLORS,
  FONTS,
  ThemeConfig,
  CardProps,
  FormInstance,
} from '@optii/ui-library';

export type FormProps = {
  formKey: FormKeys;
  form: FormInstance<any>;
};

export enum FormKeys {
  generalSettings = 'generalSettings',
  optiiConnections = 'optiiConnections',
  connectionSettings = 'connectionSettings',
  guestPrivacySettings = 'guestPrivacySettings',
}

export const FORM_CARD_THEME: ThemeConfig = {
  components: {
    Card: {
      headerBg: COLORS.neutral[2],
      headerFontSize: FONTS.medium.size,
    },
  },
};

export const CARD_CONTAINER_STYLE: CardProps['style'] = {
  maxWidth: 800,
  width: '100%',
};

export const GLOBAL_CARD_STYLES: CardProps['styles'] = {
  title: {
    fontWeight: 500,
    letterSpacing: FONTS.medium.letterSpacing,
  },
};
