import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import CrossIcon from './Cross';

const ClearContainer = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.charcoal03};
  padding: 0 0.7rem;
  margin-right: 0.7rem;
  svg {
    margin: 0 auto;
    padding: 0;
  }
`;
function ClearIndicator(props) {
  const { getValue } = props;

  const { t } = useTranslation(['fields']);
  const value = getValue();
  const hasValue = value && value[0].id;
  if (!hasValue) {
    return null;
  }
  const {
    children = (
      <ClearContainer>
        <CrossIcon title={t('fields:Clear')} />
      </ClearContainer>
    ),
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} data-testid="clear-indicator">
      {children}
    </div>
  );
}

export default ClearIndicator;
