import { useCallback, useContext, useEffect, useState } from 'react';
import { Client, Conversation } from '@twilio/conversations';
import dayjs from 'dayjs';
import { PropertyContext } from '@optii/shared';
import { SelectOption } from '../components/message/types';
import { Channel } from '../types';
import { getAllConversationList } from '../utils/message';
import { PRIVATE_CHAT_REGEX } from '../constants';
import { EmployeeStatus } from '../api/data';

const GENERAL_CHANNEL_FRIENDLY_NAME = 'All Team Members';
async function mapPrivateConversation(
  conversation: Conversation,
  employees?: SelectOption[],
  userId?: string,
) {
  const unreadMessageCount = await conversation.getUnreadMessagesCount();

  const allUnreadMessages = conversation.lastMessage?.index
    ? conversation.lastMessage.index + 1
    : 0;

  const unreadMessagesCount =
    unreadMessageCount === null ? allUnreadMessages : unreadMessageCount;

  const recipientIds = conversation.uniqueName
    ?.split('_')
    .filter((item) => item !== userId);

  const inactiveEmployees = employees
    ?.filter((item) => item.status !== EmployeeStatus.Active)
    .filter((item) => recipientIds?.includes(item.value));

  const employeeNames = Array.isArray(recipientIds)
    ? recipientIds
        ?.map((recipient) => {
          const employeeName = employees?.find(
            (item) => item.value === recipient,
          )?.label;
          return employeeName;
        })
        .join(', ')
    : conversation.uniqueName;

  const lastMessageIndex = conversation.lastMessage;
  const getMessage = await conversation.getMessages(
    1,
    lastMessageIndex?.index,
    'backwards',
  );

  const lastMessage = {
    content: getMessage.items[0]?.body,
    timestamp: dayjs(getMessage.items[0]?.dateUpdated),
  };

  const label = conversation.friendlyName?.match(PRIVATE_CHAT_REGEX)
    ? employeeNames
    : conversation.friendlyName;

  return {
    key: conversation.sid,
    uniqueName: conversation.uniqueName,
    recipientIds,
    inactiveEmployees,
    label,
    unreadMessageCount: unreadMessagesCount,
    lastMessage,
  };
}

async function mapPublicConversation(conversation: Conversation) {
  const unreadMessageCount = await conversation.getUnreadMessagesCount();

  const allUnreadMessages = conversation.lastMessage?.index
    ? conversation.lastMessage.index + 1
    : 0;

  const unreadMessagesCount =
    unreadMessageCount === null ? allUnreadMessages : unreadMessageCount;

  return {
    key: conversation.sid,
    label: conversation.friendlyName,
    uniqueName: conversation.uniqueName,
    unreadMessageCount: unreadMessagesCount,
  };
}

enum ChannelType {
  'Public' = 'Public',
}

type Attributes = {
  ChannelType: ChannelType;
};

type ChannelState = {
  publicChannels: Channel[];
  privateChannels: Channel[];
};

async function formatConversations(
  channelType: string,
  conversations: Conversation[],
  employees?: SelectOption[],
  userId?: string,
) {
  let result: Channel[];
  if (channelType === 'Public') {
    result = await Promise.all(
      conversations
        .filter(
          (conversation) => (conversation.attributes as Attributes).ChannelType,
        )
        .map(mapPublicConversation),
    );
  } else {
    result = await Promise.all(
      conversations
        .filter(
          (conversation) =>
            !(conversation.attributes as Attributes).ChannelType &&
            conversation.friendlyName !== userId,
        )
        .map(async (conversation) =>
          mapPrivateConversation(conversation, employees, userId),
        ),
    );
  }
  const findGeneralChannel = result.find((item) =>
    item.label
      ?.toLowerCase()
      .includes(GENERAL_CHANNEL_FRIENDLY_NAME.toLowerCase()),
  );

  result.sort((a, b) => {
    if (a.label && b.label) {
      return a.label.localeCompare(b.label);
    }
    return 0;
  });

  if (findGeneralChannel) {
    result = [findGeneralChannel].concat(
      result.filter(
        (conversation) =>
          !conversation.label
            ?.toLowerCase()
            .includes(GENERAL_CHANNEL_FRIENDLY_NAME.toLowerCase()),
      ),
    );
  }

  return result.filter((item) => item.label);
}

export default function useChat(
  client: Client | null,
  employees: SelectOption[],
) {
  const { property } = useContext(PropertyContext.Context);
  const [channels, setChannels] = useState<ChannelState>({
    privateChannels: [],
    publicChannels: [],
  });
  const getChannelById = useCallback(
    async (sid: string): Promise<Conversation | undefined> => {
      const currentChannel = await client?.getConversationBySid(sid);

      return currentChannel;
    },

    [client],
  );

  const getAllChannels = useCallback(async () => {
    const conversations = await client?.getSubscribedConversations();
    const allConversations = await getAllConversationList(
      conversations,
      conversations?.hasNextPage,
    );

    const privateChannels = await formatConversations(
      'Private',
      allConversations,
      employees,
      client?.user.identity,
    );

    const publicChannels = await formatConversations(
      'Public',
      allConversations,
      employees,
    );

    setChannels({
      privateChannels,
      publicChannels,
    });
  }, [client, employees]);

  useEffect(() => {
    if (client) {
      getAllChannels();
    }
  }, [getAllChannels, client, property]);

  return {
    channels,
    setChannels,
    employees,
    getChannelById,
    mapPrivateConversation,
    mapPublicConversation,
  };
}
