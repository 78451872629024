import {
  ConfigProvider,
  Divider,
  ThemeConfig,
  Typography,
  COLORS,
  FONTS,
  SPACING,
  Card,
  Select,
  Menu,
  Form,
  MenuProps,
  FormItem,
  Flex,
  Row,
  Col,
  Switcher,
  Button,
  useForm,
} from '@optii/ui-library';
import React, { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Forms } from './Forms';
import { FormKeys } from './constants';

const MOCK_DATA = {
  applications: [
    {
      label: 'Infor',
      value: 1,
    },
    {
      label: 'Opera Cloud',
      value: 2,
    },
    {
      label: 'Maestro',
      value: 3,
    },
    {
      label: 'Mews',
      value: 4,
    },
    {
      label: 'Insightful',
      value: 5,
    },
    {
      label: 'Exclusivi',
      value: 6,
    },
  ],
  properties: [
    {
      label: 'Santa Maria Inn',
      value: 1,
    },
    {
      label: 'Red Hot Chilli Peppers',
      value: 2,
    },
    {
      label: 'Creed',
      value: 3,
    },
    {
      label: 'Bon Jovi',
      value: 4,
    },
    {
      label: 'Led Zeppelin',
      value: 5,
    },
  ],
};

const TITLE_STYLE: CSSProperties = {
  letterSpacing: FONTS.h3.letterSpacing,
  color: COLORS.neutral[8],
  fontFamily: 'Montserrat',
  fontWeight: 500,
  textAlign: 'unset',
  marginBlock: SPACING.SIZE_MD,
  marginInline: SPACING.SIZE_XL,
};

const PROPERTY_NAME_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  marginTop: SPACING.NONE,
  letterSpacing: FONTS.large.letterSpacing,
  fontWeight: 500,
};

const CREATE_APP_CONFIGURATION_THEME: ThemeConfig = {
  components: {
    Divider: {
      lineWidth: 2,
      colorSplit: COLORS.neutral[7],
    },
    Typography: {
      titleMarginTop: SPACING.NONE,
    },
    Select: {
      colorTextPlaceholder: COLORS.neutral[6],
    },
    Menu: {
      colorSplit: COLORS.neutral[1],
    },
  },
};

const items: MenuProps['items'] = [
  {
    label: 'General Settings',
    key: FormKeys.generalSettings,
  },
  {
    label: 'Optii Connections',
    key: FormKeys.optiiConnections,
  },
  {
    label: 'Connection Settings',
    key: FormKeys.connectionSettings,
  },
  {
    label: 'Guest Privacy Settings (PII)',
    key: FormKeys.guestPrivacySettings,
  },
];
export function AppConfigurationForm() {
  const [configurationRecipient, setConfigurationRecipient] = useState({
    propertyName: undefined,
    applicationType: undefined,
  });
  const [selectedSettings, setSelectedSettings] = useState<FormKeys>(
    FormKeys.generalSettings,
  );

  const { t } = useTranslation(['common']);
  const history = useHistory();
  const [form] = useForm();

  const onHandleConfigurationRecipients = useCallback(
    (value: string, fieldKey: string) =>
      setConfigurationRecipient((prev) => ({
        ...prev,
        [fieldKey]: value,
      })),
    [],
  );
  return (
    <ConfigProvider theme={CREATE_APP_CONFIGURATION_THEME}>
      <Typography.Title level={3} style={TITLE_STYLE}>
        {MOCK_DATA.applications.find(
          (application) =>
            application.value === configurationRecipient.applicationType,
        )?.label || '[App Name]'}
        <Divider type="vertical" style={{ height: FONTS.h3.size }} />
        {MOCK_DATA.properties.find(
          (property) => property.value === configurationRecipient.propertyName,
        )?.label || '[Property Name]'}
      </Typography.Title>

      <Card>
        {Object.values(configurationRecipient).some((v) => !v) ? (
          <Row gutter={SPACING.SIZE_DEFAULT} align="middle">
            <Col md={8} lg={7}>
              <Typography.Text>
                Select a property and an application to continue:
              </Typography.Text>
            </Col>
            <Col md={8} lg={8}>
              <Select
                placeholder="Select Application"
                value={configurationRecipient.applicationType}
                onChange={(value) =>
                  onHandleConfigurationRecipients(value, 'applicationType')
                }
                style={{
                  width: '100%',
                }}
                options={MOCK_DATA.applications}
                optionFilterProp="label"
              />
            </Col>
            <Col md={8} lg={8}>
              <Select
                placeholder="Select Property"
                options={MOCK_DATA.properties}
                value={configurationRecipient.propertyName}
                style={{
                  width: '100%',
                }}
                onChange={(value) =>
                  onHandleConfigurationRecipients(value, 'propertyName')
                }
                optionFilterProp="label"
              />
            </Col>
          </Row>
        ) : null}

        {Object.values(configurationRecipient).every((v) => !!v) ? (
          <Row gutter={SPACING.SIZE_XXXL}>
            <Col span={6}>
              <Menu
                items={items}
                mode="vertical"
                defaultSelectedKeys={[selectedSettings]}
                selectedKeys={[selectedSettings]}
                onClick={({ key }) => setSelectedSettings(key as FormKeys)}
              />
            </Col>

            <Col span={18}>
              <Form
                name="pms-configuration"
                form={form}
                initialValues={{
                  generalSettings: {
                    applicationId: configurationRecipient.applicationType,
                  },
                }}
                onFinish={() => {
                  console.log('VALUES:', form.getFieldsValue());
                }}
                scrollToFirstError={{
                  block: 'end',
                  behavior: 'smooth',
                }}
                autoComplete="off"
              >
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    maxWidth: 800,
                    marginBottom: SPACING.SIZE_XL,
                  }}
                >
                  <Typography.Title level={5} style={PROPERTY_NAME_STYLE}>
                    {
                      MOCK_DATA.properties.find(
                        (property) =>
                          property.value ===
                          configurationRecipient.propertyName,
                      )?.label
                    }
                  </Typography.Title>

                  <FormItem name="status" noStyle>
                    <Switcher
                      checkedChildren={t('common:Active')}
                      unCheckedChildren={t('common:Inactive')}
                    />
                  </FormItem>
                </Flex>

                <Forms formKey={selectedSettings} form={form} />

                <Flex
                  align="center"
                  justify="flex-end"
                  gap={SPACING.SIZE_MD}
                  style={{
                    maxWidth: 800,
                    marginTop: SPACING.SIZE_XXL,
                  }}
                >
                  <Button
                    onClick={() =>
                      history.push('/above-property/app-configuration/')
                    }
                  >
                    {t('common:Cancel')}
                  </Button>

                  <Button type="primary" htmlType="submit">
                    {t('common:Save')}
                  </Button>
                </Flex>
              </Form>
            </Col>
          </Row>
        ) : null}
      </Card>
    </ConfigProvider>
  );
}
