import styled from 'styled-components/macro';

const Subtitle = styled.h4`
  width: 100%;
  text-align: left;
  margin-bottom: 1.4rem;
  font-size: 1.8rem;
`;

export default Subtitle;
