import ReactPlayer from 'react-player';
import {
  COLORS,
  ConfigProvider,
  Flex,
  Grid,
  Image,
  Typography,
} from '@optii/ui-library';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, RADIUS, SPACING } from '@optii-solutions/ui-library';
import { isPlatform } from '@ionic/react';
import { FileList } from '../../types';
import {
  generateBase64ImageFromVideo,
  isFileExtensionImageOrVideo,
  separateImagesAndTextAttachments,
} from '../../utils/message';
import { toolbarRender } from './InputFilePreview';

type MessageFilePreviewProps = {
  fileList: FileList[];
};

const { PreviewGroup } = Image;
const { useBreakpoint } = Grid;

function CustomImagePreview({
  url,
  name,
  isVideo,
}: {
  url: string;
  name: string;
  isVideo: boolean;
}) {
  const [videoThumbnail, setVideoThumbnail] = useState('');

  const isiOS = isPlatform('ios');

  useEffect(() => {
    if (isVideo) {
      (async () => {
        const response = await generateBase64ImageFromVideo(url, isiOS);

        setVideoThumbnail(response);
      })();
    }
  }, [isVideo, url, isiOS]);

  return (
    <Image
      src={isVideo ? videoThumbnail : url}
      alt={name}
      width={100}
      height={100}
      preview={{
        mask: <EyeOutlined />,
      }}
    />
  );
}

function TextPreview({
  name,
  onDownload,
}: {
  name: string;
  onDownload: () => void;
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: COLORS.neutral[8],
          },
          Button: {
            paddingBlock: SPACING.NONE,
            paddingInline: SPACING.NONE,
          },
        },
      }}
    >
      <Flex
        align="center"
        vertical={false}
        style={{
          border: `1px solid ${COLORS.neutral[5]}`,
          borderRadius: RADIUS.RADIUS_DEFAULT,
          backgroundColor: COLORS.neutral[1],
        }}
      >
        <FileTextOutlined
          style={{
            marginInline: SPACING.SIZE_DEFAULT,
            color: '#BFBFBF',
          }}
        />
        <Button
          type="link"
          onClick={onDownload}
          style={{
            marginRight: SPACING.SIZE_SM,
          }}
        >
          <Typography.Paragraph
            style={{
              maxWidth: 150,
              margin: SPACING.NONE,
            }}
            ellipsis={{
              tooltip: name,
            }}
          >
            {name}
          </Typography.Paragraph>
        </Button>
      </Flex>
    </ConfigProvider>
  );
}

export function MessageFilePreview({ fileList }: MessageFilePreviewProps) {
  const { t } = useTranslation(['common']);
  const { xs } = useBreakpoint();

  const { mediaAttachments, textAttachments } =
    separateImagesAndTextAttachments(fileList);

  const isMobile = xs;

  const onDownload = (current: number, isText = false) => {
    const { url, name } = isText
      ? textAttachments[current]
      : mediaAttachments[current];
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  function imageRender(originalNode: ReactElement, info: unknown) {
    const { current } = info as { current: number };
    if (!mediaAttachments.length && !current) return originalNode;

    const { name, url } = mediaAttachments[current];

    const { isVideo, isImage } = isFileExtensionImageOrVideo(name);

    if (isVideo && url) {
      return (
        <ReactPlayer
          width="75%"
          height="75%"
          controls
          url={[
            {
              src: url,
              type: 'video/mp4',
            },
          ]}
        />
      );
    }

    if (isImage) return originalNode;

    return (
      <Typography.Text
        style={{
          color: COLORS.neutral[1],
        }}
      >
        {t("common:There's no preview available for this file.")}
      </Typography.Text>
    );
  }

  return (
    <>
      <Flex gap={SPACING.SIZE_SM} wrap>
        <PreviewGroup
          preview={{
            imageRender,
            toolbarRender(originalNode, info) {
              return toolbarRender(
                info,
                isMobile,
                mediaAttachments,
                onDownload,
              );
            },
          }}
        >
          {mediaAttachments.map(({ name, url }) => (
            <CustomImagePreview
              key={url}
              name={name}
              isVideo={isFileExtensionImageOrVideo(name).isVideo}
              url={url}
            />
          ))}
        </PreviewGroup>
      </Flex>
      <Flex gap={SPACING.SIZE_SM} wrap>
        {textAttachments.map(({ name }, index) => (
          <TextPreview
            key={name}
            name={name}
            onDownload={() => onDownload(index, true)}
          />
        ))}
      </Flex>
    </>
  );
}
