export const SUPPORTED_LANGUAGES = {
  'es-MX': 'es',
  es: 'es',
  en: 'en',
};

export const SIGMA_EVENTS = {
  outbound: 'action:outbound',
};

export const SIGMA_EVENT_NAME = {
  createChecklist: 'Create-Checklist',
  createJob: 'Create-Job',
  updateChecklist: 'Edit-Checklist',
  inviteUser: 'Invite-User',
};

export const SIGMA_ORIGIN = 'https://app.sigmacomputing.com';
