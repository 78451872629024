import { Button, Flex, SPACING } from '@optii/ui-library';
import React from 'react';

type Props = {
  onCancel: () => void;
  onSave: () => void;
};

export function Footer({ onCancel, onSave }: Props) {
  return (
    <Flex justify="flex-end" align="center" gap={SPACING.SIZE_MD}>
      <Button type="default" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="default" htmlType="submit" onClick={onSave}>
        Save
      </Button>
    </Flex>
  );
}
