import { memo, ReactNode } from 'react';
import { ConfigProvider } from '@optii/ui-library';
import { FORM_CARD_THEME, FormKeys, FormProps } from './constants';
import { GeneralSettingsForm } from './GeneralSettingsForm';
import { OptiiConnectionsForm } from './OptiiConnectionsForm';
import { ConnectionSettingsForm } from './ConnectionSettingsForm';
import { GuestPrivacySettingsForm } from './GuestPrivacySettingsForm';

export const Forms = memo(({ formKey, form }: FormProps) => {
  const PMS_FORMS: { [key: string]: ReactNode } = {
    [FormKeys.generalSettings]: <GeneralSettingsForm form={form} />,
    [FormKeys.optiiConnections]: <OptiiConnectionsForm form={form} />,
    [FormKeys.connectionSettings]: <ConnectionSettingsForm form={form} />,
    [FormKeys.guestPrivacySettings]: <GuestPrivacySettingsForm form={form} />,
  };

  return (
    <ConfigProvider theme={FORM_CARD_THEME}>
      {PMS_FORMS[formKey as keyof typeof FormKeys]}
    </ConfigProvider>
  );
});
