import React, { useContext } from 'react';
import {
  FormInstance,
  FormItem,
  Select,
  SPACING,
  Typography,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { Statuses } from '@optii/global';
import { FormContext } from './context';

type Props = {
  form: FormInstance;
};

function Assignment({ form }: Props) {
  const { t } = useTranslation(['jobs', 'common']);
  const { assignees, departments, roles } = useContext(FormContext);
  return (
    <>
      <Typography.Title
        level={4}
        style={{
          marginBottom: SPACING.SIZE_MD,
          fontFamily: 'Montserrat',
        }}
      >
        {t('jobs:Assignment')}
      </Typography.Title>
      <FormItem
        name="department"
        label={t('fields:Department')}
        normalize={(value) => {
          const department = departments.options.find(
            (item) => item.value === value,
          );

          return {
            id: value,
            displayName: department?.label,
            departmentCode: department?.departmentCode,
          };
        }}
        getValueProps={(valueProps) => ({
          value: valueProps?.id,
        })}
        required
        rules={[
          {
            required: true,
            message: t('common:This is a required field'),
          },
        ]}
      >
        <Select
          placeholder={t('common:Select...')}
          options={departments.options}
          loading={departments.loading}
          optionFilterProp="label"
          showSearch
        />
      </FormItem>
      <FormItem
        name="role"
        label={t('fields:Role')}
        normalize={(v) => {
          const role = roles.options.find((item) => item.value === v);

          return {
            id: v,
            name: role?.label,
            description: role?.description,
          };
        }}
        getValueProps={(valueProps) => ({
          value: valueProps?.id,
        })}
      >
        <Select
          placeholder={t('common:Select...')}
          options={roles.options}
          loading={roles.loading}
          optionFilterProp="label"
          showSearch
        />
      </FormItem>

      <FormItem
        name="assignee"
        label={t('fields:Team Member')}
        dependencies={['status']}
        rules={[
          {
            validator(rule, value) {
              const status = form.getFieldValue('status');
              if (!value || value?.id === -88) {
                if (status === Statuses.completed) {
                  return Promise.reject(
                    t('jobs:Done jobs must be assigned to a team member.'),
                  );
                }
                if (status === Statuses.cancelled) {
                  return Promise.reject(
                    t('jobs:Cancelled jobs must be assigned to a team member.'),
                  );
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        normalize={(v) => {
          const assignee = assignees.options.find((item) => item.value === v);

          return {
            id: assignee?.value,
            userName: assignee?.userName,
            firstName: assignee?.firstName,
            lastName: assignee?.lastName,
          };
        }}
        getValueProps={(valueProps) => ({
          value: valueProps?.id,
        })}
      >
        <Select
          placeholder={t('common:Select...')}
          options={assignees.options}
          loading={assignees.loading}
          optionFilterProp="label"
          showSearch
        />
      </FormItem>
    </>
  );
}

export default Assignment;
