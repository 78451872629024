import styled from 'styled-components/macro';

const Arrow = styled.div`
  ${(props) =>
    props.theme.elements.arrow({
      color: props.theme.colors.nobel,
      borderWidth: '2px',
      rotate: '135',
      width: '9px',
      height: '9px',
    })}
  position: relative;
  bottom: 2px;
  display: inline-block;
  margin: 0px;
  &.asc {
    bottom: -2px;
    transform: rotate(-45deg);
  }
`;

export default Arrow;
