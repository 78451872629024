import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from 'react';
import { UploadProps, Upload, Button } from '@optii/ui-library';
import { Session, STORE_FILE } from '@optii/shared';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { FILE_SIZE_LIMIT } from '../../constants';
import { FileList } from '../../types';

type UploadFileProps = {
  setFileList: Dispatch<SetStateAction<FileList[]>>;
  disabled?: boolean;
};

export function UploadFile({ setFileList, disabled = false }: UploadFileProps) {
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['chat,', 'common', 'job']);
  const [storeFile] = useMutation(STORE_FILE, {
    onCompleted({ file: uploadedFile }) {
      setFileList((prev) =>
        prev.concat([
          {
            uid: uploadedFile.UUID,
            url: uploadedFile.URL,
            src: uploadedFile.URL,
            name: uploadedFile.name,
          },
        ]),
      );
    },
    onError(error) {
      console.error(error);
      return globalSnack({
        message: t('jobs:Could not upload attachment'),
        timeout: 5000,
        error: true,
      });
    },
  });
  const uploadFile = useCallback(
    (file: Blob) => {
      if (file.size >= FILE_SIZE_LIMIT)
        return globalSnack({
          message: t(
            'common:Your file is too large. The maximum upload size is {{limit}}',
            {
              limit: '20MB',
            },
          ),
          error: true,
          timeout: 5000,
        });

      return storeFile({
        variables: {
          file,
          name: file.name,
        },
      });
    },
    [storeFile, t, globalSnack],
  );

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { file, onSuccess } = options;
    if (typeof onSuccess === 'function') {
      const response = await uploadFile(file as Blob);
      onSuccess(response);
    }
  };

  return (
    <Upload multiple showUploadList={false} customRequest={customRequest}>
      <Button
        type="default"
        icon={<UploadOutlined />}
        disabled={disabled}
        size="large"
        theme={{
          components: {
            Button: {
              colorText: '#BFBFBF',
              onlyIconSizeLG: 24,
            },
          },
        }}
      />
    </Upload>
  );
}
