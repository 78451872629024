import React from 'react';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  inline: () => `
    padding: 1.6rem 2.5rem;
    display: flex;
    margin-bottom: 1.5rem;
    flex-flow: row wrap;
  `,
  withPadding: () => `
    padding-bottom: 2.5rem;
  `,
  inlineNoWrap: () => `
    padding: 1.6rem 2.5rem;
    display: flex;
    margin-bottom: 1.5rem;
    flex-flow: row;
    flex-wrap: nowrap;
  `,
  lightFontWeight: ({ theme }: { theme: any }) => `
    & > div:first-child span,
    & > div:first-child input {
    ${theme.fonts.weights.light}
  }
  `,
  hideThreeAndFour: ({ theme }: { theme: any }) => `
  @media (max-width: ${theme.breakpoints.sm}) {
    & > div:nth-child(3), & > div:nth-child(4) {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    & > div:nth-child(2) {
      min-width: 35%;
      max-width: 50%;
    }

    & > div:nth-child(3), & > div:nth-child(4) {
      display: none;
    }
  }
  `,
  hideThree: ({ theme }: { theme: any }) => `
  @media (max-width: ${theme.breakpoints.sm}) {
    & > div:nth-child(3) {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    & > div:nth-child(3) {
      display: none;
    }
  }
  `,
  preserveBorder: ({ theme }: { theme: any }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      box-shadow: 0 1px 6px 0 rgb(172 170 170 / 50%) !important;
      border: 1px solid #e5e5e5 !important;
    }
  `,
  noBorderRadius: ({ theme }: { theme: any }) => `
    border-radius: 0;
  `,
};

const RowWrap = styled.div`
  ${(props) => props.theme.elements.card}
  /* it makes the first column bold */
  & > div:first-child span,
  & > div:first-child input {
    ${(props) => props.theme.fonts.weights.medium}
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

const RowWrapper = React.forwardRef((props: any, ref: any) => (
  <RowWrap {...props} ref={ref}>
    {props.children}
  </RowWrap>
));

export default RowWrapper;
