import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { ReactComponent as Exit } from './exit.svg';

const CONFIG = {
  right: ({ theme }) => `
    margin-left: auto
  `,
  largeMobile: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.miniLg}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `,
};

const ExitIcon = styled(Exit)`
  cursor: pointer;
  :hover,
  :active {
    g {
      fill: ${(props) => props.theme.colors.tealMed};
    }
  }
  ${(props) => props.theme.animations.hoverUp()}

  ${applyStyleModifiers(CONFIG)};
`;

const Close = styled(ExitIcon)`
  position: absolute;
  top: 2.8rem;
  right: 2.5rem;
`;

export default Close;
