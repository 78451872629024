import { useState, useEffect, useContext } from 'react';
import { EmploymentContext, UserAccessContext } from '@optii/shared';
import { useMutation } from '@apollo/client';
import { REGISTER_FIREBASE_DEVICE } from 'queries';
import { isPlatform } from '@ionic/react';
import { PushNotifications } from '@capacitor/push-notifications';
import notificationTypes from 'utils/constants/notificationTypes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { registerPlugin } from '@capacitor/core';
import {
  isPushNotificationSupported,
  registerServiceWorker,
  createNotificationSubscription,
  handleInAppMessage,
  getNotificationType,
} from './push-notifications';

const PreferencesPlugin = registerPlugin('PreferencesPlugin');

// import all the function created to manage the push notifications

const pushNotificationSupported = isPushNotificationSupported();
// first thing to do: check if the push notifications are supported by the browser
function handleNotificationOpened(data, history) {
  const channelSid = data.ChannelSid;
  const propertyId = data.PropertyId || data.propertyId;

  if (channelSid) {
    const url = `/messages/${channelSid}?propertyId=${propertyId}`;

    history.push(url);
  } else if (
    getNotificationType(data) === notificationTypes.jobEscalation ||
    getNotificationType(data) ===
      notificationTypes.checklistTaskPushNotification
  ) {
    const url = `/jobs?openJob=${data.jobId}&propertyId=${propertyId}`;
    history.push(url);
  } else {
    const url = `/jobs?openJob=${data.id}&propertyId=${propertyId}`;
    history.push(url);
  }
}
export default function usePushNotifications() {
  const [registerFirebaseDevice] = useMutation(REGISTER_FIREBASE_DEVICE, {
    context: {
      _instance: 'node',
    },
  });
  const [registration, setRegistration] = useState();
  const { employee } = useContext(EmploymentContext.Context);
  const { user } = useContext(UserAccessContext.Context);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { t } = useTranslation('common');

  // to manage errors
  useEffect(
    (_) => {
      if (employee && registration && user?.id) {
        const subscribePushNotifications = async () => {
          setError(false);
          try {
            await createNotificationSubscription(async (address) => {
              if (!address) {
                return;
              }
              await registerFirebaseDevice({
                variables: {
                  input: {
                    userId: user.id,
                    deviceId: address,
                  },
                },
                onCompleted: () => {
                  handleInAppMessage(registration, t, user.id);
                },
                onError: (firebaseDeviceError) => {
                  console.error('Firebase device error:', firebaseDeviceError);
                  const [{ extensions }] = firebaseDeviceError.graphQLErrors;
                  const {
                    exception: { status },
                  } = extensions;

                  if (status === 409) {
                    handleInAppMessage(registration, t, user.id);
                  } else {
                    console.error(
                      ">>> web? Couldn't register firebase device:",
                      firebaseDeviceError,
                    );
                  }
                },
              });
            });
          } catch (err) {
            console.error(
              "Couldn't create the notification subscription",
              err,
              'name:',
              err.name,
              'message:',
              err.message,
              'code:',
              err.code,
            );
            setError(err);
          }
        };
        subscribePushNotifications();
      }
    },
    [employee, registration, registerFirebaseDevice, t, user?.id],
  );

  useEffect(() => {
    console.error('pushNotificationSupported:', pushNotificationSupported);
    if (pushNotificationSupported) {
      const getRegistration = async (_) => {
        const swRegistration = await registerServiceWorker();
        setRegistration(swRegistration);
      };
      getRegistration();
    }
  }, []);

  useEffect(
    (_) => {
      if (employee && isPlatform('capacitor') && user?.id) {
        PreferencesPlugin.saveUserId({ userId: user.id });
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        }); // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token) => {
          async function subscribeApn() {
            await registerFirebaseDevice({
              variables: {
                input: {
                  userId: user.id,
                  deviceId: token.value,
                },
              },
              onError: (firebaseError) => {
                const [{ extensions }] = firebaseError.graphqlErrors;
                const {
                  exception: { status },
                } = extensions;
                if (status !== 409)
                  console.error(
                    "Firebase Couldn't register device via APN",
                    firebaseError,
                  );
              },
            });
          }
          if (token && token.value) {
            subscribeApn();
          }
        });
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (errorValue) => {
          console.error(
            `>>> [PushNotifications] Error on registration: ${JSON.stringify(
              errorValue,
            )}`,
          );
        });
        // Method called when tapping on a notification

        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (event) => {
            handleNotificationOpened(event.notification.data, history);

            console.log('>>> Action Performed', event);
          },
        );
        PushNotifications.addListener(
          'pushNotificationReceived',
          (notification) => {
            const channelSid = notification.data.ChannelSid;
            let notificationToShow;

            if (user.id !== notification.data.userId) return;

            console.log('>>> Notification::', notification);

            if (channelSid) {
              if (window.location.pathname.indexOf(channelSid) === -1) {
                // If not on this chat, show notification
                notificationToShow = {
                  ...notification,
                  extra: notification.data,
                };
              }
            } else if (
              getNotificationType(notification.data) ===
              notificationTypes.jobAssigned
            ) {
              if (window.location.pathname.indexOf('/jobs') === -1) {
                // if  not on jobs page, show notification
                notificationToShow = {
                  ...notification,
                  extra: notification.data,
                };
                if (notification.data.fileUrl) {
                  notificationToShow.attachments = [
                    { id: '1', url: notification.data.fileUrl },
                  ];
                }
              }
            } else if (
              getNotificationType(notification.data) ===
              notificationTypes.jobEscalation
            ) {
              notificationToShow = {
                ...notification,
                extra: notification.data,
              };
            }
          },
        );
      }
    },
    [employee, history, registerFirebaseDevice, user?.id],
  );
  /**
   * returns all the stuff needed by a Component
   */
  return {
    pushNotificationSupported,
    error,
  };
}
