import React from 'react';
import { ButtonSecondaryStyled } from './styles';

const ButtonSecondary: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
  return <ButtonSecondaryStyled {...props}>{children}</ButtonSecondaryStyled>;
};

export default ButtonSecondary;
