export const COLORS = {
  primary: {
    0: '#E7F3F7',
    1: '#D0E8F0',
    2: '#A1D2E2',
    3: '#73BBD3',
    4: '#44A5C5',
    5: '#158EB6',
    6: '#1380A4',
    7: '#0F637F',
    8: '#0A475B',
    9: '#062B37',
    10: '#020E12',
  },
  secondary: {
    0: '#E5F6F4',
    1: '#CCEDE9',
    2: '#99DBD4',
    3: '#66CABE',
    4: '#33B8A9',
    5: '#00A693',
    6: '#009584',
    7: '#007467',
    8: '#007467',
    9: '#00322C',
    10: '#00110F',
  },
  red: {
    1: '#FFF1F0',
    2: '#FFCCC7',
    3: '#FFA39E',
    4: '#FF7875',
    5: '#FF4D4F',
    6: '#F5222D',
    7: '#CF1322',
    8: '#A8071A',
    9: '#820014',
    10: '#5C0011',
  },
  volcano: {
    1: '#FFF2E8',
    2: '#FFD8BF',
    3: '#FFBB96',
    4: '#ff9c6e',
    5: '#FF7A45',
    6: '#FF6541',
    7: '#FA541C',
    8: '#AD2102',
    9: '#871400',
    10: '#610B00',
  },
  sunset: {
    1: '#FFF7E6',
    2: '#FFE7BA',
    3: '#FFD591',
    4: '#FFC069',
    5: '#FFA940',
    6: '#FA8C16',
    7: '#D46B08',
    8: '#AD4E00',
    9: '#873800',
    10: '#612500',
  },
  calendula: {
    1: '#FFFBE6',
    2: '#FFF1B8',
    3: '#FFE58F',
    4: '#FFD666',
    5: '#FFC53D',
    6: '#FAAD14',
    7: '#D48806',
    8: '#AD6800',
    9: '#874D00',
    10: '#613400',
  },
  sunrise: {
    1: '#FEFFE6',
    2: '#FFFFB8',
    3: '#FFFB8F',
    4: '#FFF566',
    5: '#FFEC3D',
    6: '#FADB14',
    7: '#D4B106',
    8: '#AD8B00',
    9: '#876800',
    10: '#614700',
  },
  neutral: {
    1: '#FFFFFF',
    2: '#FAFAFA',
    3: '#F5F5F5',
    4: '#F0F0F0',
    5: '#D9D9D9',
    6: '#BFBFBF',
    7: '#8C8C8C',
    8: '#595959',
    9: '#434343',
    10: '#262626',
  },
  lime: {
    1: '#FCFFE6',
    2: '#F4FFB8',
    3: '#EAFF8F',
    4: '#D3F261',
    5: '#BAE637',
    6: '#A0D911',
    7: '#7CB305',
    8: '#5B8C00',
    9: '#3F6600',
    10: '#254000',
  },
  polarGreen: {
    1: '#F6FFED',
    2: '#D9F7BE',
    3: '#B7EB8F',
    4: '#95DE64',
    5: '#73D13D',
    6: '#52C41A',
    7: '#389E0D',
    8: '#237804',
    9: '#135200',
    10: '#092B00',
  },
  cyan: {
    1: '#E6FFFB',
    2: '#B5F5EC',
    3: '#87E8DE',
    4: '#5CDBD3',
    5: '#36CFC9',
    6: '#13C2C2',
    7: '#08979C',
    8: '#006D75',
    9: '#00474F',
    10: '#002329',
  },
  purple: {
    0: '#F9F0FF',
    1: '#EFDBFF',
    2: '#D3ADF7',
    3: '#B37FEB',
    4: '#9254DE',
  },
  magenta: {
    0: '#FFD6E7',
    1: '#FFADD2',
    2: '#FF85C0',
    3: '#F759AB',
    4: '#EB2F96',
  },
  daybreakBlue: {
    0: '#BAE7FF',
    1: '#91D5FF',
    2: '#69C0FF',
    3: '#40A9FF',
    4: '#1890FF',
  },
  dustRed: {},
};

export const DEFAULT_THEME_COLORS = {
  token: {
    primary: {
      bg: COLORS.primary[5],
      bgHover: COLORS.primary[6],
      border: COLORS.primary[5],
      borderHover: COLORS.primary[6],
      bgLight: COLORS.primary[1],
      text: COLORS.primary[5],
      textActive: COLORS.primary[7],
      textHover: COLORS.primary[6],
    },
    text: {
      base: COLORS.neutral[8],
      light: COLORS.neutral[6],
      link: COLORS.primary[4],
      help: COLORS.neutral[7],
      inactive: COLORS.neutral[5],
      error: COLORS.red[6],
      success: COLORS.polarGreen[6],
      inverse: COLORS.neutral[1],
    },
    success: {
      bg: COLORS.polarGreen[6],
      bgHover: COLORS.polarGreen[7],
      border: COLORS.polarGreen[6],
      borderHover: COLORS.polarGreen[7],
      bgLight: COLORS.polarGreen[1],
      text: COLORS.polarGreen[8],
      textActive: COLORS.polarGreen[8],
      textHover: COLORS.polarGreen[9],
    },
    danger: {
      bg: COLORS.red[6],
      bgHover: COLORS.red[7],
      border: COLORS.red[6],
      borderHover: COLORS.red[7],
      bgLight: COLORS.red[1],
      text: COLORS.red[6],
      textActive: COLORS.red[7],
      textHover: COLORS.red[7],
    },
    surfaces: {
      container: COLORS.neutral[1],
      app: COLORS.neutral[3],
      overlay: COLORS.neutral[3],
    },
    form: {
      textLabel: COLORS.neutral[8],
    },
  },
};
