import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import { JobDetailsContext, ButtonLink as LinkButton } from '@optii/shared';

const Container = styled.p`
  margin: 0.5rem 0 !important;
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.weak}
  ${(props) => props.theme.fonts.type.primary}
`;

const ButtonLink = styled(LinkButton)`
  color: ${(props) => props.theme.colors.blue};
  font: inherit;
`;

function ChildJobs({ jobsId }: { jobsId: string[] }) {
  const { t } = useTranslation(['checklist']);
  const { setBackToJob } = useContext(JobDetailsContext) as any;
  const [query] = useQueryParams({
    openJob: StringParam,
  });
  const history = useHistory();
  if (!setBackToJob) return null;

  return (
    <Container>
      {t('checklist: Jobs ID')}:{' '}
      {jobsId.map((id, i) => (
        <ButtonLink
          onClick={() => {
            setBackToJob(true);
            history.push({
              pathname: '/jobs',
              search: `?openJob=${id}&backToJob=${query.openJob}`,
            });
          }}
        >
          #{id}
          {i < jobsId.length - 1 && ', '}
        </ButtonLink>
      ))}
    </Container>
  );
}

export default ChildJobs;
