import styled from 'styled-components/macro';

const Header = styled.div`
  display: flex;
  & h3 {
    align-self: center;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.bg}) {
    padding-right: 8%;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.miniLg}) {
    padding-right: 18%;
  }
`;

export default Header;
