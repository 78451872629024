import styled from 'styled-components/macro';

const H2 = styled.h3`
  ${(props) => props.theme.fonts.type.boundary};
  ${(props) => props.theme.fonts.weights.medium};
  margin: 0 auto 0 0;
  font-size: 2.2rem;
`;

export default H2;
