import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { ReactComponent as FilterIcon } from './filters.svg';

const MODIFIER_CONFIG = {
  tabletHide: ({ theme }) => `
    @media (min-width: ${theme.breakpoints.smMax}) {
      display: none;
    }
  `,
};

const Icon = styled(FilterIcon)`
  @media (min-width: ${(props) => props.theme.breakpoints.mdMax}) {
    display: none;
  }

  ${(props) =>
    props.active &&
    `
      path.filter-bottom {
        fill: ${props.theme.colors.yellow}
      }
      path.filter-top {
        fill: ${props.theme.colors.tealMed}
      }
  `}
  ${(props) => props.theme.animations.hoverUp()}
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Icon;
