import React, { useContext, useEffect, ReactNode, useState } from 'react';
import {
  Button,
  COLORS,
  Drawer,
  Icon,
  Modal,
  SPACING,
} from '@optii/ui-library';
import { Loading, Icons, Session } from '@optii/shared';

import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam, BooleanParam } from 'use-query-params';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { useChecklistTemplateQuery } from '@optii/global';
import { DeleteOutlined } from '@ant-design/icons';
import ModalElements from '../blocks/ModalElements';

import { ChecklistModalContext } from '../contexts';
import { useDeleteChecklistTemplateMutation } from '../../api/checklists';

const MODIFIER_CONFIG = {
  childrenPadding: () => `
    padding: 1.6rem 1.6rem 0 1.6rem;
    border-bottom: 1px solid var(--components-form-border, #D9D9D9);
  `,
};

const ModalTop = styled(ModalElements.Top as 'div')<{ modifiers: any }>`
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export default function ChecklistModal({
  modalName,
  children,
  modalWidth,
  modifiers = ['right'],
}: {
  modalName: string;
  children: ReactNode;
  modalWidth: number;
  modifiers: string[];
}) {
  const [query, setQuery] = useQueryParams({
    edit: BooleanParam,
    openChecklist: StringParam,
    openChecklistTemplate: StringParam,
  });

  const {
    close,
    open,
    isOpen,
    readOnly,
    isAdd,
    isEdit,
    isCopy,
    setData,
    setLoading,
    isFulfillment,
    loading,
    data,
    associatedProperties,
    selectedProperties,
  } = useContext(ChecklistModalContext);
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['common', 'checklist']);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    data: checklistTemplateData,
    loading: onGetChecklistTemplateLoading,
  } = useChecklistTemplateQuery({
    variables: {
      id: query.openChecklistTemplate || '',
    },
    context: { _instance: 'node', _shard: selectedProperties?.[0] },
    skip: !query.openChecklistTemplate,
  });

  const [deleteChecklistTemplate] = useDeleteChecklistTemplateMutation({
    context: {
      _instance: 'node',
    },
  });

  const isAPConsole = window.document.URL.includes('above-property/checklists');

  const isEditAllowed = isAPConsole
    ? selectedProperties?.length === associatedProperties?.length
    : !checklistTemplateData?.checklistTemplate?.isAboveProperty;

  const closeConfirm = () => {
    GoogleAnalyticsClient.event(GA_EVENTS.closeChecklistModal);
    close({ refresh: 'false' });
  };

  useEffect(() => {
    setLoading(() => onGetChecklistTemplateLoading);
    if (checklistTemplateData?.checklistTemplate?.tasks) {
      setData({
        ...checklistTemplateData.checklistTemplate,
        tasks: checklistTemplateData?.checklistTemplate?.tasks.sort((a, b) => {
          if (Number(a.ordering_value) > Number(b.ordering_value)) {
            return 1;
          }
          if (Number(a.ordering_value) < Number(b.ordering_value)) return -1;
          return 0;
        }),
      });
    }
  }, [
    onGetChecklistTemplateLoading,
    checklistTemplateData,
    setData,
    setLoading,
  ]);

  const handleClick = () => {
    open(true);
    setQuery({ edit: true });
  };

  function modalTitle() {
    if (isAdd) {
      return t('checklist:Add Checklist');
    }
    if (isEdit) {
      return t('checklist:Edit Checklist');
    }
    if (isCopy) {
      return t('checklist:Copy Checklist');
    }
    if (readOnly || isFulfillment) {
      return data ? data.name : '';
    }
    return 'ChecklistNameProbably';
  }

  if (loading || onGetChecklistTemplateLoading) {
    return (
      <Drawer title={modalName} width={modalWidth} open={isOpen}>
        <Loading loading />
      </Drawer>
    );
  }

  function onCancel() {
    setOpenDeleteModal(false);
  }

  async function onDelete() {
    setDeleteLoading(true);
    Promise.all(
      selectedProperties.map(async (property) => {
        await deleteChecklistTemplate({
          variables: {
            id: data?.id,
            fromAPConsole: isAPConsole,
          },
          context: {
            _instance: 'node',
            _shard: property,
          },
        });
      }),
    ).finally(() => {
      setDeleteLoading(false);
      globalSnack({
        message: t('common:Checklist Template deleted successfully'),
        timeout: 6000,
      });
      close({});
    });
  }

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        close({});
      }}
      width={modalWidth}
      title={modalName}
      closeIcon={false}
      closable
      styles={{
        body: {
          padding: SPACING.NONE,
        },
        header: {
          display: 'none',
        },
      }}
    >
      <ModalTop modifiers={modifiers}>
        <ModalElements.Title data-testid="checklist-modal">
          {modalTitle()}
        </ModalElements.Title>
        <Icons modifiers={['alignCenter']}>
          {readOnly && isEditAllowed ? (
            <Icons.Edit
              data-testid="toggleEdit"
              title={t('common:Edit')}
              onClick={handleClick}
            />
          ) : null}
          {isAPConsole &&
          !isAdd &&
          selectedProperties.length === associatedProperties.length ? (
            <Button
              icon={<DeleteOutlined />}
              type="text"
              styles={{
                icon: {
                  color: COLORS.red[5],
                },
              }}
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            />
          ) : null}
          <Icons.Exit data-testid="closeModalIcon" onClick={closeConfirm} />
        </Icons>
      </ModalTop>
      {children}

      <Modal
        title={t('common:Delete {{checklistTemplateName}}', {
          checklistTemplateName: data?.name,
        })}
        open={openDeleteModal}
        okType="primary"
        okText={t('common:Yes, Delete')}
        cancelButtonProps={{
          type: 'text',
        }}
        cancelText={t('common:Cancel')}
        centered
        okButtonProps={{
          danger: true,
        }}
        onOk={onDelete}
        confirmLoading={deleteLoading}
        onCancel={onCancel}
      >
        {t(
          'checklist:Are you sure you want to delete this checklist for {{propertyCount}} properties? This cannot be undone and will not impact jobs already using this checklist',
          {
            propertyCount: selectedProperties.length,
          },
        )}
      </Modal>
    </Drawer>
  );
}
