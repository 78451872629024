import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  withMoreThanThreeTableColumns: ({ theme }) => `
    margin: 0;
    padding-right: 5px;
  `,
};
const ColData = styled.span`
  color: ${(props) => props.theme.colors.charcoal};
  ${(props) => props.theme.fonts.sizes.large};
  ${(props) => props.theme.fonts.weights.medium};
  line-height: 1.9rem;
  margin: 0 10px 0 0;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default ColData;
