import { FC, HTMLAttributes } from 'react';
import Main from './Main';
import Header from './Header';
import Title from './Title';
import Button from './Button';

const SettingsMain: FC<HTMLAttributes<HTMLDivElement>> & {
  Header: typeof Header;
  Title: typeof Title;
  Button: typeof Button;
} = (props) => <Main {...props} />;

SettingsMain.Header = Header;
SettingsMain.Title = Title;
SettingsMain.Button = Button;

export default SettingsMain;
