import React, { useContext, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import TextareaAutosize from 'react-textarea-autosize';
import { ChecklistItemContext } from '../contexts.ts';
import { CHECKLIST_ACTION_STATES, CHECKLIST_TYPES } from '../taskConstants.ts';

const NoteInput = styled(TextareaAutosize)`
  ${(props) => props.theme.fonts.type.primary};
  border: none !important;
  outline: none !important;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.charcoal02} !important;
  flex: 1;
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.borders.default}
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.light}
  resize: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  &::placeholder {
    ${(props) => props.theme.fonts.sizes.large}
    ${(props) => props.theme.fonts.type.primary};
    ${(props) => props.theme.fonts.weights.light};
    ${(props) => props.theme.colors.charcoal04};
    font-style: italic;
  }
`;

const Text = styled.div`
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.light}
  padding-top: .9rem;
  margin: 0 !important;
  color: ${(props) => props.theme.colors.charcoal05};
  height: ${(props) => (props.expand ? '100%' : '3rem')};
  max-width: 38.3rem;
  white-space: ${(props) => (props.expand ? 'normal' : 'nowrap')};
  overflow: ${(props) => (props.expand ? 'visible' : 'hidden')};
`;

const ReadOnly = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  column-gap: 1.6rem;
`;

const NoteInputWrapper = styled.div`
  display: flex;
  align-items: end;
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 1.6rem;
  padding: 1rem 0;
  min-height: 6rem;
  box-sizing: border-box;
`;

const More = styled.a`
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.medium}
  color: ${(props) => props.theme.colors.persianGreen};
  text-decoration: none;
  cursor: pointer;
`;

const Cancel = styled.a`
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.medium}
  color: ${(props) => props.theme.colors.persianGreen};
  text-decoration: none;
  cursor: pointer;
`;

const NoteContainer = styled.div`
  ${(props) =>
    props.taskType === CHECKLIST_TYPES.checkBox &&
    `
    padding-left: 3.4rem;
  `}
`;

function NoteView({
  note,
  onCancel = () => {
    // onCancel
  },
  canCancel,
  onClick,
  isReadOnly,
}) {
  const { t } = useTranslation(['common', 'checklist']);
  const [noteOverflow, setNoteOverflow] = useState(null);
  const [expand, setExpand] = useState(false);

  const noteRef = useRef(null);

  useEffect(() => {
    if (noteRef.current) {
      setNoteOverflow(
        noteRef.current.scrollHeight > noteRef.current.clientHeight ||
          noteRef.current.scrollWidth > noteRef.current.clientWidth,
      );

      setExpand(false);
    }
  }, []);

  function handleExpand() {
    setExpand(!expand);
  }

  const noteLineBreaks = note?.split('\n');

  return (
    <div>
      {note && (
        <ReadOnly onClick={onClick}>
          <Text ref={noteRef} expand={expand}>
            {noteLineBreaks?.map((item, key) => (
              <>
                {item}
                <br />
              </>
            ))}
          </Text>
          {noteOverflow && !expand && (
            <More onClick={handleExpand}> ...{t('common:more')}</More>
          )}
          {(!noteOverflow || expand) && canCancel && !isReadOnly && (
            <Cancel onClick={onCancel}>{t('common:cancel')}</Cancel>
          )}
        </ReadOnly>
      )}
    </div>
  );
}

export default function Note() {
  const { t } = useTranslation(['common', 'checklist']);
  const {
    formValues,
    onFormChange,
    actionState,
    setActionState,
    isFulfillment,
    isReadOnly,
    onFulfillmentChange,
  } = useContext(ChecklistItemContext);
  const [valueChanged, setValueChanged] = useState(false);

  const { note, fulfillmentNote } = formValues;

  const isAddingNote = actionState === CHECKLIST_ACTION_STATES.addingNote;

  const handleCancel = (event) => {
    event.stopPropagation();
    const field = isFulfillment ? 'fulfillmentNote' : 'note';
    onFormChange({ name: field, value: null });
    setActionState(CHECKLIST_ACTION_STATES.viewingItem);

    if (isFulfillment) {
      onFulfillmentChange({ ...formValues, fulfillmentNote: null });
    }
  };

  const handleChange = (event) => {
    const field = isFulfillment ? 'fulfillmentNote' : 'note';
    onFormChange({ name: field, value: event.target.value });
    setValueChanged(true);
  };

  return (
    <NoteContainer taskType={formValues.taskType}>
      {(!isAddingNote || (isAddingNote && isFulfillment)) && (
        <NoteView
          note={note}
          onCancel={handleCancel}
          canCancel={!isFulfillment}
          isReadOnly={isReadOnly}
          onClick={() =>
            !isFulfillment &&
            !isReadOnly &&
            setActionState(CHECKLIST_ACTION_STATES.addingNote)
          }
        />
      )}
      {isAddingNote && (
        <NoteInputWrapper>
          <NoteInput
            type="text"
            value={isFulfillment ? fulfillmentNote : note}
            data-testid="checklist-note"
            onChange={handleChange}
            autoFocus
            placeholder={t('checklist:Add Note...')}
            onBlur={() =>
              setTimeout(() => {
                setActionState(CHECKLIST_ACTION_STATES.viewingItem);
                if (isFulfillment) {
                  onFulfillmentChange({ ...formValues, fulfillmentNote });
                }
              }, 100)
            }
          />

          <Cancel onClick={handleCancel}>{t('common:cancel')}</Cancel>
        </NoteInputWrapper>
      )}

      {!isAddingNote && isFulfillment && (
        <NoteView
          note={fulfillmentNote}
          onCancel={handleCancel}
          canCancel={isFulfillment && valueChanged}
          onClick={() =>
            !isReadOnly && setActionState(CHECKLIST_ACTION_STATES.addingNote)
          }
        />
      )}
    </NoteContainer>
  );
}
