import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Table as AntTable,
  ConfigProvider,
  TableProps,
  ThemeConfig,
} from 'antd';
import { Button, COLORS } from '../../..';

type Props<T> = TableProps<T> & {
  theme?: ThemeConfig;
};

export function Table<T extends object = any>(props: Props<T>) {
  const { theme, ...rest } = props;

  const itemRender = (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    element: React.ReactNode,
  ) => {
    const PAGINATION_BUTTON_THEME: ThemeConfig = {
      components: {
        Button: {
          defaultBorderColor: `${COLORS.neutral[5]} !important`,
          defaultBg: `${COLORS.neutral[1]} !important`,
          defaultHoverBg: `${COLORS.neutral[1]} !important`,
          defaultHoverBorderColor: `${COLORS.primary[5]} !important`,
        },
      },
    };

    if (type === 'prev') {
      return (
        <Button
          type="default"
          className="ant-pagination-item-link"
          theme={PAGINATION_BUTTON_THEME}
        >
          <LeftOutlined />
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button
          type="default"
          className="ant-pagination-item-link"
          theme={PAGINATION_BUTTON_THEME}
        >
          <RightOutlined />
        </Button>
      );
    }
    return element;
  };

  return (
    <ConfigProvider theme={theme}>
      <AntTable<T>
        {...rest}
        pagination={
          rest.pagination
            ? {
                itemRender,
                ...rest.pagination,
              }
            : false
        }
      />
    </ConfigProvider>
  );
}
