import React, { useState, useEffect } from 'react';

function ToggleContent({
  toggle,
  content,
  shown,
}: {
  toggle: (show: () => void, isShown: boolean) => void;
  content: (hide: () => void) => void;
  shown?: boolean;
}) {
  const [isShown, setIsShown] = useState(shown || false);
  const hide = () => setIsShown(false);

  const show = () => setIsShown(true);

  // Allow consumers to explicitly control show state
  useEffect(() => {
    setIsShown(shown ?? false);
  }, [shown]);
  return (
    <>
      {toggle(show, isShown)}
      {isShown && content(hide)}
    </>
  );
}
export default ToggleContent;
