import { JobAction } from '../../api/jobs';

export const JOB_CARD_ACTION = {
  [JobAction.Check]: {
    label: 'Check',
  },
  [JobAction.Correction]: {
    label: 'Correction',
  },
  [JobAction.Departure]: {
    label: 'Departure',
  },
  [JobAction.Find]: {
    label: 'Find',
  },
  [JobAction.Fix]: {
    label: 'Fix',
  },
  [JobAction.Inspection]: {
    label: 'Inspection',
  },
  [JobAction.NoAction]: {
    label: 'NoAction',
  },
  [JobAction.Stayover]: {
    label: 'Stayover',
  },
  [JobAction.Touchup]: {
    label: 'Touchup',
  },
  [JobAction.Turndown]: {
    label: 'Turndown',
  },
  [JobAction.Clean]: {
    label: 'Clean',
  },
  [JobAction.Deliver]: {
    label: 'Deliver',
  },
  [JobAction.Inspect]: {
    label: 'Inspect',
  },
  [JobAction.Install]: {
    label: 'Install',
  },
  [JobAction.Perform]: {
    label: 'Perform',
  },
  [JobAction.Remove]: {
    label: 'Remove',
  },
  [JobAction.Repair]: {
    label: 'Repair',
  },
  [JobAction.Replace]: {
    label: 'Replace',
  },
  [JobAction.Test]: {
    label: 'Test',
  },
};

export const JOB_CARD_ACTION_COLORS = {
  [JobAction.Departure]: {
    notStarted: '#FFA940',
    inProgress: '#FFA940',
    onHold: '#FFA940',
    completed: '#FFE7BA',
    cancelled: '#FFE7BA',
  },
  [JobAction.Stayover]: {
    notStarted: '#237804',
    inProgress: '#237804',
    onHold: '#237804',
    completed: '#D9F7BE',
    cancelled: '#D9F7BE',
  },
  [JobAction.Inspection]: {
    notStarted: '#08979C',
    inProgress: '#08979C',
    onHold: '#08979C',
    completed: '#B5F5EC',
    cancelled: '#B5F5EC',
  },
  [JobAction.Check]: {
    notStarted: '#C41D7F',
    inProgress: '#C41D7F',
    onHold: '#C41D7F',
    completed: '#FFD6E7',
    cancelled: '#FFD6E7',
  },
  [JobAction.Touchup]: {
    notStarted: '#1890FF',
    inProgress: '#1890FF',
    onHold: '#1890FF',
    completed: '#BAE7FF',
    cancelled: '#BAE7FF',
  },
  [JobAction.Turndown]: {
    notStarted: '#722ED1',
    inProgress: '#722ED1',
    onHold: '#722ED1',
    completed: '#EFDBFF',
    cancelled: '#EFDBFF',
  },
  [JobAction.Correction]: {
    notStarted: '#820014',
    inProgress: '#820014',
    onHold: '#820014',
    completed: '#FFCCC7',
    cancelled: '#FFCCC7',
  },
};

export const JOB_CARD_ICONS_LABELS = {
  rush: 'Rushed Job',
  checklists: 'Job has an attached checklist',
  attachments: 'Job has photos and/or attachments',
};

export const JOB_CARD_ICONS_PRIORITY_LABELS = {
  highest: 'Highest Priority',
  high: 'High Priority',
  medium: 'Medium Priority',
  low: 'Low Priority',
};

export const JOB_CARD_JOB_TYPE_ICONS_LABELS = {
  repeating: 'Repeating Job',
  project: 'Project Job',
};

export const JOB_CARD_ICONS_CLEANING_STATUS_LABELS = {
  doNotDisturb: 'Do Not Disturb',
  noServiceRequired: 'No Service Required',
  doubleLockActive: 'Double Lock Active',
  sleepOutNoShow: 'Guest Sleep Out / No Show',
  guestInVacantRoom: 'Guest In Vacant Room',
  outOfOrder: 'Out of Order',
};

export const JOB_CARD_ICONS_RESERVATIONS_LABELS = {
  arrival: 'Arrival Guest',
  departure: 'Departing Guest',
  inHouse: 'In-house Guest',
  arrivalDeparture: 'Departing Guest with New Arrival',
  arrivalVip: 'Arriving, VIP Guest',
  departureVip: 'Departing, VIP Guest',
  inHouseVip: 'In-house, VIP Guest',
  arrivalDepartureVip: 'VIP Departing Guest with New VIP Arrival',
};
