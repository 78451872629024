import React from 'react';

export const RushInactive = () => (
  <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M11.4185 20.4999V14.9185L9.46505 13.0581L8.53482 17.1511L2.11621 15.8488L2.4883 13.9883L6.95342 14.9185L8.44179 7.38366L6.76737 8.03482V11.1976H4.90691V6.82552L8.58133 5.24412C9.12396 5.01157 9.52319 4.8604 9.779 4.79064C10.0348 4.72087 10.2712 4.68599 10.4883 4.68599C10.8139 4.68599 11.1162 4.77126 11.3953 4.9418C11.6744 5.11234 11.8992 5.33715 12.0697 5.61622L12.9999 7.10459C13.403 7.75575 13.9495 8.29064 14.6395 8.70924C15.3294 9.12785 16.1162 9.33715 16.9999 9.33715V11.1976C15.9767 11.1976 15.0193 10.9844 14.1278 10.5581C13.2364 10.1317 12.4883 9.56971 11.8837 8.87203L11.3255 11.6627L13.279 13.5232V20.4999H11.4185ZM11.8837 4.22087C11.372 4.22087 10.934 4.0387 10.5697 3.67436C10.2054 3.31002 10.0232 2.87203 10.0232 2.3604C10.0232 1.84878 10.2054 1.41079 10.5697 1.04645C10.934 0.68211 11.372 0.499939 11.8837 0.499939C12.3953 0.499939 12.8333 0.68211 13.1976 1.04645C13.5619 1.41079 13.7441 1.84878 13.7441 2.3604C13.7441 2.87203 13.5619 3.31002 13.1976 3.67436C12.8333 4.0387 12.3953 4.22087 11.8837 4.22087Z"
      fill="#BFBFBF"
    />
  </svg>
);

export const RushActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M11.4185 20.4999V14.9185L9.46505 13.0581L8.53482 17.1511L2.11621 15.8488L2.4883 13.9883L6.95342 14.9185L8.44179 7.38366L6.76737 8.03482V11.1976H4.90691V6.82552L8.58133 5.24412C9.12396 5.01157 9.52319 4.8604 9.779 4.79064C10.0348 4.72087 10.2712 4.68599 10.4883 4.68599C10.8139 4.68599 11.1162 4.77126 11.3953 4.9418C11.6744 5.11234 11.8992 5.33715 12.0697 5.61622L12.9999 7.10459C13.403 7.75575 13.9495 8.29064 14.6395 8.70924C15.3294 9.12785 16.1162 9.33715 16.9999 9.33715V11.1976C15.9767 11.1976 15.0193 10.9844 14.1278 10.5581C13.2364 10.1317 12.4883 9.56971 11.8837 8.87203L11.3255 11.6627L13.279 13.5232V20.4999H11.4185ZM11.8837 4.22087C11.372 4.22087 10.934 4.0387 10.5697 3.67436C10.2054 3.31002 10.0232 2.87203 10.0232 2.3604C10.0232 1.84878 10.2054 1.41079 10.5697 1.04645C10.934 0.68211 11.372 0.499939 11.8837 0.499939C12.3953 0.499939 12.8333 0.68211 13.1976 1.04645C13.5619 1.41079 13.7441 1.84878 13.7441 2.3604C13.7441 2.87203 13.5619 3.31002 13.1976 3.67436C12.8333 4.0387 12.3953 4.22087 11.8837 4.22087Z"
      fill="#CF1322"
    />
  </svg>
);

export function Priority() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="2" fill="#F2722C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.99783 11.0877C8.61148 11.0877 9.10894 11.5904 9.10894 12.2105C9.10894 12.8306 8.61148 13.3333 7.99783 13.3333C7.38418 13.3333 6.88672 12.8306 6.88672 12.2105C6.88672 11.5904 7.38418 11.0877 7.99783 11.0877ZM8.70292 2.66666C8.88887 2.66666 9.03962 2.81137 9.03962 2.98989C9.03962 2.99701 9.03937 3.00413 9.03888 3.01124L8.56789 9.84103C8.55618 10.0109 8.40925 10.1429 8.23193 10.1429H7.76517C7.58787 10.1429 7.44095 10.0109 7.4292 9.84109L6.95685 3.0113C6.94453 2.83318 7.08496 2.67919 7.27051 2.66737L8.70292 2.66666Z" fill="white"/>
  </svg>;
}

export function Recurring() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.00021" cy="8.00006" r="2.43478" fill="#FADB14"/>
    <path d="M1.3913 8.00007C1.38918 5.59065 2.69965 3.37137 4.81051 2.20965C6.92137 1.04793 9.49743 1.12825 11.5318 2.4192L10.4783 3.31277C10.38 3.39588 10.3357 3.52642 10.363 3.65215C10.3904 3.77788 10.4848 3.87826 10.6087 3.91312L14.3885 4.96633C14.4188 4.97496 14.4502 4.97929 14.4817 4.9792C14.6738 4.9792 14.8296 4.82348 14.8296 4.63138C14.8295 4.61684 14.8286 4.60232 14.8268 4.5879L14.3443 0.738507C14.3285 0.611692 14.2444 0.503774 14.1253 0.457439C14.0062 0.411105 13.8713 0.433818 13.7739 0.516594L12.6372 1.48112C10.1976 -0.254308 6.99315 -0.48229 4.3324 0.890265C1.67166 2.26282 -1.66273e-05 5.00617 0 8.00007C5.725e-09 8.38427 0.311454 8.69572 0.695652 8.69572C1.07985 8.69572 1.3913 8.38427 1.3913 8.00007Z" fill="#158EB6"/>
    <path d="M15.3042 7.30441C14.92 7.30441 14.6085 7.61587 14.6085 8.00007C14.6108 10.4095 13.3004 12.6288 11.1896 13.7906C9.07875 14.9523 6.50269 14.872 4.46835 13.5809L5.52157 12.6874C5.61979 12.6043 5.66411 12.4737 5.6368 12.348C5.60948 12.2223 5.51499 12.1219 5.39114 12.087L1.60992 11.0338C1.4978 11.0026 1.37753 11.0296 1.28945 11.1057C1.20138 11.1817 1.15717 11.2968 1.17166 11.4122L1.65548 15.2616C1.6714 15.3883 1.75544 15.4961 1.87442 15.5424C1.9934 15.5887 2.1282 15.5661 2.22557 15.4835L3.36261 14.519C5.80224 16.2544 9.00668 16.4824 11.6674 15.1099C14.3282 13.7373 15.9998 10.994 15.9998 8.00007C15.9998 7.61587 15.6884 7.30441 15.3042 7.30441Z" fill="#158EB6"/>
  </svg>;
}

export const ImagesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2.49994C0.5 1.67151 1.17157 0.999939 2 0.999939H18C18.8284 0.999939 19.5 1.67151 19.5 2.49994V18.4999C19.5 19.3284 18.8284 19.9999 18 19.9999H2C1.17157 19.9999 0.5 19.3284 0.5 18.4999V2.49994Z"
      fill="white"
    />
    <path
      d="M0.5 2.49994C0.5 1.67151 1.17157 0.999939 2 0.999939H18C18.8284 0.999939 19.5 1.67151 19.5 2.49994V18.4999C19.5 19.3284 18.8284 19.9999 18 19.9999H2C1.17157 19.9999 0.5 19.3284 0.5 18.4999V2.49994Z"
      stroke="#BFBFBF"
    />
    <path
      d="M8.75153 8.62494C9.61447 8.62494 10.314 7.92538 10.314 7.06244C10.314 6.19949 9.61447 5.49994 8.75153 5.49994C7.88858 5.49994 7.18903 6.19949 7.18903 7.06244C7.18903 7.92538 7.88858 8.62494 8.75153 8.62494Z"
      fill="#FADB14"
    />
    <path
      d="M12.3951 14.8542L7.80746 9.8361C7.75643 9.77945 7.69511 9.73515 7.62747 9.70606C7.55982 9.67697 7.48736 9.66375 7.41476 9.66723C7.34238 9.67032 7.27136 9.68972 7.20614 9.72423C7.14092 9.75874 7.08291 9.80761 7.03574 9.8678L3.87728 13.9021C3.81157 13.9858 3.76917 14.0883 3.75513 14.1976C3.74109 14.3068 3.756 14.4182 3.79807 14.5185C3.84015 14.6187 3.90762 14.7037 3.99249 14.7632C4.07736 14.8227 4.17604 14.8543 4.27682 14.8542H12.3951Z"
      fill="#158EB6"
    />
    <path
      d="M16.1524 13.9843L12.0548 7.81585C12.0153 7.75595 11.9654 7.70517 11.9079 7.66644C11.8505 7.62771 11.7866 7.60179 11.72 7.59017C11.6533 7.57855 11.5853 7.58146 11.5197 7.59873C11.4541 7.616 11.3922 7.64729 11.3377 7.6908C11.299 7.72282 11.2645 7.76056 11.2353 7.80296L6.20697 14.8749H15.738C15.8325 14.8749 15.9252 14.8463 16.0057 14.7922C16.0863 14.7382 16.1516 14.6608 16.1945 14.5686C16.2374 14.4765 16.2561 14.3731 16.2487 14.27C16.2413 14.1669 16.208 14.068 16.1524 13.9843Z"
      fill="#73BBD3"
    />
  </svg>
);

export const NotesIcon = () => (
  <svg 
    width="16"
    height="16" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="white"/>
    <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#BFBFBF"/>
    <rect x="4" y="4" width="8" height="1.71429" rx="0.857143" fill="#73BBD3"/>
    <rect x="4" y="7.42847" width="8" height="1.71429" rx="0.857143" fill="#73BBD3"/>
    <rect x="4" y="10.8571" width="5.45455" height="1.71429" rx="0.857143" fill="#FCD15C"/>
  </svg>
);

export function LowPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <title>icons/ priority/ low</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -764.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-low" transform="translate(15.000000, 149.000000)">
                    <g id="icons/-priority/-low">
                        <g id="icons/-background/-border-radius-2" fill="#A1D2E2">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(6.000000, 4.000000)" fill="#FFFFFF">
                            <path d="M7.95143824,6.46506222 C7.87591725,6.31597333 7.72270636,6.22222222 7.55560196,6.22222222 L4.88890049,6.22222222 L4.88890049,0.444444444 C4.88890049,0.198982222 4.68991123,0 4.44445024,0 L3.55554976,0 C3.31008877,0 3.11109951,0.198982222 3.11109951,0.444444444 L3.11109951,6.22222222 L0.444398041,6.22222222 C0.277293638,6.22222222 0.12408275,6.31597333 0.0485617643,6.46506222 C-0.0273947826,6.61393333 -0.0135057124,6.79296889 0.0850200178,6.92816889 L3.64062198,11.8170578 C3.72438751,11.9318578 3.85763814,12 4,12 C4.14236186,12 4.27561249,11.9318578 4.35937802,11.8170578 L7.91497998,6.92816889 C8.01350571,6.79296889 8.02739478,6.61393333 7.95143824,6.46506222 Z" id="arrow-down/-low" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function MediumPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icons/ priority/ medium</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -729.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-medium" transform="translate(15.000000, 114.000000)">
                    <g id="icons/-priority/-medium">
                        <g id="icons/-background/-border-radius-2" fill="#FBC633">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(5.000000, 7.000000)" fill="#FFFFFF">
                            <path d="M9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 L1,6 C0.44771525,6 6.76353751e-17,5.55228475 0,5 C-6.76353751e-17,4.44771525 0.44771525,4 1,4 L9,4 Z M9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 C10,1.55228475 9.55228475,2 9,2 L1,2 C0.44771525,2 6.76353751e-17,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L9,0 Z" id="equal/-medium" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function HighPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icons/ priority/ high</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -694.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-high" transform="translate(15.000000, 79.000000)">
                    <g id="icons/-priority/-high">
                        <g id="icons/-background/-border-radius-2" fill="#F2722C">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(9.000000, 4.000000)" fill="#FFFFFF">
                            <g id="exclamation/-alert">
                                <path d="M1.25,9.86842105 C1.94035594,9.86842105 2.5,10.4575201 2.5,11.1842105 C2.5,11.910901 1.94035594,12.5 1.25,12.5 C0.559644063,12.5 0,11.910901 0,11.1842105 C0,10.4575201 0.559644063,9.86842105 1.25,9.86842105 Z M1.96228401,0 C2.21332253,-1.0162618e-16 2.41682947,0.203506932 2.41682947,0.454545455 C2.41682947,0.464562482 2.41649834,0.474576773 2.41583663,0.484571921 L1.89600266,8.33671336 C1.88018947,8.57557314 1.68183268,8.76123235 1.44245004,8.76123235 L1.05914711,8.76123235 C0.819797871,8.76123235 0.621456758,8.57562329 0.605600219,8.33679987 L0.0842619426,0.484658431 C0.0676310048,0.234171402 0.257208824,0.0176295052 0.507695853,0.000998567354 L1.96228401,0 Z" id="icons/-Exclamation-Point" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function HighestPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Highest Priority</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -659.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-highest" transform="translate(15.000000, 44.000000)">
                    <g id="icons/-priority/-highest">
                        <g id="bg" fill="#CE0000">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(5.000000, 3.000000)" fill="#FFFFFF">
                            <path d="M8.06316069,2.97030816 C7.99116437,2.8876326 7.92739621,2.81350019 7.87361936,2.74818649 C7.80719843,2.66691037 7.69820043,2.6268733 7.59058749,2.6442234 C7.48297455,2.6615735 7.39449269,2.73344964 7.36082926,2.83086205 C7.19910645,3.29397256 6.95313806,3.72734111 6.63381337,4.11178207 C6.65262058,3.01715765 6.42135077,1.08558098 4.72987808,0.0452468415 C4.63941273,-0.0104797145 4.52360594,-0.0150588254 4.42845966,0.0333284704 C4.33331339,0.0817157661 4.27423224,0.17523535 4.27468503,0.27673841 C4.27468503,1.17955553 3.7589563,2.14162347 2.86238174,2.91546671 C1.99489957,3.66450729 -0.0207034881,5.64403578 0.000160751139,7.83686723 C0.0148538774,9.36360924 0.843840058,11.4745919 2.94672028,12.3126465 C2.97816357,11.3544367 3.42395302,10.6740169 3.86004501,10.0181241 C4.27439117,9.3941992 4.66610991,8.80554921 4.70196114,7.98182504 C4.70654373,7.87392437 4.77790305,7.7784758 4.8845072,7.73765568 C4.99111135,7.69683555 5.11331503,7.71816631 5.19711949,7.79222243 C6.38226705,8.83944619 7.01730397,10.3909909 7.0484534,12.2842612 C8.55684973,11.6810052 9.79224778,10.3661882 9.97444255,8.22737146 C10.2098264,5.44974822 8.75990874,3.77501695 8.06316069,2.97030816 Z" id="fire" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function CancelledIcon() {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9531 12L9.56083 10.6077C9.06865 10.9646 8.53413 11.2404 7.95727 11.435C7.38041 11.6297 6.77348 11.727 6.13649 11.727C5.32537 11.727 4.56311 11.5731 3.84973 11.2653C3.13633 10.9574 2.51578 10.5397 1.98806 10.0119C1.46034 9.48422 1.04257 8.86367 0.734731 8.15027C0.426896 7.43688 0.272978 6.67463 0.272978 5.86351C0.272978 5.22652 0.370313 4.61959 0.564982 4.04273C0.75964 3.46587 1.03541 2.93135 1.39228 2.43917L0 1.04687L0.659925 0.386947L11.6131 11.3401L10.9531 12ZM6.13649 10.8012C6.64213 10.8012 7.12602 10.7268 7.58814 10.5781C8.05026 10.4293 8.48469 10.216 8.89142 9.93829L2.06171 3.10858C1.78396 3.51531 1.57071 3.94974 1.42194 4.41186C1.27317 4.87398 1.19878 5.35787 1.19878 5.86351C1.19878 7.23167 1.67969 8.39666 2.64152 9.35848C3.60334 10.3203 4.76833 10.8012 6.13649 10.8012ZM11.0647 9.04452L10.3893 8.36917C10.6156 7.98617 10.7864 7.58538 10.9015 7.16679C11.0166 6.74818 11.0742 6.31376 11.0742 5.86351C11.0742 4.49536 10.5933 3.33036 9.63146 2.36854C8.66963 1.40672 7.50464 0.925805 6.13649 0.925805C5.68624 0.925805 5.25182 0.98337 4.83321 1.0985C4.41462 1.21364 4.01383 1.38437 3.63083 1.61068L2.95548 0.93531C3.42682 0.632989 3.92943 0.401524 4.46328 0.240914C4.99715 0.0803052 5.55489 0 6.13649 0C6.94761 0 7.70986 0.153918 8.42325 0.461753C9.13664 0.769589 9.7572 1.18736 10.2849 1.71508C10.8126 2.2428 11.2304 2.86336 11.5382 3.57675C11.8461 4.29014 12 5.05239 12 5.86351C12 6.44511 11.9197 7.00285 11.7591 7.53671C11.5985 8.07057 11.367 8.57317 11.0647 9.04452Z" fill="black" fillOpacity="0.45"/>
  </svg>;
}

export function InProgressIcon() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7" fill="#52C41A"/>
    <circle cx="8" cy="8" r="5.5" fill="#52C41A" stroke="white"/>
  </svg>;
}

export function DoneIcon() {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50443 3.87012H7.8763C7.73969 3.87012 7.60978 3.93574 7.52942 4.04824L5.42407 6.96789L4.4705 5.64467C4.39014 5.53351 4.26157 5.46655 4.12362 5.46655H3.4955C3.40844 5.46655 3.35755 5.56565 3.40844 5.63664L5.07719 7.95092C5.11661 8.00594 5.16858 8.05078 5.22879 8.08171C5.289 8.11263 5.35571 8.12877 5.4234 8.12877C5.49109 8.12877 5.5578 8.11263 5.61801 8.08171C5.67821 8.05078 5.73018 8.00594 5.7696 7.95092L8.59014 4.04021C8.64237 3.96922 8.59148 3.87012 8.50443 3.87012Z" fill="#135200"/>
    <path d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z" fill="#135200"/>
  </svg>;
}

export function OnHoldIcon() {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821ZM4.82143 3.85714H4.17857C4.11964 3.85714 4.07143 3.90536 4.07143 3.96429V8.03571C4.07143 8.09464 4.11964 8.14286 4.17857 8.14286H4.82143C4.88036 8.14286 4.92857 8.09464 4.92857 8.03571V3.96429C4.92857 3.90536 4.88036 3.85714 4.82143 3.85714ZM7.82143 3.85714H7.17857C7.11964 3.85714 7.07143 3.90536 7.07143 3.96429V8.03571C7.07143 8.09464 7.11964 8.14286 7.17857 8.14286H7.82143C7.88036 8.14286 7.92857 8.09464 7.92857 8.03571V3.96429C7.92857 3.90536 7.88036 3.85714 7.82143 3.85714Z" fill="#614700"/>
  </svg>;
}

export const ProjectsIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9" width="7" height="1.5" rx="0.75" fill="#158EB6" />
    <rect x="14.5" width="1.5" height="7" rx="0.75" fill="#158EB6" />
    <rect x="9" y="14.5" width="7" height="1.5" rx="0.75" fill="#73BBD3" />
    <rect x="14.5" y="9" width="1.5" height="7" rx="0.75" fill="#73BBD3" />
    <rect
      x="7"
      y="16.0001"
      width="7"
      height="1.5"
      rx="0.75"
      transform="rotate(180 7 16.0001)"
      fill="#158EB6"
    />
    <rect
      x="1.5"
      y="16.0001"
      width="1.5"
      height="7"
      rx="0.75"
      transform="rotate(-180 1.5 16.0001)"
      fill="#158EB6"
    />
    <rect
      x="7"
      y="1.50012"
      width="7"
      height="1.5"
      rx="0.75"
      transform="rotate(180 7 1.50012)"
      fill="#73BBD3"
    />
    <rect
      x="1.5"
      y="7.00012"
      width="1.5"
      height="7"
      rx="0.75"
      transform="rotate(-180 1.5 7.00012)"
      fill="#73BBD3"
    />
    <path
      d="M7.44 9.84L10.26 7.02L9.7 6.46L7.44 8.72L6.3 7.58L5.74 8.14L7.44 9.84ZM8 12C7.44667 12 6.92667 11.895 6.44 11.685C5.95333 11.475 5.53 11.19 5.17 10.83C4.81 10.47 4.525 10.0467 4.315 9.56C4.105 9.07333 4 8.55333 4 8C4 7.44667 4.105 6.92667 4.315 6.44C4.525 5.95333 4.81 5.53 5.17 5.17C5.53 4.81 5.95333 4.525 6.44 4.315C6.92667 4.105 7.44667 4 8 4C8.55333 4 9.07333 4.105 9.56 4.315C10.0467 4.525 10.47 4.81 10.83 5.17C11.19 5.53 11.475 5.95333 11.685 6.44C11.895 6.92667 12 7.44667 12 8C12 8.55333 11.895 9.07333 11.685 9.56C11.475 10.0467 11.19 10.47 10.83 10.83C10.47 11.19 10.0467 11.475 9.56 11.685C9.07333 11.895 8.55333 12 8 12Z"
      fill="#FADB14"
    />
  </svg>
);