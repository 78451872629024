import React, { useContext, useMemo, useCallback, memo } from 'react';
// import Select from "components/shared/form/Select";
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';

import styled from 'styled-components/macro';
import { GA_EVENTS, GoogleAnalyticsClient, breakpoints } from '@optii/shared';
import { Select } from '@optii-solutions/ui23-antd-components';
import { ChecklistItemContext } from '../../contexts';
import CheckList, {
  FlexContainer,
  FlexItem,
  RequiredFlag,
} from '../../elements';
import TaskNameField from '../TaskNameField';
import OptionsList from '../OptionsList';
import SelectType from '../SelectType';
import { CHECKLIST_TASK_FIELDS } from '../../taskConstants';

const SelectBox = styled(Select)`
  width: 100%;
  margin-top: 1rem;
  > div {
    height: 44px;
  }
`;

function stringToSelectOption(strValue: string) {
  return {
    value: strValue,
    label: strValue,
  };
}

function filterEmptyValues(options: any[]) {
  return options?.filter((opt) => !!opt);
}

function DropdownType(props: any) {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    onFormChange,
    isFulfillment,
    formValues,
    isReadOnly,
    errors,
  } = useContext(ChecklistItemContext);
  const { t } = useTranslation(['common', 'checklist']);

  const isMobile = useMedia({
    query: `(max-width: ${breakpoints.mdMax})`,
  });

  const isRequired =
    String(
      formValues[CHECKLIST_TASK_FIELDS.required as keyof typeof formValues],
    ) === 'true';

  const dropdownOptions = useMemo(() => {
    const currentOptions =
      formValues[
        CHECKLIST_TASK_FIELDS.dropdownOptions as keyof typeof formValues
      ];
    const defaultOptions = [
      t('checklist:Option {{position}}', { position: 1 }),
    ];

    if ((currentOptions as any)?.length > 0) {
      return currentOptions;
    }

    return defaultOptions;
  }, [
    formValues[
      CHECKLIST_TASK_FIELDS.dropdownOptions as keyof typeof formValues
    ],
  ]);

  const formatedDropdownOptions = useMemo(
    () => (dropdownOptions as any)?.map(stringToSelectOption),
    [dropdownOptions],
  );

  const handleFulfillmentChange = useCallback(
    (value: any) => {
      if (isReadOnly) return;
      GoogleAnalyticsClient.event(GA_EVENTS.dropdownTaskSelect);

      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataDropdownValue]: value,
      });
    },
    [formValues, isReadOnly, onFulfillmentChange],
  );

  const clearFulfillmentChange = useCallback(() => {
    if (isReadOnly) return;

    onFulfillmentChange({
      ...formValues,
      [CHECKLIST_TASK_FIELDS.dataDropdownValue]: null,
    });
  }, [formValues, isReadOnly, onFulfillmentChange]);

  const handleChange = (allOptions: any) => {
    const options = filterEmptyValues(allOptions);
    onFormChange({
      name: CHECKLIST_TASK_FIELDS.dropdownOptions,
      value: options,
    });
  };

  const errorMessage = errors[
    CHECKLIST_TASK_FIELDS.dropdownOptions as keyof typeof errors
  ]
    ? t(errors[CHECKLIST_TASK_FIELDS.dropdownOptions as keyof typeof errors])
    : false;

  return (
    <FlexContainer data-testid="checklist-task-dropdown-container" col>
      <FlexContainer>
        <FlexItem row>
          {isRequired && <RequiredFlag />}
          <TaskNameField />
        </FlexItem>
        {!isFulfillment && <SelectType />}
      </FlexContainer>
      <FlexContainer>
        {isFulfillment ? (
          <SelectBox
            onClear={clearFulfillmentChange}
            allowClear={!isMobile}
            mode="multiple"
            options={formatedDropdownOptions}
            disabled={isReadOnly}
            placeholder={t('checklist:Select')}
            value={
              fulfillmentValues[
                CHECKLIST_TASK_FIELDS.dataDropdownValue as keyof typeof fulfillmentValues
              ] ?? undefined
            }
            onChange={handleFulfillmentChange}
          />
        ) : (
          <OptionsList
            initialOptions={dropdownOptions as string[]}
            onChange={handleChange as any}
            isDisabled={isReadOnly}
            error={errorMessage}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
}

export default memo(DropdownType);
