import React, { Fragment, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  GA_EVENTS,
  ViewJobDetailsContext,
  EmploymentContext,
  usePropertyTime,
} from '@optii/shared';
import { JOB_STATUS } from '@optii/shared/constants/jobStatus';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { AddTemplateNote } from '@optii/jobs';
import TemplateFulfillment from '@optii/topcat-client/checklists/details/fulfillment/CheckListFulfillment';
import { getAuditAction } from '@optii/jobs/formatters/jobs';
import { formatUserDisplayName } from '@optii/jobs/formatters/user';
import { CHECKLIST_TASK_TYPE_CONFIG } from '@optii/topcat-client/checklists/constants';
import { useJobNoteEditor } from '@optii/topcat-client/data/jobs';
import TabNav from '@optii/topcat-client/components/shared/nav/TabNav';
import AddNote from '@optii/topcat-client/components/Jobs/JobDetails/AddNote';

import { JobNotesDropdown } from '@optii-solutions/ui23-antd-components';

import { JobFormNoteEdit } from './JobFormNoteEdit';

const Author = styled.h4`
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.type.primary}
  ${(props) => props.theme.fonts.weights.weak}
  flex: 1;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Time = styled.div`
  ${(props) => props.theme.fonts.sizes.small}
  margin-left: auto;
  color: ${(props) => props.theme.colors.charcoal05};
  font-style: italic;
  text-align: right;
`;
const Text = styled.p`
  ${(props) => props.theme.fonts.sizes.small}
  padding-top: 1rem;
  margin: 0 !important;
  color: ${(props) => props.theme.colors.charcoal05};
`;
const Entry = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.charcoal02};
  margin-bottom: 1.5rem;
  padding-bottom: 1.4rem;
`;
const Entries = styled.div`
  padding-bottom: 1rem;
`;
const Container = styled.div``;
const ModeSelection = styled(TabNav)`
  margin-top: 0;
  padding-left: 0;
`;

type TNote = {
  entry: TEditJobFormTabsPropsJobNotes;
  toPropertyTime: any;
  canEdit: boolean;
  refetch: any;
  job: any;
};

type TAudit = {
  entry: TEditJobFormTabsPropsJobNotes;
  toPropertyTime: any;
};

export function Note({
  entry: note,
  toPropertyTime,
  canEdit,
  refetch,
  job,
}: TNote) {
  const { t } = useTranslation(['common', 'fields'], { useSuspense: false });
  const { editLoading, removeLoading, update, remove, edit, setEdit } =
    useJobNoteEditor({
      job,
    });
  if (!note.text) {
    return null;
  }
  const time = Object.prototype.hasOwnProperty.call(note, 'createdAt')
    ? toPropertyTime(moment(note.createdAt).unix())
    : toPropertyTime(note.time);

  return (
    <Entry style={{ opacity: removeLoading ? '0.3' : '1' }}>
      {edit ? (
        <JobFormNoteEdit
          note={note.text}
          loading={editLoading}
          name={formatUserDisplayName(note.createdBy)}
          onCancel={() => setEdit(false)}
          onSave={async (input) => {
            await update({ note: input, id: note.id });

            await refetch();
          }}
        />
      ) : (
        <>
          <Top>
            <Author>
              {note.createdBy
                ? formatUserDisplayName(note.createdBy)
                : t('common:Unknown')}
            </Author>
            <Time>{time && time.format('ll[,] LTS')}</Time>
            {canEdit && (
              <JobNotesDropdown
                onEdit={() => !removeLoading && setEdit(true)}
                onRemove={async () => {
                  if (!removeLoading) {
                    await remove({
                      id: note.id,
                    });
                  }

                  await refetch();
                }}
              />
            )}
          </Top>
          <Text>
            {note.text.split('\n').map((item, index) => {
              const key = `${item}-${index}`;

              return (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              );
            })}
          </Text>
        </>
      )}
    </Entry>
  );
}

function Audit({ entry, toPropertyTime }: TAudit) {
  const time = toPropertyTime(entry.createdAt);
  const { t } = useTranslation(['common', 'fields'], { useSuspense: false });

  return (
    <Entry>
      <Top>
        <Author>{getAuditAction(entry, t)}</Author>
        <Time>{time && time.format('ll[,] LTS')}</Time>
      </Top>
    </Entry>
  );
}

type TRemoveDuplicates = {
  modifier1: string;
  createdAt: string;
  action: string;
};

function removeDuplicates(
  data: (TRemoveDuplicates & TEditJobFormTabsPropsJobNotes)[],
) {
  const seenModifiers = new Set();
  const seenCreatedAt = new Set();
  const seenAction = new Set();

  const filteredData = data.filter((record) => {
    const { modifier1, createdAt, action } = record;

    if (
      seenModifiers.has(modifier1) &&
      seenCreatedAt.has(createdAt) &&
      seenAction.has(action)
    ) {
      return false; // Skip the item if both modifier1 and createdAt are duplicates
    }

    seenModifiers.add(modifier1);
    seenCreatedAt.add(createdAt);
    seenAction.add(action);

    return true; // Keep the item in the filtered data
  });

  return filteredData;
}

export type TEditJobFormTabsPropsJobNotes = {
  id: string;
  time: string;
  text: string;
  employee: {
    employeeId: string;
  };
  createdBy: {
    id: string;
  };
  createdAt: string;
};

export type TEditJobFormTabsPropsJobChecklists = {
  checklistTasks: any[];
  id: string;
  checklistTemplateId: string;
};

type TEditJobFormTabsPropsJob = {
  checklists?: TEditJobFormTabsPropsJobChecklists[];
  jobTemplate?: any;
  canEdit?: boolean;
  notes?: TEditJobFormTabsPropsJobNotes[];
  status?: string;
};

type TEditJobFormTabsProps = {
  hideAddNote: boolean;
  audit: any;
  canEdit?: boolean;
  job: TEditJobFormTabsPropsJob;
  queuedJobRequest?: boolean;
  refetch?: () => void;
  submitted?: boolean;
  updateChecklistTask?: any;
};

type TModeSection = {
  setMode: (a: any) => void;
  mode: string;
  modes: {
    notes: any;
    audits: any;
    tasks: any;
  };
  queuedJobRequest: any;
  hasChecklist?: boolean;
  t: (a: string) => string;
};

function ModeSections({
  setMode,
  modes,
  mode,
  queuedJobRequest,
  hasChecklist,
  t,
}: TModeSection) {
  const sections = [
    {
      handleClick: () => setMode(modes.notes),
      label: t('fields:Notes'),
      key: 'modes_notes',
      isActive: mode === modes.notes,
    },
  ];

  if (!queuedJobRequest) {
    sections.push({
      handleClick: () => {
        setMode(modes.audits);
        GoogleAnalyticsClient.event(GA_EVENTS.viewActivityJob);
      },
      label: t('jobs:Activity'),
      key: 'modes_audits',
      isActive: mode === modes.audits,
    });
  }

  if (hasChecklist) {
    sections.unshift({
      handleClick: () => {
        setMode(modes.tasks);
      },
      label: t('jobs:Tasks'),
      key: 'modes_tasks',
      isActive: mode === modes.tasks,
    });
  }
  return sections;
}

type TChecklistTask = {
  orderingValue: string | number;
  fulfillmentAttachment: any;
  fulfillmentAttachments: any;
};

export function EditJobFormTabs(props: TEditJobFormTabsProps) {
  const {
    updateChecklistTask,
    submitted,
    canEdit,
    queuedJobRequest,
    hideAddNote,
    audit,
    job,
    refetch,
  } = props;

  const modes = {
    all: 'all',
    notes: 'notes',
    audits: 'audits',
    tasks: 'tasks',
  };
  const { isRepeatingJobTemplate } = useContext(ViewJobDetailsContext);

  const hasChecklist = job && job.checklists && job.checklists.length > 0;
  // For when we filter the history
  const [mode, setMode] = useState(hasChecklist ? modes.tasks : modes.notes);
  const { t } = useTranslation(['jobs', 'fields'], { useSuspense: false });

  const { employee } = useContext(EmploymentContext.Context);
  const { toPropertyTime } = usePropertyTime(null);
  const notes = isRepeatingJobTemplate
    ? (job.jobTemplate && job.jobTemplate.notes) || []
    : job.notes || [];
  const audits = audit || [];
  const getFulfillmentValues = (task: any) =>
    CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.getValue(task);

  const handleChecklistTaskChange = React.useCallback(
    ({ checklistId, data }: { checklistId: string | number; data: any }) => {
      const {
        note: dataNote,
        id,
        label,
        orderingValue,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ordering_value,
        required,
        taskType,
        createdAt,
        attachment,
        fulfillmentAttachment,
        fulfillmentNote,
      } = data;
      const fulfillmentValues = getFulfillmentValues(data);

      updateChecklistTask({
        variables: {
          id,
          updateChecklistTaskInput: {
            id,
            label,
            notes: dataNote,
            ordering_value,
            orderingValue,
            checklistId,
            required,
            taskType,
            createdAt,
            attachment,
            ...fulfillmentValues,
            fulfillmentAttachment,
            fulfillmentNote,
          },
        },
      });
    },
    [updateChecklistTask],
  );

  const renameFields = React.useCallback(
    (checklistTasks: TChecklistTask[]) =>
      checklistTasks.map((task) => ({
        ...task,
        fulfillmentAttachment:
          task.fulfillmentAttachment ?? task.fulfillmentAttachments,
        orderingValue: task.orderingValue ?? task.orderingValue,
      })),
    [],
  );

  const orderTasks = React.useCallback(
    (checklistTasks: TChecklistTask[]) =>
      checklistTasks.sort(
        (taskA: TChecklistTask, taskB: TChecklistTask) =>
          Number(taskA.orderingValue) - Number(taskB.orderingValue),
      ),
    [],
  );

  return (
    <Container {...props}>
      <ModeSelection
        sections={ModeSections({
          setMode,
          mode,
          modes,
          queuedJobRequest,
          hasChecklist,
          t,
        })}
      />
      <Entries data-testid="job-notes-entries">
        {mode === modes.tasks &&
          job.checklists?.map(({ checklistTasks, id: checklistId }) => (
            <TemplateFulfillment
              onTaskChange={(data: any) =>
                handleChecklistTaskChange({
                  checklistId,
                  data,
                })
              }
              submitted={submitted}
              data={orderTasks(renameFields(checklistTasks))}
              readOnly={canEdit}
            />
          ))}
        {mode === modes.notes &&
          job.notes?.map((entry: TEditJobFormTabsPropsJobNotes) => (
            <Note
              key={
                (entry.time || `${entry.createdAt}_audit`) +
                (entry.employee ? entry.employee.employeeId : '0') +
                entry.id
              }
              canEdit={employee?.id === entry.createdBy?.id}
              entry={entry}
              toPropertyTime={toPropertyTime}
              refetch={refetch}
              job={job}
            />
          ))}
        {mode === modes.audits && audits?.length
          ? removeDuplicates(audits)?.map(
              (entry: TEditJobFormTabsPropsJobNotes) => (
                <Audit
                  key={
                    (entry.time || `${entry.createdAt}_audit`) +
                    (entry.employee ? entry.employee.employeeId : '0') +
                    entry.id
                  }
                  entry={entry}
                  toPropertyTime={toPropertyTime}
                />
              ),
            )
          : null}
      </Entries>
      {mode === modes.notes &&
      !queuedJobRequest &&
      !hideAddNote &&
      job.status !== JOB_STATUS.queued ? (
        <div>
          {isRepeatingJobTemplate ? (
            <AddTemplateNote queuedJobRequest={queuedJobRequest} />
          ) : (
            <AddNote data-testid="job-add-note" job={job} />
          )}
        </div>
      ) : null}
    </Container>
  );
}
