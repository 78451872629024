import { gql } from '@apollo/client';

export const GET_DONE_JOB_LIST = gql`
  query getDoneJobsList(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: getDoneJobsList(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      edges {
        node {
          ... on DoneJobListItem {
            id
            assetId
            parentJobId
            asset {
              id
              displayName
              assetType {
                id
                displayName
              }
            }
            roomId
            roomsData {
              id
              reservations {
                preferences {
                  type
                  value
                }
                statusLabel
              }
              cleaningStatus
              roomType {
                code
              }
              occupancyStatus
              outOfOrderDates {
                code
                startDate
                endDate
              }
              cleaningExceptionStatus {
                doNotDisturb
                noServiceRequired
                doubleLockActive
                sleepOutNoShow
              }
            }
            room {
              reservations {
                preferences {
                  type
                  value
                }
                statusLabel
              }
              cleaningStatus
              roomType {
                code
              }
              occupancyStatus
              outOfOrderDates {
                code
                startDate
                endDate
              }
              cleaningExceptionStatus {
                doNotDisturb
                noServiceRequired
                doubleLockActive
                sleepOutNoShow
              }
            }
            items {
              name
              amount
              __typename
            }
            type
            action
            priority
            isEscalated
            status
            doBy
            completedAt
            parentChecklistTaskId
            checklists {
              hasUncompletedChecklistTask
              checklistTasks {
                id
                checklistId
                taskType
                attachments
                label
                orderingValue
                dataText
                dataCheckboxChecked
                dataTemperatureScale
                dataDropdownValue
                dropdownOptions
                dataNumerical
                dataTemperatureValue
                required
                notes
                createdAt
                updatedAt
                childJobsIds
                dataOnOff
                dataPassFail: dataPassError
                dataYesNo
                fulfillmentAttachments
                fulfillmentNote
                __typename
              }
              __typename
            }
            locations {
              id
              name
              shortDisplayName
              longDisplayName
              displayName
              type
              __typename
            }
            notes {
              text
              time
              __typename
            }
            assignee {
              firstName
              lastName
              id
              __typename
            }
            reporter {
              id
              __typename
            }
            department {
              displayName
              departmentCode
              __typename
            }
            attachments
            metadata {
              externalId
              source
              __typename
            }
            isRushed
            timeWindowStart
            timeWindowEnd
            creditValue
            durationMin
            __typename
          }
          __typename
        }
        __typename
      }
    }
  }
`;
