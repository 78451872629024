import { Table, TableProps, Typography } from '@optii/ui-library';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

function AppConfigurationTable() {
  const { t } = useTranslation(['aboveProperty', 'common']);

  const [pageInfo, setPageInfo] = useQueryParams({
    after: StringParam,
    first: NumberParam,
    page: NumberParam,
  });
  const columns: TableProps['columns'] = [
    {
      title: t('common:Property'),
      dataIndex: 'propertyName',
      key: 'propertyName',
      defaultSortOrder: 'ascend',
    },
    {
      title: t('common:Application'),
      dataIndex: 'application',
      key: 'application',
    },
    {
      title: t('common:Status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('Common:Property ID'),
      dataIndex: 'propertyId',
      key: 'propertyId',
      render(value) {
        return <Typography.Text copyable>{value}</Typography.Text>;
      },
    },
    {
      title: t('common:ID Number'),
      dataIndex: 'idNumber',
      key: 'idNumber',
      render(value) {
        return <Typography.Text copyable>{value}</Typography.Text>;
      },
    },
    {
      title: t('common:Description'),
      dataIndex: 'description',
      key: 'description',
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey="propertyId"
      scroll={{
        x: 950,
      }}
      locale={{
        emptyText: t('common:No App Configurations'),
      }}
      dataSource={[]}
      pagination={{
        total: 0,
        showTotal(total, range) {
          return `${range[0]}-${range[1]} ${t('common:of {{total}} items', {
            total,
          })}`;
        },
        showSizeChanger: true,
        pageSize: pageInfo.first || undefined,
        current: pageInfo.page || undefined,
        onChange(page, pageSize) {
          const after = (
            page === 1 ? undefined : pageSize * page - pageSize
          )?.toString();

          setPageInfo({
            after,
            first: pageSize,
            page,
          });
        },
      }}
    />
  );
}

export default AppConfigurationTable;
