import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Table, useColsSorting, HeadCol } from '@optii/shared';

const TableCol = styled(Table.Col as 'div')<{ modifiers: string[] }>`
  ${(props) => props.theme.fonts.type.boundary};
`;

const NameCol = styled(Table.Col as 'div')<{ modifiers: string[] }>`
  ${(props) => props.theme.fonts.type.boundary};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 38rem;
  }
`;

export default function ChecklistHeadColumns({
  onSort,
}: {
  onSort: (currentCol: any) => void;
}) {
  const { t } = useTranslation(['common', 'checklists']);

  const columns = {
    CHECKLIST_NAME: {
      id: 'name',
      displayName: t('common:Name'),
      isSortDesc: false,
    },

    CHECKLIST_ITEMS: {
      id: 'task_count',
      displayName: t('checklists:Checklist Tasks'),
      isSortDesc: false,
    },

    LAST_UPDATED_DATE: {
      id: 'updated_at',
      displayName: t('common:Last Updated'),
      isSortDesc: false,
    },
  };

  const colsData: any[] = [];
  Object.entries(columns).forEach(([key, value]) => {
    colsData.push(value);
  });

  const { cols, handleCols, currentCol } = useColsSorting(
    colsData,
    'name',
    colsData[0],
  );

  const getColField = (id: string) =>
    cols.filter((field: { id: string }) => field.id === id)[0];

  useEffect(() => {
    if (currentCol) {
      onSort(currentCol);
    }
  }, [currentCol]);

  return (
    <>
      <NameCol
        data-testid={`column${columns.CHECKLIST_NAME.id}`}
        key={columns.CHECKLIST_NAME.id}
        modifiers={['noRightMargin', 'hiddenOverflow', 'longDynamic']}
      >
        <HeadCol
          field={getColField(columns.CHECKLIST_NAME.id)}
          handleCols={handleCols}
          modifiers={['withMoreThanThreeTableColumns']}
          isNotSortable={false}
          shouldMakeColHeadBold={currentCol.id === columns.CHECKLIST_NAME.id}
        />
      </NameCol>
      <TableCol
        data-testid={`column-${columns.CHECKLIST_ITEMS.id}`}
        key={columns.CHECKLIST_ITEMS.id}
        modifiers={['hideAtSmBreak', 'noRightMargin', 'hiddenOverflow']}
      >
        <HeadCol
          field={getColField(columns.CHECKLIST_ITEMS.id)}
          handleCols={handleCols}
          modifiers="withMoreThanThreeTableColumns"
          isNotSortable={false}
          shouldMakeColHeadBold={currentCol.id === columns.CHECKLIST_ITEMS.id}
        />
      </TableCol>
      <TableCol
        data-testid={`column-${columns.LAST_UPDATED_DATE.id}`}
        key={columns.LAST_UPDATED_DATE.id}
        modifiers={[
          'hideAtSmBreak',
          'noRightMargin',
          'hiddenOverflow',
          'shortDynamic',
        ]}
      >
        <HeadCol
          field={getColField(columns.LAST_UPDATED_DATE.id)}
          handleCols={handleCols}
          modifiers="withMoreThanThreeTableColumns"
          isNotSortable={false}
          shouldMakeColHeadBold={currentCol.id === columns.LAST_UPDATED_DATE.id}
        />
      </TableCol>
      <TableCol key="empty-column" modifiers={['endActions']}>
        <HeadCol field="" isNotSortable />
      </TableCol>
    </>
  );
}
