import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { ReactComponent as ArrowUp } from '../../../images/svg/arrow-up.svg';

const MODIFIER_CONFIG = {
  down: () => `
    transform: rotate(180deg);
  `,
  left: () => `
    transform: rotate(-90deg);
  `,
  right: () => `
    transform: rotate(90deg);
  `,
  extraSmall: () => `
    width: 0.8rem;
    height: 0.8rem;
  `,
  small: () => `
    width: 1.2rem;
    height: 1.2rem;
  `,
  large: () => `
    width: 2.1rem;
    height: 2.1rem;
  `,
  light: ({ theme }: { theme: any }) => `
    g {
      fill: ${theme.colors.charcoal04};
    }
  `,
  hidden: () => `
    display: none;
  `,
  defaultCursor: () => `
    cursor: default;
  `,
};

const Arrow = styled(ArrowUp)<{ modifiers?: string[] }>`
  width: 1.6rem;
  height: 1.8rem;
  cursor: pointer;
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export default Arrow;
