import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from '@optii/topcat-client/images/svg/close.svg';
import { Error as ChecklistError, FlexItem } from '../Checklists.elements';

const Error = styled(ChecklistError)`
  padding-left: 1rem;
  padding-top: 0;
`;

const List = styled.ol`
  width: 100%;
  padding-left: 0;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
`;

const ItemContent = styled.div`
  padding: 0.2rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  border-style: solid;
  list-style: none;
  flex: 1;
  margin-left: 0.8rem
    ${(props) => `
        border-color: ${
          props.border ? props.theme.colors.charcoal03 : 'transparent'
        }  
    `}
    div {
    display: flex;
  }
`;

const Field = styled.input`
  border: 0;
  width: 100%;
  padding: 0.6rem;
`;

const Remove = styled.a`
  border: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

function Option({
  value,
  onRemove = () => {},
  onChange = () => {},
  onBlur = () => {},
  type = 'text',
  canRemove,
  isDisabled,
  position = 0,
}) {
  const { t } = useTranslation(['common', 'checklist']);

  const hasBorder = useMemo(
    () => canRemove || isDisabled,
    [canRemove, isDisabled],
  );

  return (
    <Item>
      {position + 1}
      <ItemContent border={hasBorder}>
        <div>
          <Field
            disabled={isDisabled}
            value={value}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={t('Add Option')}
          />
          {canRemove && (
            <Remove tabIndex={-1} onClick={onRemove}>
              <RemoveIcon width={10} />
            </Remove>
          )}
        </div>
      </ItemContent>
    </Item>
  );
}

function OptionsList({
  initialOptions = [''],
  isDisabled,
  onChange = () => {},
  error,
}) {
  const [options, setOptions] = useState(initialOptions);
  const { t } = useTranslation(['common', 'checklist']);

  const autoAdd = () => {
    if (!options || isDisabled) return;
    const lastOption = options[options.length - 1];
    if (!!lastOption && !!lastOption.trim()) {
      setOptions([...options, '']);
    }
  };

  useEffect(() => {
    onChange(options);
    autoAdd(options);
  }, [options]);

  const removeOptionByPosition = (position) => {
    const qty = 1;
    const newOptions = [...options];
    newOptions.splice(position, qty);
    setOptions(newOptions);
  };

  const changeOptionByPosition = (position, value) => {
    const newOptions = [...options];
    newOptions[position] = value;
    setOptions(() => newOptions);
  };

  const handleBlur = (position, value) => {
    const newOptions = [...options];
    newOptions[position] = value;
    const isLastOption = position === options.length - 1;
    const isEmptyOption = String(value).trim() === '';
    if (isEmptyOption && !isLastOption) {
      newOptions[position] = t('checklist:Option {{position}}', {
        position: position + 1,
      });
    }
    setOptions(() => newOptions);
  };

  const canRemove = (position) => {
    if (isDisabled) return false;
    return position < options.length - 1;
  };

  return (
    <FlexItem col>
      <List>
        {options?.map((opt, position) => (
          <Option
            value={opt}
            key={opt}
            onRemove={() => removeOptionByPosition(position)}
            onChange={(event) =>
              changeOptionByPosition(position, event.target.value)
            }
            onBlur={(event) => handleBlur(position, event.target.value)}
            canRemove={canRemove(position)}
            position={position}
            isDisabled={isDisabled}
          />
        ))}
        {!!error && <Error>{error}</Error>}
      </List>
    </FlexItem>
  );
}

export default OptionsList;
