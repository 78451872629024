import {
  Card,
  Checkbox,
  Col,
  FormItem,
  Input,
  Password,
  Row,
  Select,
  SPACING,
  TextArea,
  Typography,
} from '@optii/ui-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CARD_CONTAINER_STYLE,
  FormProps,
  GLOBAL_CARD_STYLES,
} from './constants';

export function OptiiConnectionsForm({ form }: Pick<FormProps, 'form'>) {
  const { t } = useTranslation(['common']);
  return (
    <Card
      styles={GLOBAL_CARD_STYLES}
      style={CARD_CONTAINER_STYLE}
      title={t('common:Optii Connections')}
    >
      <Row align="middle" gutter={SPACING.SIZE_MD}>
        <Col md={12} xs={24} sm={24}>
          <FormItem label={t('common:Read Only')}>
            <Typography.Text>[Text]</Typography.Text>
          </FormItem>
        </Col>

        <Col md={12} xs={24} sm={24}>
          <FormItem noStyle valuePropName="checked">
            <Checkbox>True/False (bottom aligned with text field)</Checkbox>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={SPACING.SIZE_MD}>
        <Col md={12} xs={24} sm={24}>
          <FormItem label={t('common:Multi-Select/Dropdown')}>
            <Select placeholder="Select..." />
          </FormItem>
        </Col>

        <Col md={12} xs={24} sm={24}>
          <FormItem label={t('common:Password')}>
            <Password placeholder="Password..." />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <FormItem label={t('common:URLs span 100%')}>
            <Input addonBefore="https://" />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <FormItem label={t('common:Select field with long data')}>
            <Select placeholder="Select..." />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={SPACING.SIZE_MD}>
        <Col md={12} xs={24} sm={24}>
          <FormItem label={t('common:Open Text')}>
            <Input placeholder="Input" />
          </FormItem>
        </Col>

        <Col md={12} xs={24} sm={24}>
          <FormItem label={t('common:Open Text')}>
            <Input placeholder="Input" />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <FormItem label={t('common:Notes/Descriptions')}>
            <TextArea rows={3} />
          </FormItem>
        </Col>
      </Row>
    </Card>
  );
}
