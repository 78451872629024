import styled from 'styled-components/macro';

const FieldWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.6rem;
  flex-flow: row wrap;
  & label {
    margin-right: 6rem;
    text-align: right;
    & + div {
      margin-top: 0;
      max-width: 10rem;
    }
  }
  & .error {
    margin-top: 1rem;
  }
`;

export default FieldWrap;
