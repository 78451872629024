import React from 'react';
import { Flex, Button } from '@optii/ui-library';
import { FormInstance, SPACING } from '@optii-solutions/ui-library';
import { useTranslation } from 'react-i18next';

type Props = {
  form: FormInstance;
  loading: boolean;
  onClose: () => void;
};

export function Footer({ form, loading, onClose }: Props) {
  const { t } = useTranslation(['common']);
  return (
    <Flex align="center" justify="flex-end" gap={SPACING.SIZE_MD}>
      <Button type="text" onClick={onClose}>
        {t('common:Cancel')}
      </Button>
      <Button
        type="primary"
        onClick={form.submit}
        loading={loading}
        disabled={loading}
      >
        {t('common:Save')}
      </Button>
    </Flex>
  );
}
