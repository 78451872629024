import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import Modal from 'components/shared/Modal';
import { CONVERT_TASK_TO_JOB } from 'queries';
import { useMutation } from '@apollo/client';
import { Session, ViewJobDetailsContext } from '@optii/shared';
import { useTranslation } from 'react-i18next';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { CHECKLIST_TASK_TYPE_CONFIG } from 'checklists/constants';
import ConvertTaskToJob from './ConvertTaskToJob';
import TemplateFulfillment from '@optii/checklists/lib/details/fulfillment/CheckListFulfillment';

const Container = styled.div`
  /* [class^='checklists__ItemContainer'] {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  } */
`;

function JobChecklist({ isReadOnly, id, data }) {
  const {
    job,
    setJob,
    submitted,
    refetch,
    toggleIsUpdateChecklistTaskLoading,
    updateChecklistTask,
    updateLoading,
    checklists,
    isRepeatingJobTemplate,
  } = useContext(ViewJobDetailsContext);

  const [showModal, setShowModal] = useState(false);
  const [taskInfo, setTaskInfo] = useState(null);
  const { t } = useTranslation(['jobs, common']);
  const { globalSnack } = useContext(Session);

  const getFulfillmentValues = (task) =>
    CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.getValue(task);

  const [convertTaskToJob, { loading: convertTaskToJobLoading }] = useMutation(
    CONVERT_TASK_TO_JOB,
    {
      context: { _instance: 'node' },
      onError: () => {
        globalSnack({
          message: t('common: Something went wrong, perhaps try again?'),
          error: true,
        });
      },
      onCompleted: (data) => {
        const {
          convertTaskToJob: {
            new_job: { id: newJobId, location: locations } = {},
          },
        } = data;
        globalSnack({
          message: t(`jobs: Job id #{{newJobId}} {{location}} has been added`, {
            newJobId: `${newJobId}:`,
            location:
              locations[0].name.length > 5
                ? `${locations[0].name.slice(0, 4)}...`
                : locations[0].name,
          }),
        });
        setShowModal(false);
        refetch();
      },
    },
  );

  const handleChecklistTaskChange = React.useCallback(
    ({ checklistId, data }) => {
      const {
        note: notes,
        id,
        templateTaskId,
        label,
        ordering_value,
        orderingValue,
        required,
        taskType,
        createdAt,
        attachment,

        fulfillmentAttachment,
        fulfillmentNote,
      } = data;

      const fulfillmentValues = getFulfillmentValues(data);
      if (job.status !== 'queued') {
        const originalJob = { ...job };
        toggleIsUpdateChecklistTaskLoading(required);
        setJob({
          ...job,
          checklists: job.checklists.map((checklist) => {
            const hasTasksThatMatch = checklist.checklistTasks.findIndex(
              (task) => task.id === id,
            );
            if (hasTasksThatMatch >= 0) {
              return {
                ...checklist,
                checklistTasks: checklist.checklistTasks.map((task, index) => {
                  if (index === hasTasksThatMatch) {
                    return {
                      ...task,
                      label,
                      notes,
                      ordering_value: ordering_value ?? orderingValue,
                      orderingValue,
                      checklistId,
                      required,
                      taskType,
                      createdAt,
                      attachment,
                      ...fulfillmentValues,
                      fulfillmentAttachment,
                      fulfillmentNote,
                    };
                  }
                  return task;
                }),
              };
            }
            return checklist;
          }),
        });
        updateChecklistTask({
          onError: () => {
            toggleIsUpdateChecklistTaskLoading(false);
            setTimeout(() => setJob(originalJob));
          },
          onCompleted: () => {
            toggleIsUpdateChecklistTaskLoading(false);
          },
          variables: {
            id,
            updateChecklistTaskInput: {
              id,
              label,
              notes,
              ordering_value: ordering_value ?? orderingValue,
              orderingValue,
              checklistId,
              required,
              taskType,
              createdAt,
              attachment,
              templateTaskId,
              ...fulfillmentValues,
              fulfillmentAttachment,
              fulfillmentNote,
            },
          },
        });
      }
    },
    [job, updateChecklistTask],
  );

  const renameFields = (checklistTasks) =>
    checklistTasks?.map((task) => ({
      ...task,
      fulfillmentAttachment:
        task.fulfillmentAttachment ?? task.fulfillmentAttachments,
      ordering_value: task.ordering_value ?? task.orderingValue,
    }));

  const orderTasks = (checklistTasks) =>
    checklistTasks?.sort(
      (taskA, taskB) =>
        Number(taskA.ordering_value) - Number(taskB.ordering_value),
    );

  const treatedChecklists = React.useMemo(() => {
    const hasSelectedChecklist =
      checklists?.length &&
      checklists.find(
        (checklist) =>
          checklist.id === id || checklist.checklistTemplateId === id,
      );
    const jobChecklists = hasSelectedChecklist
      ? checklists?.map(({ checklistTasks, ...rest }) => ({
          checklistTasks: orderTasks(renameFields(checklistTasks)),
          ...rest,
        }))
      : [
          {
            ...data,
            checklistTasks: data?.tasks || [],
          },
        ];

    return isRepeatingJobTemplate
      ? checklists?.map(({ tasks, ...rest }) => ({
          checklistTasks: orderTasks(renameFields(tasks)),
          ...rest,
        }))
      : jobChecklists;
  }, [checklists, isRepeatingJobTemplate, data]);

  return (
    <Container>
      {treatedChecklists
        .filter((treatedChecklist) =>
          id
            ? treatedChecklist.id === id ||
              treatedChecklist.checklistTemplateId === id
            : true,
        )
        ?.map(({ checklistTasks, id: checklistId }, templateIndex) => (
          <TemplateFulfillment
            key={`job-checklist-${checklistId}`}
            submitted={submitted}
            onTaskChange={(data) =>
              handleChecklistTaskChange({ checklistId, data })
            }
            onConvertTask={({ label, id }) => {
              GoogleAnalyticsClient.event(GA_EVENTS.convertTaskToJob);
              setShowModal(true);
              setTaskInfo({ label, id });
            }}
            data={checklistTasks}
            readOnly={isReadOnly || updateLoading}
          />
        ))}
      {showModal && (
        <Modal subModal modifiers={['small']}>
          <ConvertTaskToJob
            name={taskInfo.label}
            locations={job.locations}
            loading={convertTaskToJobLoading}
            defaultAssignee={job.assignee}
            onSave={(convertTaskToJobInput) => {
              GoogleAnalyticsClient.event(GA_EVENTS.confirmConvertTaskToJob);
              convertTaskToJob({
                variables: {
                  convertTaskToJobId: taskInfo.id,
                  convertTaskToJobInput,
                },
              });
            }}
            onCancel={() => setShowModal(false)}
          />
        </Modal>
      )}
    </Container>
  );
}
export default JobChecklist;
