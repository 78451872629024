import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

const Card = styled.div`
  ${(props) => props.theme.elements.card}
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 8.2rem;
  margin-top: 2.7rem;
`;

function EmptyCard(props: { message: ReactNode }) {
  const { message } = props;
  return (
    <Card data-testid="emptyCard">
      <span>{message}</span>
    </Card>
  );
}

export default EmptyCard;
