import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  hideAtSmBreak: ({ theme }) => `
    @media(max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  }`,
  long: ({ theme }) => `
    width: 39rem;
    @media (max-width: ${theme.breakpoints.lg}) {
      width: 39rem;
    }
  `,
  longest: ({ theme }) => `
    width: 52rem;
    @media (max-width: ${theme.breakpoints.lg}) {
      width: 52rem;
    }
  `,
  longDynamic: ({ theme }) => ` 
    width: 39rem;
  `,
  // use when more than 3 columns
  shortDynamic: ({ theme }) => ` 
    width: 22rem;
    margin-right: 10px;

    @media (min-width: ${theme.breakpoints.mdMax}) and (max-width: ${theme.breakpoints.miniLg}) {
      width: auto;
      min-width: 15rem;
    }
    @media (max-width: ${theme.breakpoints.mdMax}) {
      width: auto;
      min-width: 12rem;
    }
  `,
  endActions: ({ theme }) => ` 
    width: 12rem;
    margin-left: auto;
    margin-right: 10px;

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 11rem;
    }
    @media (min-width: ${theme.breakpoints.mdMax}) and (max-width: ${theme.breakpoints.miniLg}) {
      width: auto;
      min-width: 4rem;
    }
    @media (max-width: ${theme.breakpoints.mdMax}) {
      width: auto;
      min-width: 4rem;
    }
  `,
  // use when more than 3 columns and the first one is small width and the second one is bigger width
  shortDynamicSmallFirstBigSecondCol: ({ theme }) => ` 
    width: 22rem;
    margin-right: 10px;

    @media (min-width: ${theme.breakpoints.mdMax}) and (max-width: ${theme.breakpoints.miniLg}) {
      width: auto;
      min-width: 15rem;
    }
    @media (max-width: ${theme.breakpoints.mdMax}) {
      width: auto;
      min-width: 12rem;
    }
    // max-width fo the first column
    &:first-of-type {
      max-width: 14rem;
    }
    // more width for the second column
    &:nth-of-type(2) {
      @media (max-width: ${theme.breakpoints.lg}) {
        width: 25%;
        min-width: 15rem;
      }
      @media (min-width: ${theme.breakpoints.mdMax}) and (max-width: ${theme.breakpoints.miniLg}) {
        max-width: 20rem;
      }
      @media (max-width: ${theme.breakpoints.mdMax}) {
        width: calc(50% + 2.5rem);
      }
    }
  `,
  // use if checkbox-column exist
  smallColumnWidth: ({ theme }) => `
    width: 5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 7rem;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      width: 7rem;
    }
  `,
  noRightMargin: (_) => `
    margin-right: 0;
  `,
  displayTwoOnly: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      width: auto;
      min-width: 12rem;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      width: auto;
      min-width: 12rem;
    }
  `,
  medium: ({ theme }) => `
    width: 25rem;
    @media (max-width: ${theme.breakpoints.lg}) {
      width: 25rem;
    }
  `,
  superSmall: (props) => `
    width: 3rem;
    @media (max-width: ${props.theme.breakpoints.lg}) {
      width: 3rem;
    }
    @media (max-width: ${props.theme.breakpoints.sm}) {
      width: 3rem;
    }
  `,
  hiddenOverflow: () => `
    overflow: hidden;
  `
};

const Col = styled.div`
  width: 22rem;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 13rem;
  }
  & span {
    font-size: 1.6rem;
    ${(props) => props.theme.fonts.weights.light}
  }
  /* if Field component is inside table Col */
  & > .tableFieldEdit {
    padding-left: 0;
    padding-right: 2.5rem;
    margin: 0;
    & span {
      left: -1.2rem;
    }
    & + .error {
      padding-inline-start: 0;
    }
  }
  & > .tableField {
    margin: 0;
    min-height: auto;
    height: auto;
    & input {
      padding-inline-start: 0;
    }
  }
  & > .tableFieldEditInline {
    margin: 0;
    height: initial;
    min-height: initial;
    input {
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.charcoal03};
      border-radius: 0;
      width: 90%;
      padding-inline-start: 0.5rem;
      &:focus {
        border: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.teal};
      }
    }
  }

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Col;
