import React from 'react';
import { ButtonLinkStyled } from './styles';

const ButtonLink: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return <ButtonLinkStyled {...props}>{children}</ButtonLinkStyled>;
};

export { default as ButtonQuad } from './ButtonQuad';
export default ButtonLink;
