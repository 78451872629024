import styled from 'styled-components/macro';
import { ReactComponent as Icon } from './plus.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  green: ({ theme }: { theme: any }) => `
    #oval {
      fill: ${theme.colors.charcoal03};
    };
    #plus {
      stroke: ${theme.colors.white};
    }
    :active, :hover {
    #plus {
      stroke: ${theme.colors.white};
    }
  }
  `,
};

const Plus = styled(Icon)`
  :active,
  :hover {
    #plus {
      stroke: ${(props) => props.theme.colors.teal};
    }
  }
  cursor: pointer;
  ${(props) => props.theme.animations.hoverUp()}

  ${applyStyleModifiers(CONFIG)};
`;
export default Plus;
