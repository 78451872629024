import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, SPACING, Spin } from '@optii/ui-library';
import { usePropertyTime, UserAccessContext } from '@optii/shared';
import { JobForm } from '@optii/jobs/components/form';
import styled from 'styled-components';
import { StringParam, useQueryParams } from 'use-query-params';
import { LoadingOutlined } from '@ant-design/icons';
import { SelectOption } from '@optii/global';
import { JobFormV2, JobForm as LegacyJobForm } from '@optii/jobs/index';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JobDetails from '@optii/topcat-client/components/Jobs/JobDetails';
import moment from 'moment';
import {
  useGenerateEncryptedEmbedUrlQuery,
  useReportsQuery,
} from '../../api/reports';
import { SIGMA_EVENTS } from '../utils/constants';

const Iframe = styled.iframe`
  border: ${SPACING.NONE};
  width: 100%;
  height: 81vh;
  * {
    overflow: hidden !important;
    & div:first-child {
      overflow: hidden !important;
    }
  }
`;

type JobProps = {
  open: boolean;
  properties: SelectOption[];
};

type PrimitivePropertyObject = {
  property_id: number;
  display_name: string;
};

const SUPPORTED_LANGUAGES = {
  'es-MX': 'es',
  es: 'es',
  en: 'en',
};

function extractProperties(shards: Array<Array<PrimitivePropertyObject>>) {
  const formattedProperties = shards.flatMap((property) => {
    const [propertyValues] = property;
    return {
      label: propertyValues.display_name,
      value: propertyValues.property_id.toString(),
    };
  });

  return formattedProperties;
}

export function JobsManagement() {
  const { user } = useContext(UserAccessContext.Context);
  const jobManagementRef = useRef<HTMLIFrameElement | null>(null);
  const { toPropertyTime } = usePropertyTime(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openV2, setOpenV2] = useState(false);

  const CURRENT_DATE_IN_PROPERTY = toPropertyTime(moment().unix()).format(
    'YYYY-MM-DD',
  );

  const [jobProps, setJobProps] = useState<JobProps>({
    properties: [],
    open: false,
  });

  const [queryParams, setQueryParams] = useQueryParams({
    edit: StringParam,
    openJob: StringParam,
    shard: StringParam,
  });

  const { data } = useReportsQuery({
    variables: {
      filters: 'type==report,name==Jobs Management',
    },
  });

  const { data: src } = useGenerateEncryptedEmbedUrlQuery({
    variables: {
      embedKey: data?.reports?.edges?.[0].node.embedKey,
      userEmail: user?.emailAddr,
      lng: SUPPORTED_LANGUAGES[
        user?.preferredLang as keyof typeof SUPPORTED_LANGUAGES
      ],
    },
    onError(error) {
      console.error(error);
    },
    skip: !user?.emailAddr || !data?.reports.edges?.length,
  });

  useEffect(() => {
    function onMessage(event: MessageEvent<any>) {
      const { data: sigmaData, source, origin } = event;
      if (event.data.type === 'workbook:loaded') {
        setIsLoaded(true);
      }

      if (
        source === jobManagementRef.current?.contentWindow &&
        origin === 'https://app.sigmacomputing.com' &&
        sigmaData.type === SIGMA_EVENTS.outbound
      ) {
        console.log('SIGMA EVENT:', sigmaData);
        if (sigmaData.name === 'Create-Job') {
          const properties = extractProperties(
            sigmaData.values?.Shards || sigmaData.values?.Shard,
          );

          setJobProps({
            properties,
            open: true,
          });
        }

        if (sigmaData.name === 'Edit-Job') {
          setQueryParams({
            openJob: sigmaData.values?.['Job-Id'].toString(),
            shard: sigmaData.values?.Shard?.toString() || '562',
          });
        }
      }
    }

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [setQueryParams]);

  useEffect(() => {
    if (jobManagementRef.current?.contentWindow && isLoaded) {
      jobManagementRef.current.contentWindow.postMessage(
        {
          type: 'workbook:variables:update',
          variables: {
            'Date-Added-Filter': `min:${CURRENT_DATE_IN_PROPERTY}T00:00:00,max:${CURRENT_DATE_IN_PROPERTY}T23:59:59`,
          },
        },
        'https://app.sigmacomputing.com',
      );
    }
  }, [CURRENT_DATE_IN_PROPERTY, isLoaded]);

  return src?.url ? (
    <>
      <JobFormV2 open={openV2} setOpen={setOpenV2} />
      {queryParams.openJob ? (
        <JobDetails
          close={() => {
            setQueryParams({
              edit: queryParams.edit,
              openJob: undefined,
              shard: undefined,
            });
          }}
          shard={queryParams.shard}
          legacyLogs={[]}
          open={!!queryParams.openJob}
        />
      ) : null}

      {queryParams.edit && queryParams.openJob && queryParams.shard ? (
        <LegacyJobForm
          onClose={() => {
            setQueryParams({
              edit: undefined,
              openJob: undefined,
              shard: undefined,
            });
          }}
          open={!!queryParams.openJob}
          mode="edit"
          propertyShard={queryParams.shard}
        />
      ) : null}

      <Button
        onClick={() => {
          setOpenV2(true);
        }}
      >
        Open New Job Form
      </Button>
      <Iframe
        title="Job Management"
        id="job-management-iframe"
        data-testid="job-management-iframe"
        src={src.url}
        seamless
        ref={jobManagementRef}
      />
      <JobForm
        {...jobProps}
        onClose={() =>
          setJobProps((prev) => ({
            ...prev,
            open: false,
          }))
        }
      />
    </>
  ) : (
    <Spin
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
      }}
      spinning
      indicator={<LoadingOutlined spin />}
      size="large"
    />
  );
}
