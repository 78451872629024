import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DragScrollBar,
} from 'react-virtualized-dnd';
import { v4 as uuidv4 } from 'uuid';
import Task from '../../blocks/Task.tsx';
import { DraggableContainer } from '../../elements.tsx';
import { CHECKLIST_TYPES } from '../../taskConstants.ts';
import { CHECKLIST_TASK_TYPE_CONFIG } from '../../constants.ts';

function DreaggableItem(props) {
  const { disabled } = props;
  const Component = disabled ? 'div' : Draggable;

  return <Component {...props} />;
}

function CheckListTemplate({
  data = [],
  onChange = (a) => {
    // onChange method
  },
  isScoringEnabled,
  isReadOnly = false,
  isAdd = false,
  isEdit = false,
  footer,
  draggable = true,
}) {
  const defaultCheckListType = data?.length
    ? data[data.length - 1]?.taskType
    : CHECKLIST_TYPES.checkBox;
  const [checkListTasks, setCheckListTasks] = useState(data || []);
  const [lasttaskType, setLasttaskType] = useState(defaultCheckListType);
  const [close, setClose] = useState({});
  const [canDrag, setCanDrag] = useState(false);

  function validation({ needBeTouched } = {}) {
    let hasError = false;

    if (needBeTouched) {
      hasError = checkListTasks.some(({ isValid = true, touched }) =>
        isValid ? false : !(isValid && touched?.label),
      );
    } else {
      hasError = checkListTasks.some(({ isValid = true }) => !isValid);
    }
    return !hasError;
  }

  const getItemTemplate = () => {
    const id = uuidv4();
    const taskType = lasttaskType;
    const copiedValues = CHECKLIST_TASK_TYPE_CONFIG[
      taskType
    ].getPreviousTaskValue(checkListTasks[checkListTasks.length - 1]);
    return {
      id,
      taskType,
      label: '',
      pointsComplete:
        taskType === 'groupHeader' || taskType === 'readOnly' ? null : 1,
      ...copiedValues,
    };
  };

  function autoAddNextItem() {
    const emptyTemplate = checkListTasks.length === 0;
    const isValid = validation({ needBeTouched: true });
    const MIN_LABEL_SIZE = 1;
    const skip = isReadOnly || (!emptyTemplate && !isValid);

    if (skip) {
      return false;
    }

    const isAllTasksReady = checkListTasks.every(
      (task) => task.label?.length >= MIN_LABEL_SIZE,
    );

    if (emptyTemplate || isAllTasksReady) {
      const newCheckListTasks = [...checkListTasks];
      const newItem = getItemTemplate();
      newCheckListTasks.push(newItem);
      setCheckListTasks(newCheckListTasks);
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (isAdd || isEdit) {
      autoAddNextItem();
    }
  }, [data]);

  useEffect(() => {
    onChange({
      data: checkListTasks,
      isValid: validation(),
    });
  }, [checkListTasks]);

  const handleItemChange = (incomingValues) => {
    const newCheckListTasks = checkListTasks.map((item) => {
      let newItem = item;
      if (item.id === incomingValues.id) {
        newItem = incomingValues;
      }
      return newItem;
    });
    setLasttaskType(incomingValues.taskType);
    setCheckListTasks(newCheckListTasks);
  };

  const handleDelete = ({ id }) => {
    const newCheckListTasks = checkListTasks.filter((item) => item.id !== id);
    setCheckListTasks(newCheckListTasks);
  };

  function arrayMove(arr, oldIndex, newIndex) {
    let nextIndex = newIndex;
    let prevIndex = oldIndex;
    if (nextIndex > prevIndex) {
      nextIndex -= 1;
    }
    while (prevIndex < 0) {
      prevIndex += arr.length;
    }
    while (nextIndex < 0) {
      nextIndex += arr.length;
    }
    if (nextIndex >= arr.length) {
      let k = nextIndex - arr.length + 1;
      while ((k -= 1)) {
        arr.push(undefined);
      }
    }

    arr.splice(nextIndex, 0, arr.splice(prevIndex, 1)[0]);
    return arr;
  }

  const firstGroupHeader = useMemo(
    () =>
      checkListTasks?.reduce((lastIndex, currentTask, taskIndex) => {
        if (lastIndex > -1) return lastIndex;
        return currentTask.taskType === CHECKLIST_TYPES.groupHeader
          ? taskIndex
          : lastIndex;
      }, -1),
    [checkListTasks],
  );

  const groupedTasks = useMemo(() => {
    if (!checkListTasks || checkListTasks.length === 0) return checkListTasks;

    const groups = {};
    let groupId;

    checkListTasks.forEach((task, index) => {
      if (task.taskType === CHECKLIST_TYPES.groupHeader) {
        groupId = task.id;
        groups[groupId] = [];
      }

      if (task.taskType !== CHECKLIST_TYPES.groupHeader && groupId) {
        groups[groupId].push(task);
      }
    });

    return groups;
  }, [checkListTasks]);

  const isSubTask = useCallback(
    (task, taskIndex) => {
      if (firstGroupHeader === -1) return false;
      return (
        task.taskType !== CHECKLIST_TYPES.groupHeader &&
        taskIndex > firstGroupHeader
      );
    },
    [checkListTasks],
  );

  const haSubTask = useCallback(
    (task) => {
      const isGroup =
        Object.keys(groupedTasks).includes(task.id) &&
        task.taskType === CHECKLIST_TYPES.groupHeader;
      if (!isGroup) return false;

      const hasNonBlankTasks = groupedTasks[task.id]?.some((groupTask) => {
        const hasLabel = groupTask.label?.length > 0;
        const hasNote = groupTask.note?.length > 0;
        const hasAttachment = groupTask.attachment?.length > 0;
        const hasContent = hasLabel || hasNote || hasAttachment;
        return hasContent;
      });

      return hasNonBlankTasks;
    },
    [checkListTasks],
  );

  const handleToggleOpen = (groupId) => {
    const newClose = { ...close };
    const isClosed = !!close[groupId];

    if (isClosed) {
      delete newClose[groupId];
    } else {
      newClose[groupId] = true;
    }

    setClose(() => newClose);
  };

  const showTask = (task) => {
    let group;
    Object.entries(groupedTasks).forEach(([groupId, groupTasks]) => {
      const subTask = groupTasks.some(({ id }) => id === task.id);
      if (subTask) {
        group = groupId;
      }
    });

    if (!group) return true;

    const isClosed = close[group];

    return !isClosed;
  };

  const getFulfillmentValues = (task) =>
    CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.getValue(task);

  const getTaskPositionById = (taskId) => {
    const position = checkListTasks.findIndex((task) => task.id === taskId);
    return position;
  };

  const onDrag = (source, destinationId, placeholderId) => {
    const dragIndex = getTaskPositionById(source.draggableId);
    const hoverIndex = getTaskPositionById(placeholderId);
    const newlist = arrayMove([...checkListTasks], dragIndex, hoverIndex);
    setCheckListTasks(() => newlist);
    setCanDrag(() => false);
  };

  const dndHeight = draggable ? window.innerHeight - 295 : window.innerHeight;

  return (
    <>
      <DragDropContext
        dragAndDropGroup="name"
        onDragEnd={onDrag}
        onDragStart={() => setClose({})}
        outerScrollBar
        scrollContainerHeight={dndHeight}
      >
        <DraggableContainer data-testid="checklist-template">
          <DragScrollBar maxHeight={999999999999999} dragAndDropGroup="name">
            <Droppable
              dragAndDropGroup="name"
              droppableId="droppableId"
              key="droppableId"
            >
              {checkListTasks?.map((task, position) =>
                showTask(task) ? (
                  <DreaggableItem
                    dragActiveClass="-active"
                    dragAndDropGroup="name"
                    draggableId={task.id}
                    containerHeight={500}
                    disabled={
                      !draggable ||
                      !canDrag ||
                      position >= checkListTasks.length - 1
                    }
                  >
                    <Task
                      {...task}
                      key={task.id}
                      onChange={handleItemChange}
                      onDelete={handleDelete}
                      isScoringEnabled={isScoringEnabled}
                      deletable={!haSubTask(task)}
                      isAdd={isAdd}
                      isEdit={isEdit}
                      isReadOnly={isReadOnly}
                      onToggleOpen={() => handleToggleOpen(task.id)}
                      position={position}
                      subTask={isSubTask(task, position)}
                      last={position === checkListTasks.length - 1}
                      isOpen={!close[task.id]}
                      setCanDrag={setCanDrag}
                      canDrag={canDrag}
                      {...getFulfillmentValues(task)}
                    />
                  </DreaggableItem>
                ) : (
                  <div />
                ),
              )}
            </Droppable>
          </DragScrollBar>
        </DraggableContainer>
      </DragDropContext>
      {footer}
    </>
  );
}

export default CheckListTemplate;
