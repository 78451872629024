import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  withMoreThanThreeTableColumns: ({ theme }) => `
  @media (max-width: ${theme.breakpoints.lg}) {
    max-width: 100%;
  }
  `,
  wider: () => `max-width: 1128px;`,
};

const Main = styled.div`
  max-width: 1028px;
  min-height: 30rem;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    max-width: 687px;
  }
  padding: 0 0 0 0.5rem;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Main;
