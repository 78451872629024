import styled from 'styled-components/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { ErrorMessage, getIn } from 'formik';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import { isPlatform } from '@ionic/react';
import {
  SelectTag,
  TreeSelectLocations,
} from '@optii-solutions/ui23-antd-components';
import { ReactComponent as ArrowDown } from 'images/svg/arrow-down.svg';
import HelpTip from '../HelpTip';
import Checkbox from '../Checkbox';
import FieldBlock from './FieldBlock';
import RequiredStar from './RequiredStar';
import Select from './Select';
import PhoneInput from './PhoneInput';
import '@optii-solutions/ui23-antd-components/index.css';

const sharedLeftPadding = '1.32rem';

const Error = styled(ErrorMessage)`
  color: ${(props) => props.theme.colors.roseDark};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.weak}
`;
const Textarea = styled(TextareaAutosize)`
  ${(props) => props.theme.fonts.type.primary};
  min-height: 44px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  padding-inline-start: ${sharedLeftPadding};
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.borders.default}
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.light}
  resize: none;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  &::placeholder {
    ${(props) => props.theme.fonts.type.primary};
    ${(props) => props.theme.fonts.weights.light};
    ${(props) => props.theme.colors.charcoal04};
    font-style: italic;
  }
`;
/*
checkbox must have setFieldTouched...
*/

// to support Select value which is an Object
const modifyErrors = (errors: any) => {
  const updatedErrors = mapValues(errors, (o) => {
    if (o && o.displayName) {
      return o.displayName;
    }
    return o;
  });
  return updatedErrors;
};

const FormikField = React.forwardRef((props: any, ref: any) => {
  const hasLabel = props.label && props.type !== 'checkbox';
  const error = getIn(modifyErrors(props.errors), props.name);
  const touched = getIn(props.touched, props.name);
  const { t } = useTranslation(['fields']);
  const { handleKeyDown, heightChangeHandler, ...rest } = props;
  const value = props.value && props.value.id ? props.value.id : props.value;
  const correctOpt =
    (props.options && props.options.find((item: any) => item.id === value)) ||
    props.value;

  function renderSwitch() {
    switch (props.type) {
      case 'treeSelect':
        return (
          <TreeSelectLocations
            {...props}
            suffixIcon={<ArrowDown />}
            size="large"
            notFoundContent
            multiple
            placeholder={t('fields:Search...')}
            placement={isPlatform('capacitor') ? 'topLeft' : ''}
          />
        );
      case 'tel':
        return (
          <PhoneInput
            placeholder={t('fields:None')}
            {...props}
            error={touched && error}
          />
        );
      case 'select':
        if (props.readOnly && !props.customReadOnly) {
          let readOnlyOption;
          if (props.isMulti) {
            readOnlyOption =
              props.value &&
              props.value.map((prev: any) => prev.displayName).join(', ');
          } else {
            readOnlyOption = props.value && props.value.displayName;

            if (!readOnlyOption && props.options) {
              readOnlyOption = props.options.find(
                (item: any) => item.id === props.value,
              );
              readOnlyOption = readOnlyOption && readOnlyOption.displayName;
            }
          }

          return (
            <FieldBlock.Input
              isFormik
              placeholder={t('fields:None')}
              id={props.name}
              {...props}
              value={readOnlyOption || ''}
              error={touched && error}
            />
          );
        }
        if (props.readOnly && props.customReadOnly) {
          return props.customReadOnly(props.value);
        }

        return (
          <Select
            placeholder={t('fields:None')}
            onBlur={() => props.setFieldTouched(props.name)}
            {...props}
            error={touched && error}
            value={correctOpt}
            blurred={touched}
            onChange={(e: any) => {
              if (props.setFieldTouched) props.setFieldTouched(props.name);
              if (props.onChange) props.onChange(e);
            }}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            {...props}
            onChange={(e: any) => {
              props.setFieldTouched(props.name);
              props.onChange(e);
            }}
          />
        );
      case 'textarea':
        return (
          <Textarea
            {...rest}
            id={props.name}
            onKeyDown={handleKeyDown}
            onHeightChange={heightChangeHandler}
            ref={ref}
            data-testid={props.name}
          />
        );
      // There's some functionality that comes with the formik Field component that we want to use
      // However, if we replace everything, it would result in breaking changes throughout the app
      case 'formikText':
        return (
          <FieldBlock.FormikInput
            isFormik
            id={props.name}
            placeholder={props.placeholder || t('fields:None')}
            {...props}
            error={error}
            // We've removed custom logic from this
            // Instead, we use the Field component from Formik in Input.JS (from form block)
            type="text"
            data-testid={props.name}
          />
        );
      case 'selectTag':
        return (
          <SelectTag
            id={props.name}
            options={props.options}
            onChange={props.onChange}
            handleOptionCreation={props.onCreateOption}
            value={props.value}
            allowClear
            {...props}
          />
        );
      default:
        return (
          <FieldBlock.Input
            isFormik
            id={props.name}
            className="basic-input"
            placeholder={props.placeholder || t('fields:None')}
            {...props}
            error={touched && error}
            onChange={(e) => {
              if (props.setFieldTouched) props.setFieldTouched(props.name);
              if (props.onChange) props.onChange(e);
            }}
            blurred={touched}
            data-testid={props.name}
          />
        );
    }
  }
  const isRequired =
    props.required ||
    (props.schema &&
      props.schema.fields &&
      props.schema.fields[props.name] &&
      props.schema.fields[props.name]
        .describe()
        .tests.filter((item: any) => item.name === 'required').length > 0);
  const blockProps = omit(props, ['onChange', 'name']);
  return (
    <>
      <FieldBlock {...blockProps}>
        {!props.readOnly && isRequired && (
          <RequiredStar
            {...{
              isInlineFormikField: props.isInlineFormikField,
              topLabel: props.topLabel,
            }}
          />
        )}
        {hasLabel && (
          <FieldBlock.Label
            htmlFor={props.name}
            topLabel={props.topLabel}
            {...(props.hideLabel ? { modifiers: ['hidden'] } : {})}
          >
            {props.label}
          </FieldBlock.Label>
        )}
        {renderSwitch()}
        {props.help && <HelpTip text={props.help} />}
      </FieldBlock>
      <Error component="div" name={props.name} />
    </>
  );
});
export default FormikField;
