import React, {
  useContext,
  lazy,
  Suspense,
  Fragment,
  useEffect,
  useMemo,
} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PERMISSIONS } from 'utils/constants';

import { Chat } from '@optii/chat';
import { AboveProperty } from '@optii/above-property';
import SecureRoute from 'components/shared/SecureRoute';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { PropertyContext, EmploymentContext } from '@optii/shared';
import Loading from 'components/shared/Loading';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import ImplicitCallback from './ImplicitCallback';

const Login = lazy(() => import('components/Login/Login'));
const GlobalHeader = lazy(() => import('components/Main/GlobalHeader'));
const Dashboard = lazy(() => import('components/Dashboard/Dashboard'));
const Schedule = lazy(() => import('components/Schedule/Schedule'));
const Settings = lazy(() => import('components/Settings/Settings'));
const Profile = lazy(() => import('components/Profile'));
const Jobs = lazy(() => import('components/Jobs'));
const Reports = lazy(() => import('components/Reports'));
const Engineering = lazy(() => import('components/Engineering'));

const MODIFIER_CONFIG = {
  overflowHidden: () => `
    overflow: hidden;
  `,
};

const RoutesContainer = styled.div`
  height: 100%;
`;

const Wrap = styled.div`
  height: 100%;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

const Content = styled.div`
  margin: 0;
  width: 100%; // TODO: return to vw if any page width broken
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 100%;
    margin: 0;
    padding: ${(props) => props.theme.sizes.mobileHeaderHeight} 0 0 0;
  }
`;

const Routes = function Routes() {
  const { property, loaded } = useContext(PropertyContext.Context);
  const { employee } = useContext(EmploymentContext.Context);

  const IS_ABOVE_PROPERTY_ACTIVE = sessionStorage.getItem('orgId');
  const broadcastChannel = useMemo(
    () => new BroadcastChannel('serviceWorkerChannel'),
    [],
  );

  useEffect(() => {
    broadcastChannel.postMessage({
      userId: employee.userId?.toString(),
    });
  }, [employee, broadcastChannel]);
  const isLoaded = loaded && employee && property;

  if (IS_ABOVE_PROPERTY_ACTIVE) {
    return isLoaded ? (
      <ErrorBoundary>
        <Suspense fallback={<Loading loading />}>
          <AboveProperty />
        </Suspense>
      </ErrorBoundary>
    ) : (
      <Loading loading />
    );
  }

  return (
    <RoutesContainer>
      <Wrap modifiers="overflowHidden" id="scroll-wrapper">
        <ErrorBoundary>
          <Suspense fallback={<Loading loading />}>
            <Switch>
              {/* Unsecured routes */}
              <Route
                path="/implicit/callback"
                render={(routeProps) => (
                  <ImplicitCallback routeProps={routeProps} />
                )}
              />

              <Route
                path="/login"
                render={(routeProps) => (
                  <Content className="content">
                    <Login {...routeProps} />
                  </Content>
                )}
              />

              {/* Secured routes */}
              <SecureRoute
                render={(routeProps) => (
                  <>
                    {loaded && <GlobalHeader />}
                    {/* Don't load content if a property isn't selected */}
                    {loaded && employee && property ? (
                      <Content className="content">
                        <Switch>
                          <SecureRoute
                            path="/settings"
                            useCanOr
                            render={(props) => (
                              <ErrorBoundary key="1">
                                <Settings {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[
                              PERMISSIONS.settings.notifications.view,
                              PERMISSIONS.settings.jobItems.view,
                              PERMISSIONS.settings.any,
                            ]}
                          />

                          <SecureRoute
                            path="/dashboard"
                            exact
                            render={(props) => (
                              <ErrorBoundary key="2">
                                <Dashboard {...props} />
                              </ErrorBoundary>
                            )}
                          />

                          <SecureRoute
                            path="/schedule"
                            render={(props) => (
                              <ErrorBoundary key="3">
                                <Schedule {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[PERMISSIONS.schedule.any]}
                          />

                          <SecureRoute
                            path="/projects"
                            render={(props) => (
                              <ErrorBoundary key="3">
                                <Engineering {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[PERMISSIONS.engineering.any]}
                          />

                          <SecureRoute
                            path="/jobs"
                            render={(props) => (
                              <ErrorBoundary key="4">
                                <Jobs {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[PERMISSIONS.jobs.any]}
                          />

                          {/* <SecureRoute
                            path="/messages/:id?"
                            render={(props) => (
                              <ErrorBoundary key="5">
                                <Messaging {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[PERMISSIONS.communications.any]}
                          /> */}

                          <SecureRoute
                            path="/messages"
                            render={(props) => (
                              <ErrorBoundary key="5">
                                <Chat {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[PERMISSIONS.communications.any]}
                          />

                          <SecureRoute
                            path="/profile/:id?"
                            render={(props) => (
                              <ErrorBoundary key="6">
                                <Profile {...props} />
                              </ErrorBoundary>
                            )}
                          />

                          <SecureRoute
                            path="/reports"
                            render={(props) => (
                              <ErrorBoundary key="7">
                                <Reports {...props} />
                              </ErrorBoundary>
                            )}
                            permissions={[PERMISSIONS.reports.any]}
                          />

                          <Route exact path="/">
                            <Redirect to="/dashboard" />
                          </Route>

                          {/* handle 404 Not Found */}
                          <ErrorBoundary {...{ notFoundErr: true }}>
                            <Route path="*" />
                          </ErrorBoundary>
                        </Switch>
                      </Content>
                    ) : (
                      <Loading loading />
                    )}
                  </>
                )}
              />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Wrap>
    </RoutesContainer>
  );
};

export default Routes;
