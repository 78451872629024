import { useContext } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import styled from 'styled-components/macro';
import { useQuery } from '@apollo/client';
import { GET_ROLES_LIST } from 'queries';
import { ReactComponent as ArrowIcon } from 'images/svg/arrow-right.svg';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { UserAccessContext } from '@optii/shared/index';
import usePropertyTime from 'utils/usePropertyTime';
import { COLORS } from '@optii-solutions/ui-library';
import jobLog from './ActivityLogs/JobLog';
import Loading from '../Loading';
import { Log, ItemChanges } from './types';

const Container = styled.div`
  padding: 0px 24px;
`;
const Body = styled.p`
  margin: 0;
`;
const NoLogBody = styled.p`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
`;
const ItemBody = styled.span`
  margin: 0;
  font-weight: 400;
  color: #595959;
`;
const Details = styled.span`
  color: #595959;
  font-weight: 400;
`;
const Bold = styled.span`
  margin: 0;
  font-weight: 500;
  color: #595959;
`;
const Id = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.teal};
  min-width: 60px;
  padding-right: 5px;
  font-weight: 400;
  &:hover {
    cursor: pointer;
  }
`;
const Date = styled.p`
  margin: 0;
  color: #595959;
  font-weight: 400;
  text-align: right;
`;
const ActivityWrap = styled.div`
  margin-top: 5px;
  min-height: 50px;
  display: flex;
  border-bottom: solid 1px #d9d9d9;
`;
const NoLogsWrap = styled.div`
  margin-top: 5px;
  min-height: 236px;
`;
const DateWrap = styled.div`
  width: 35%;
  text-align: end;
`;
const BodyWrap = styled.div`
  display: flex;
  width: 65%;
`;
const TextField = styled.input`
  ${(props) => props.theme.fonts.sizes.large};
  ${(props) => props.theme.fonts.weights.weak};
  text-align: end;
  padding: 0;
  border: none;
  font-family: 'Roboto';
  color: ${COLORS.neutral[8]};
  background-color: transparent;
`;

const filterMetadata = (log: Log) => {
  if (log.eventType === 'Modified' && log.action === 'Modified') {
    return log.metadata?.changes?.filter((item) => item.old !== 'null').length;
  }
  return true;
};

export default function ActivityLog({
  data,
  loading,
}: {
  data: Log[];
  loading: boolean;
}) {
  const [j, setOpenJob] = useQueryParam('openJob', StringParam);
  const { timezone } = usePropertyTime();
  const { data: rolesData } = useQuery(GET_ROLES_LIST);
  const { user } = useContext(UserAccessContext.Context);
  const { preferredLang } = user;

  dayjs.extend(localizedFormat);
  dayjs.locale(preferredLang);

  if (!data || data.length === 0 || !rolesData) {
    return (
      <Container>
        <NoLogsWrap>
          {loading ? (
            <Loading loading />
          ) : (
            <NoLogBody>
              <Bold>No available logs for this location.</Bold>
            </NoLogBody>
          )}
        </NoLogsWrap>
      </Container>
    );
  }

  const rolesList = rolesData.page.edges;
  const logs = data.filter(filterMetadata);

  return (
    data && (
      <Container>
        {logs.map((item: Log) => {
          const date = dayjs(item.recordedAt).tz(timezone).format('L LT');
          const { metadata } = item;
          const { changes } = metadata;
          const [status] = changes || [];
          const log = jobLog(item, timezone, undefined, rolesList);
          if (!log) return null;
          const {
            message,
            fullName,
            oldMetadata,
            newMetadata,
            showMeta,
            showArrow,
            id,
            reservationCheck,
            guestData,
          } = log;
          const { guestFullName, guestId } = guestData || {};
          const fullCheck = (reservationCheck || !id);

          if (status) {

            return changes?.map((change) => {
              const log2 = jobLog(item, timezone, change, rolesList);
              if (log2 === null) return null;
              const {
                oldMetadata: changeOldMetadata,
                newMetadata: changeNewMetadata,
                showMeta: changeShowMetadata,
                showArrow: changeShowArrow,
                itemChanges,
              } = log2;
              let { message: changeMessage } = log2;
              const itemMessage = (
                <>
                  <ItemBody>Item: </ItemBody>
                  <Bold>Updated</Bold>
                </>
              );

              if (itemChanges?.length === 0) return null;
              if (itemChanges && itemChanges?.length !== 0) {
                return itemChanges.map((itemChange: ItemChanges) => {
                  const { newItems, newAmount, oldAmount } = itemChange;
                  const newName = newAmount ? newAmount.name : '';
                  const newItemName = newItems ? newItems.name : '';
                  const newItemAmount = newItems ? newItems.amount : '';
                  changeMessage = oldAmount ? itemMessage : changeMessage;
                  return (
                    <ActivityWrap
                      key={`${item.eventType}-${item.recordedAt}-${item.aggregateVersion}`}
                    >
                      <BodyWrap>
                        {fullCheck ? '' : 
                        <Id
                          onClick={() => {
                            setOpenJob(id);
                          }}
                        >{`#${id}`}</Id>}
                        <Body>
                          {changeMessage}
                          <br />
                          <>
                            <Details>
                              {`${newAmount ? newName : newItemName} `}{' '}
                              {oldAmount ? `Count: ${oldAmount?.amount}` : ''}
                            </Details>
                            {oldAmount ? <ArrowIcon /> : ''}
                            <Details>
                              {oldAmount ? ` Count: ${newAmount?.amount}` : ` Count: ${newItemAmount}`}
                            </Details>
                          </>
                        </Body>
                      </BodyWrap>
                      <DateWrap>
                        <Date>{date}</Date>
                        <TextField type="button" value={fullName} />
                      </DateWrap>
                    </ActivityWrap>
                  );
                });
              }

              return loading ? (
                <Loading loading />
              ) : (
                <ActivityWrap>
                  <BodyWrap>
                    {fullCheck ? '' : 
                      <Id
                        onClick={() => {
                          setOpenJob(id);
                        }}
                      >{`#${id}`}</Id>}
                    <Body>
                      {changeMessage}
                      <br />
                      {changeShowMetadata ? (
                        <>
                          <Details>{`${changeOldMetadata} `}</Details>
                          {changeShowArrow ? <ArrowIcon /> : ''}
                          <Details>{` ${changeNewMetadata}`}</Details>
                        </>
                      ) : (
                        ''
                      )}
                      <br />
                      {
                        guestData ? (`Guest ID: #${guestId} - ${guestFullName}`) :
                        ''
                      }
                    </Body>
                  </BodyWrap>
                  <DateWrap>
                    <Date>{date}</Date>
                    <TextField type="button" value={reservationCheck ? `ResID: #${id}` : fullName} />
                  </DateWrap>
                </ActivityWrap>
              );
            });
          }

          return loading ? (
            <Loading loading />
          ) : (
            <ActivityWrap>
              <BodyWrap>
                {fullCheck ? '' : 
                  <Id
                    onClick={() => {
                      setOpenJob(id);
                    }}
                  >{`#${id}`}</Id>}
                <Body>
                  {message}
                  <br />
                  {showMeta ? (
                    <>
                      <Details>{`${oldMetadata} `}</Details>
                      {showArrow ? <ArrowIcon /> : ''}
                      <Details>{` ${newMetadata}`}</Details>
                    </>
                  ) : (
                    ''
                  )}
                  {showMeta ? <br /> : ''}
                  {
                    guestData ? (`Guest ID: #${guestId} - ${guestFullName}`) :
                      ''
                  }
                </Body>
              </BodyWrap>
              <DateWrap>
                <Date>{date}</Date>
                <TextField type="button" value={reservationCheck ? `ResID: #${id}` : fullName} />
              </DateWrap>
            </ActivityWrap>
          );
        })}
      </Container>
    )
  );
}
