import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & h3 {
    margin: 0;
    font-size: 2.2rem;
    & + div {
      font-size: 1.6rem;
    }
  }
`;

export default Title;
