export const CHECKLIST_TASK_FIELDS = {
  archivedAt: 'archivedAt',
  attachment: 'attachment',
  checklistId: 'checklistId',
  createdAt: 'createdAt',
  standard: 'standard',
  dataCheckboxChecked: 'dataCheckboxChecked',
  dataNumerical: 'dataNumerical',
  dataOnOff: 'dataOnOff',
  dataPassFail: 'dataPassFail',
  dataTemperatureValue: 'dataTemperatureValue',
  dataTemperatureScale: 'dataTemperatureScale',
  dataDropdownValue: 'dataDropdownValue',
  dropdownOptions: 'dropdownOptions',
  dataText: 'dataText',
  dataYesNo: 'dataYesNo',
  fulfillmentAttachment: 'fulfillmentAttachment',
  fulfillmentNote: 'fulfillmentNote',
  id: 'id',
  label: 'label',
  notes: 'notes',
  ordering_value: 'ordering_value',
  parent_task_id: 'parent_task_id',
  required: 'required',
  taskType: 'taskType',
  updatedAt: 'updatedAt',
};

export const CHECKLIST_CONVERSIONS = {
  job: 'job',
};

export const CHECKLIST_TYPES = {
  groupHeader: 'groupHeader',
  checkBox: 'checkBox',
  readOnly: 'readOnly',
  separator: 'separator',
  numerical: 'numerical',
  yesNo: 'yesNo',
  passFail: 'passFail',
  text: 'text',
  temperature: 'temperature',
  onOff: 'onOff',
  dropdown: 'dropdown',
};

export const CHECKLIST_ACTION_STATES = {
  addingItem: 'addingItem',
  viewingItem: 'viewingItem',
  fulfillment: 'fulfillment',
  addingNote: 'addingNote',
  addingAttachment: 'addingAttachment',
};

export const TASK_TYPE_OPTIONS = [
  {
    label: 'Checkbox',
    value: CHECKLIST_TYPES.checkBox,
  },
  {
    label: 'Group Header',
    value: CHECKLIST_TYPES.groupHeader,
  },
  {
    label: 'Read-Only',
    value: CHECKLIST_TYPES.readOnly,
  },
  {
    label: 'On | Off',
    value: CHECKLIST_TYPES.onOff,
  },
  {
    label: 'Temperature',
    value: CHECKLIST_TYPES.temperature,
  },
  {
    label: 'Dropdown',
    value: CHECKLIST_TYPES.dropdown,
  },
  {
    label: 'Numeric',
    value: CHECKLIST_TYPES.numerical,
  },
  {
    label: 'Pass | Fail',
    value: CHECKLIST_TYPES.passFail,
  },
  {
    label: 'Yes | No',
    value: CHECKLIST_TYPES.yesNo,
  },
  {
    label: 'Text',
    value: CHECKLIST_TYPES.text,
  },
];
