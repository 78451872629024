import styled from 'styled-components/macro';
import Button from '../Button';

export const ButtonSecondaryStyled = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.persianGreen};

  :active,
  :hover {
    ${(props) => props.theme.shadows.button}
  }
  :active {
    background-color: ${(props) => props.theme.colors.persianGreenMed};
  }
`;
