import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  // use when more than 3 columns in the Head
  alignWithTableColumns: () => `
    padding: 0 11rem 0 2.5rem; 
  `,
  smallAlignWithTableColumns: () => `
    padding: 0 8rem 0 2.5rem; 
  `,
  smallestAlignWithTableColumns: () => `
    padding: 0 8rem 0 2.5rem; 
  `,
  hideThreeAndFour: ({ theme }: { theme: any }) => `
  @media (max-width: ${theme.breakpoints.sm}) {
    & > div:nth-child(3), & > div:nth-child(4) {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    & > div:nth-child(2) {
      min-width: 40%;
    }

    & > div:nth-child(3), & > div:nth-child(4) {
      display: none;
    }
  }
  `,
  hideThree: ({ theme }: { theme: any }) => `
  @media (max-width: ${theme.breakpoints.sm}) {
    & > div:nth-child(3) {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    & > div:nth-child(3) {
      display: none;
    }
  }
  `,
};

const Head = styled.div<{ modifiers?: string[] }>`
  display: flex;
  padding: 0 2.5rem;
  margin-bottom: 1.5rem;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Head;
