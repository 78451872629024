import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import elements, {
  ActionsContainer,
  FlexContainer,
  IconDelete,
  IconDrag,
  ItemContainer,
  TaskContent,
  TaskTypeWrapper,
  TasksCompletionErrorMessage,
} from 'checklists';
import { ReactComponent as AddNoteIcon } from 'images/svg/icons_add_note.svg';
import { ReactComponent as AddPhotoIcon } from 'images/svg/icons_add_photo.svg';
import { ReactComponent as ConvertTaskIcon } from 'images/svg/convert-task.svg';
import { ReactComponent as NotificationIcon } from 'images/svg/notification-normal.svg';
import { ReactComponent as NotificationFocusedIcon } from 'images/svg/notification-focused.svg';
import Tooltip from 'components/shared/Tooltip';
import NotificationForm from '@optii/escalations/notifications/form/NotificationForm';
import { EventContext } from '@optii/escalations/types/notifications';
import { NotificationContextProvider } from '@optii/escalations/notifications/context';
import { useNotificationGroupsQuery } from '@optii/escalations/api/notifications';
import styled from 'styled-components';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { InputNumber, useForm } from '@optii-solutions/ui-library';
import {
  CHECKLIST_ACTION_STATES,
  CHECKLIST_TYPES,
  CHECKLIST_TASK_TYPE_CONFIG,
  CHECKLIST_CONVERSIONS,
} from '../constants';
import FileUpload from '../../components/shared/Attachments/FileUpload';
import { ChecklistItemContext } from '../contexts';
import ChildJobs from './ChildJobs';
import Note from './Note';
import Attachments from './Attachments';
import { Score } from './TaskScore.tsx';

const ActionButton = styled.button`
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')} !important;
  ${({ isDisabled }) =>
    isDisabled &&
    `
  svg, img {
    filter: grayscale(100%);
    opacity: .8
  }
`};
`;

function NotificationButton({ taskType, taskId, formValues }) {
  const [metadata, setMetadata] = useState({});
  const [addNotification, setAddNotification] = useState(false);

  const notificationContext = {
    [CHECKLIST_TYPES.temperature]: EventContext.TemperatureTask,
    [CHECKLIST_TYPES.numerical]: EventContext.NumericalTask,
  };

  const { data } = useNotificationGroupsQuery({
    variables: {
      filters: `EventGroupFiltersValues@=${taskId},EventGroupContext@=ChecklistTask`,
    },
    skip: !taskId,
    context: {
      _instance: 'node',
    },
  });

  useEffect(() => {
    if (!addNotification) {
      setMetadata({});
    }
  }, [addNotification]);

  const notificationId = data?.page?.edges[0]?.node?.id;

  return (
    <>
      <ActionButton
        type="button"
        onClick={() => {
          setAddNotification(() => true);
          setMetadata({
            context: notificationContext[taskType],
            taskId,
            formValues,
          });
        }}
      >
        {addNotification || notificationId ? (
          <NotificationFocusedIcon />
        ) : (
          <NotificationIcon />
        )}
      </ActionButton>

      <NotificationContextProvider>
        {addNotification && (
          <NotificationForm
            open={addNotification}
            setOpen={setAddNotification}
            initialContext={metadata?.context ?? 'ChecklistTask'}
            metadata={metadata}
            notificationId={notificationId}
          />
        )}
      </NotificationContextProvider>
    </>
  );
}

function Task(props) {
  const {
    taskType,
    label = '',
    value = '',
    note = '',
    pointsComplete = 1,
    pointsIncomplete = 0,
    weight = undefined,
    defaultValue = {
      scale: '',
      value: undefined,
    },
    attachment = [],
    groupId,
    id,
    onChange = () => {
      // onChange function
    },
    onFulfillmentChange = () => {
      // onFulfillment function
    },
    onConvertTask,
    onDelete = () => {
      // onDelete function
    },
    onToggleOpen = () => {
      // onToggleOpen function
    },
    deletable = true,
    isReadOnly = false,
    isEdit,
    isAdd,
    isFulfillment,
    isOpen,
    required,
    childJobsId,
    createdAt,
    subTask,
    isDragging,
    setCanDrag,
    canDrag,
    last,
    submitted,
    fulfillmentAttachment,
    fulfillmentNote,
    isScoringEnabled,
  } = props;

  const { t } = useTranslation(['common', 'checklist']);
  const [touched, setTouched] = useState({});
  const { Switcher } = elements;
  // const [metadata, setMetadata] = useState({});
  // const [addNotification, setAddNotification] = useState(false);
  const [form] = useForm();
  const fileUploadRef = useRef(null);

  const fulfillmentValues = useMemo(
    () => CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment?.getValue(props),
    [props],
  );

  const [formValues, setFormValues] = useState({
    label,
    value,
    taskType,
    note,
    attachment,
    required,
    weight,
    defaultValue,
    ...fulfillmentValues,
    fulfillmentAttachment,
    fulfillmentNote,
    pointsComplete,
    pointsIncomplete,
  });

  const [wasDragging, updateWasDragging] = useState(false);
  const [actionState, setActionState] = useState(
    CHECKLIST_ACTION_STATES.viewingItem,
  );

  const ALLOW_SCORING =
    taskType !== CHECKLIST_TYPES.groupHeader &&
    taskType !== CHECKLIST_TYPES.readOnly;

  useEffect(() => {
    if (isFulfillment) {
      setFormValues((values) => ({
        ...values,
        ...fulfillmentValues,
      }));
    }
  }, [isFulfillment, fulfillmentValues]);

  function defaultState(propState) {
    if (propState?.isReadOnly === true)
      return CHECKLIST_ACTION_STATES.viewingItem;

    if (propState?.isAdd === true && !formValues?.label)
      return CHECKLIST_ACTION_STATES.addingItem;

    if (propState?.isFulfillment === true)
      return CHECKLIST_ACTION_STATES.fulfillment;

    if (propState?.isEdit === true && !formValues?.label)
      return CHECKLIST_ACTION_STATES.addingItem;

    return CHECKLIST_ACTION_STATES.viewingItem;
  }

  useEffect(() => {
    if (canDrag && !wasDragging && formValues && formValues.label.length > 0) {
      updateWasDragging(true);
    } else if (!canDrag && wasDragging) {
      updateWasDragging(false);
      setActionState(CHECKLIST_ACTION_STATES.viewingItem);
    }
  }, [canDrag, wasDragging]);

  useEffect(() => {
    setActionState(defaultState({ isAdd, isEdit, isFulfillment, isReadOnly }));
  }, []);

  const dragEnabled =
    [
      CHECKLIST_ACTION_STATES.viewingItem,
      CHECKLIST_ACTION_STATES.addingNote,
      CHECKLIST_ACTION_STATES.addingItem,
    ].includes(actionState) && !isFulfillment;
  const canDelete =
    [
      CHECKLIST_ACTION_STATES.viewingItem,
      CHECKLIST_ACTION_STATES.addingNote,
      CHECKLIST_ACTION_STATES.addingItem,
    ].includes(actionState) &&
    !isFulfillment &&
    deletable;

  const showRequiredSwitcher =
    !isFulfillment &&
    CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment?.fulfillable;

  const { hasError = false, errors = {} } = useMemo(
    () =>
      CHECKLIST_TASK_TYPE_CONFIG[taskType]?.validation({
        touched,
        formValues,
      }) ?? {},
    [taskType, touched, formValues],
  );
  const isValid = !hasError;

  useEffect(() => {
    let fulfillmentDefaultValues = {};
    if (
      typeof CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment
        ?.getDefaultValue === 'function'
    ) {
      fulfillmentDefaultValues =
        CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment?.getDefaultValue(
          formValues,
        );
    }

    onChange({
      ...formValues,
      required: (formValues?.required && showRequiredSwitcher) ?? false,
      isValid,
      errors,
      groupId,
      id,
      touched,
      createdAt,

      ...fulfillmentDefaultValues,
    });
  }, [formValues, showRequiredSwitcher, isValid]);

  const onFormChange = useCallback(
    ({ name, value: formValue }) => {
      setFormValues({
        ...formValues,
        [name]: formValue,
      });
      setTouched({ [name]: true });
    },
    [formValues],
  );

  const handleAttachmentsUpload = (fileId) => {
    const fieldName = isFulfillment ? 'fulfillmentAttachment' : 'attachment';
    const currentAttachments = formValues[fieldName] || [];
    const updatedAttachments = [fileId, ...currentAttachments];
    onFormChange({
      name: fieldName,
      value: updatedAttachments,
    });

    if (isFulfillment) {
      onFulfillmentChange({
        ...formValues,
        fulfillmentAttachment: updatedAttachments,
      });
    }
  };

  const cantAddNote = !isReadOnly && label !== '';
  const cantAddAttachment =
    fileUploadRef.current && !isReadOnly && label !== '';

  const isInvalid = useMemo(
    () =>
      submitted &&
      required &&
      CHECKLIST_TASK_TYPE_CONFIG[props.taskType]?.fulfillment?.fulfillable &&
      !isReadOnly &&
      !CHECKLIST_TASK_TYPE_CONFIG[props.taskType]?.fulfillment?.isFulfilled(
        formValues,
      ),
    [submitted, required, props.taskType, isReadOnly, formValues],
  );

  useEffect(() => {
    setFormValues((values) => ({
      ...values,
      required,
    }));
  }, [required]);

  const TaskType = CHECKLIST_TASK_TYPE_CONFIG[formValues.taskType]?.component;

  const checklistItemContextValue = useMemo(
    () => ({
      formValues,
      fulfillmentValues,
      touched,
      onFormChange,
      onFulfillmentChange,
      isValid,
      errors,
      isReadOnly,
      isEdit,
      isAdd,
      isFulfillment,
      actionState,
      setActionState,
      onToggleOpen,
      isOpen,
      groupId,
    }),
    [
      formValues,
      fulfillmentValues,
      touched,
      onFormChange,
      onFulfillmentChange,
      isValid,
      errors,
      isReadOnly,
      isEdit,
      isAdd,
      isFulfillment,
      actionState,
      setActionState,
      onToggleOpen,
      isOpen,
      groupId,
    ],
  );

  return (
    <ChecklistItemContext.Provider value={checklistItemContextValue}>
      <ItemContainer
        dashed
        id={`item-${id}`}
        data-testid="checklist-item"
        isGroupHeader={taskType === CHECKLIST_TYPES.groupHeader}
        subTask={subTask}
        last={last}
      >
        <FlexContainer colGap="0.8rem">
          {dragEnabled && (
            <IconDrag
              onMouseOver={() => setCanDrag(true)}
              isDragging={isDragging}
            />
          )}

          <TaskContent isDragging={isDragging} canDelete={canDelete}>
            <TaskTypeWrapper
              canDelete={canDelete}
              isLast={last}
              onClick={() => {
                if (!isFulfillment) {
                  setCanDrag(false);
                }
              }}
            >
              {TaskType && <TaskType />}
            </TaskTypeWrapper>
            {isInvalid && (
              <TasksCompletionErrorMessage>
                {t(
                  'checklist:Task must be completed in order to complete the job',
                )}
              </TasksCompletionErrorMessage>
            )}
            {childJobsId?.length > 0 && <ChildJobs jobsId={childJobsId} />}
            <Note />
            <Attachments />

            <ActionsContainer data-testid="checklist-actions">
              <div style={{ display: 'flex' }}>
                <Tooltip
                  trigger={
                    <ActionButton
                      style={{ cursor: 'pointer' }}
                      type="button"
                      tabIndex={-1}
                      isDisabled={!cantAddNote}
                      onClick={() => {
                        GoogleAnalyticsClient.event(GA_EVENTS.checklistAddNote);
                        cantAddNote &&
                          setActionState(CHECKLIST_ACTION_STATES.addingNote);
                      }}
                    >
                      <AddNoteIcon />
                    </ActionButton>
                  }
                  content={<span>{t('checklist: Add Note')}</span>}
                  position="top"
                  arrow={false}
                />
                <FileUpload
                  isAttached
                  handleUpload={handleAttachmentsUpload}
                  fileUploadRef={fileUploadRef}
                  isDisabled={!cantAddAttachment}
                >
                  <Tooltip
                    closeOnDocumentClick
                    trigger={
                      <ActionButton
                        type="button"
                        tabIndex={-1}
                        isDisabled={!cantAddAttachment}
                        onClick={() => {
                          GoogleAnalyticsClient.event(
                            GA_EVENTS.checklistClickAddAttachment,
                          );
                          cantAddAttachment && fileUploadRef.current.click();
                        }}
                      >
                        <AddPhotoIcon />
                      </ActionButton>
                    }
                    content={<span>{t('checklist: Add Attachment')}</span>}
                    position="top"
                    arrow={false}
                  />
                </FileUpload>
                {onConvertTask &&
                  CHECKLIST_TASK_TYPE_CONFIG[
                    taskType
                  ].canBeConvertedInto?.includes(CHECKLIST_CONVERSIONS.job) && (
                    <Tooltip
                      trigger={
                        <ActionButton
                          type="button"
                          tabIndex={-1}
                          isDisabled={false}
                          onClick={() => onConvertTask({ label, id })}
                        >
                          <ConvertTaskIcon />
                        </ActionButton>
                      }
                      content={
                        <span>{t('checklist: Convert task to job')}</span>
                      }
                      position="top"
                      arrow={false}
                    />
                  )}
                {CHECKLIST_TASK_TYPE_CONFIG[taskType].canHaveNotifications &&
                  ['addingItem', 'viewingItem'].includes(actionState) &&
                  !isFulfillment && (
                    <Tooltip
                      trigger={
                        <NotificationButton
                          taskType={taskType}
                          taskId={id}
                          formValues={formValues}
                        />
                      }
                      content={<span>{t('checklist: Add Notification')}</span>}
                      position="top"
                    />
                  )}
              </div>

              {showRequiredSwitcher && (
                <Switcher
                  checked={
                    formValues.required === '' ? false : formValues.required
                  }
                  value={formValues.required}
                  onChange={(e) => {
                    const { checked } = e.target;

                    onFormChange({ name: 'required', value: checked });
                    form.setFieldValue('pointsIncomplete', 0);

                    GoogleAnalyticsClient.event(GA_EVENTS.checklistRequired);
                  }}
                  readOnly={isReadOnly}
                  data-testid="required"
                  label={t('checklist:Required')}
                />
              )}
            </ActionsContainer>
            {/* {isScoringEnabled && ALLOW_SCORING ? (
              <Score
                taskType={taskType}
                setFormValues={setFormValues}
                pointsComplete={pointsComplete}
                pointsIncomplete={pointsIncomplete}
                isRequired={required}
                form={form}
                disabled={!formValues.label}
              />
            ) : null} */}
          </TaskContent>
          {canDelete && !isReadOnly && !last && (
            <IconDelete
              onClick={() => onDelete(props)}
              data-testid="checklist-item-delete"
              isDragging={isDragging}
            />
          )}
        </FlexContainer>
      </ItemContainer>
    </ChecklistItemContext.Provider>
  );
}

export default Task;
