import { Space, Icon, Flex, SPACING } from '@optii/ui-library';
import {
  PRIORITY_ICONS,
  Priorities,
  ServiceActions,
  SelectOption,
  Statuses,
  HousekeepingActions,
  i18n,
} from '@optii/global';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

export const HOUSEKEEPING_ACTION_OPTIONS: SelectOption[] = Object.keys(
  HousekeepingActions,
).map((housekeepingAction) => ({
  label: i18n.t(
    `jobs:${
      housekeepingAction.charAt(0).toUpperCase() +
      housekeepingAction
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    }`,
  ),
  value: housekeepingAction,
  style: {
    borderInlineStartWidth: '1px',
    borderInlineEndWidth: '1px',
  },
}));

export const ACTION_OPTIONS = (
  t: TFunction<['jobs'], undefined>,
): SelectOption[] =>
  Object.keys(ServiceActions).map((serviceAction) => ({
    label: t(
      `jobs:${
        serviceAction.charAt(0).toUpperCase() +
        serviceAction
          .slice(1)
          .replace(/([A-Z])/g, ' $1')
          .trim()
      }`,
    ),
    value: serviceAction,
    style: {
      borderInlineStartWidth: '1px',
      borderInlineEndWidth: '1px',
    },
  }));

export const PRIORITY_OPTIONS = (
  t: TFunction<['jobs'], undefined>,
): SelectOption[] =>
  Object.keys(Priorities).map((priority) => ({
    label: t(`jobs:${priority.charAt(0).toUpperCase() + priority.slice(1)}`),
    value: priority,
  }));

export const STATUS_OPTIONS = (
  t: TFunction<['common'], undefined>,
): SelectOption[] =>
  Object.keys(Statuses).map((status) => ({
    label:
      status === Statuses.completed
        ? t('jobs:Done')
        : t(
            `jobs:${status
              .split('_')
              .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
              .join(' ')}`,
          ),
    value: status,
  }));

export const PrioritySelectOptionRender = ({
  value,
  label,
}: {
  value?: string | number;
  label?: ReactNode;
}) => {
  const ICON_SVG = PRIORITY_ICONS[value as keyof typeof PRIORITY_ICONS];

  return (
    <Space>
      <Icon component={ICON_SVG} />
      {label}
    </Space>
  );
};

export const PrioritySelectLabelRender = ({
  value,
  label,
}: {
  label?: ReactNode;
  value?: string | number;
}) => {
  const ICON_SVG = PRIORITY_ICONS[value as keyof typeof PRIORITY_ICONS];

  return (
    <Flex align="center" gap={SPACING.SIZE_SM}>
      <Icon component={ICON_SVG} />
      <span>{label}</span>
    </Flex>
  );
};
