import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Loading, TopModalContent } from '@optii/shared';
import { context, useChecklistTemplateQuery } from '@optii/global';

import {
  COLORS,
  Flex,
  FONTS,
  SPACING,
  Tag,
  Typography,
} from '@optii/ui-library';
import { ChecklistModalContext } from '../contexts';
import {
  Container,
  Description,
  InnerTitle,
  SubTitle,
  TemplateMessage,
} from '..';
import CheckListTemplate from './template/CheckListTemplate';
import { ChecklistScore } from './template/ChecklistScore';

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};

const dateFormat = new Intl.DateTimeFormat('default', options as any);

export default function ReadOnlyChecklistTemplate() {
  const { t } = useTranslation(['common', 'checklists']);
  const {
    data: contextData,
    setData,
    selectedProperties,
    associatedProperties,
  } = useContext(ChecklistModalContext);

  const [query] = useQueryParams({
    openChecklistTemplate: StringParam,
  });

  const { data: { checklistTemplate } = {}, loading } =
    useChecklistTemplateQuery({
      variables: {
        id: query.openChecklistTemplate || '',
      },
      context: { _instance: 'node', _shard: selectedProperties?.[0] },
      skip: !!contextData,
    });

  if (!contextData && checklistTemplate) setData(checklistTemplate);
  if (loading) {
    return <Loading loading modifiers={['relative']} />;
  }

  return (
    <>
      <TopModalContent>
        <Description>{contextData?.description}</Description>
        {associatedProperties.length ? (
          <Flex
            vertical
            gap={SPACING.SIZE_SM}
            style={{
              marginBlock: SPACING.SIZE_MD,
            }}
          >
            <Typography.Text
              style={{
                fontSize: FONTS.xSmall.size,
                letterSpacing: FONTS.xSmall.letterSpacing,
                fontWeight: 500,
              }}
            >
              {t('common:Associated Properties')}
            </Typography.Text>
            <Flex gap={SPACING.SIZE_XS} wrap>
              {associatedProperties.map((associatedProperty) => (
                <Tag>{associatedProperty.title}</Tag>
              ))}
            </Flex>

            {associatedProperties.length !== selectedProperties.length ? (
              <Typography.Text
                color="red"
                style={{
                  fontSize: FONTS.xSmall.size,
                  fontWeight: 500,
                  color: COLORS.red[5],
                }}
              >
                {t(
                  'checklists:You need access to all properties in this checklist to edit or delete.',
                )}
              </Typography.Text>
            ) : null}
          </Flex>
        ) : null}

        {contextData && (
          <SubTitle>
            {t(
              'checklist: Added by {{firstName}} {{lastName}} on {{addedDate}}',
              {
                firstName: contextData.creator.firstName,
                lastName: contextData.creator.lastName,
                addedDate: dateFormat.format(new Date(contextData.createdAt)),
              },
            )}
          </SubTitle>
        )}
        <TemplateMessage data-testid="checklist-template-edit-message">
          {t(
            'checklist:This is a template. To use this checklist, attach it to a job.',
          )}
        </TemplateMessage>

        {contextData?.enableScoring ? (
          <ChecklistScore
            setFieldValue={() => Promise.resolve()}
            values={{
              displayName: contextData.name,
              description: contextData.description,
              checkList: contextData.tasks,
              enableScoring: contextData.enableScoring,
            }}
          />
        ) : null}
        <InnerTitle>{t('checklist: Tasks')}</InnerTitle>
      </TopModalContent>
      {!contextData?.tasks && (
        <p>{t('checklist: No tasks on this checklist')}</p>
      )}

      <Container data-testid="checklist-template">
        <CheckListTemplate
          isScoringEnabled={contextData?.enableScoring}
          isReadOnly
          data={contextData?.tasks}
          draggable={false}
          footer={undefined}
        />
      </Container>
    </>
  );
}
