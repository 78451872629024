import styled from 'styled-components';
// table elements
import Head from './Head';
import Title from './Title';
import Col from './Col';
import ColWrap from './ColWrap';
import ColData from './ColData';
import Arrow from './Arrow';
import RowWrap from './RowWrap';
import Header from './Header';
import Footer from './Footer';
import Subtitle from './Subtitle';
import Section from './Section';
import FieldWrap from './FieldWrap';
import { FC, HTMLAttributes } from 'react';

const TableContent = styled.div`
  margin-top: 2.7rem;
`;

const Table: FC<HTMLAttributes<HTMLDivElement>> & {
  Head: typeof Head;
  Title: typeof Title;
  RowWrap: typeof RowWrap;
  ColWrap: typeof ColWrap;
  Col: typeof Col;
  ColData: typeof ColData;
  Arrow: typeof Arrow;
  Header: typeof Header;
  Footer: typeof Footer;
  Subtitle: typeof Subtitle;
  Section: typeof Section;
  FieldWrap: typeof FieldWrap;
} = (props) => <TableContent {...props} />;

Table.Title = Title;
Table.FieldWrap = FieldWrap;
Table.Head = Head;
Table.RowWrap = RowWrap;
Table.ColWrap = ColWrap;
Table.Col = Col;
Table.ColData = ColData;
Table.ColWrap = ColWrap;
Table.Arrow = Arrow;
Table.Header = Header;
Table.Footer = Footer;
Table.Subtitle = Subtitle;
Table.Section = Section;

export default Table;
