import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Plus from './Plus';
import TrashCan from './TrashCan';

const MODIFIER_CONFIG = {
  right: () => `
    & :last-child {
      margin-right: 0;
    }
  `,
  alignCenter: () => `
    align-items: center;
  `,
};

const IconContainer = styled.div`
  display: flex;
  margin-left: auto;
  & > * {
    margin: 0 0.7rem;
    cursor: pointer;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

const Icons: FC<HTMLAttributes<HTMLDivElement>> & {
  Plus: typeof Plus;
  TrashCan: typeof TrashCan;
} = (props) => <IconContainer {...props} />;

Icons.Plus = Plus;
Icons.TrashCan = TrashCan;

export default Icons;
