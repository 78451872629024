import React from 'react';
import styled from 'styled-components/macro';
import Popup from 'reactjs-popup';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  'right top': (props: any) => `
    border-width: 5px 0 5px 6px;
    border-color: transparent transparent transparent ${props.theme.colors.charcoal05};
    position: absolute;
    top: 12px;
    right: -1px;
  `,
  'right center': (props: any) => `
    border-width: 5px 0 5px 6px;
    border-color: transparent transparent transparent ${props.theme.colors.charcoal05};
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: -1px;
  `,
  'right bottom': (props: any) => `
    border-width: 5px 0 5px 6px;
    border-color: transparent transparent transparent ${props.theme.colors.charcoal05};
    position: absolute;
    bottom: 12px;
    right: -1px;
  `,
  // TODO: 'top left', 'top center', 'top right', 'bottom left', 'bottom center', 'bottom right',  'left top', 'left center', 'left bottom'
};

const StyledPopup = styled(Popup)`
  &-content {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    width: auto !important;
    opacity: 1 !important;
    z-index: 9999 !important;
  }
`;

const Content = styled.div<{ hasArrow: boolean; modifiers: string[] }>`
  padding: 0.7rem 1.2rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.sizes.small};
  ${(props) => props.theme.fonts.weights.medium};
  border-radius: 0.3rem;
  width: max-content;
  max-width: 31rem;
  opacity: 1 !important;
  div {
    opacity: 1 !important;
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    ${applyStyleModifiers(CONFIG)};
    ${({ hasArrow }) => `
      display: ${hasArrow ? 'block' : 'none'};
    `}
  }
`;

function Tooltip(props: any) {
  const {
    trigger,
    position,
    content,
    on = ['hover', 'focus'],
    arrowPosition,
    closeOnDocumentClick = false,
  } = props;
  return (
    <StyledPopup
      {...props}
      trigger={trigger}
      position={position}
      on={on}
      arrow={false}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <Content hasArrow={!!arrowPosition} modifiers={arrowPosition}>
        {content}
      </Content>
    </StyledPopup>
  );
}

export default Tooltip;
