import {
  COLORS,
  FONTS,
  Row,
  SPACING,
  ThemeConfig,
  Col,
  Typography,
  CollapseProps,
  ConfigProvider,
  Flex,
  Switcher,
  Card,
  Collapse,
  Divider,
} from '@optii/ui-library';
import { FormikErrors } from 'formik';
import { CSSProperties, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { CHECKLIST_TYPES } from '../../taskConstants';
import './styles.css';
import { ChecklistModalContext } from '../../contexts';

type ChecklistTask = {
  id: string;
  label?: string;
  groupId?: string;
  taskType: string;
  pointsComplete: number;
  pointsIncomplete?: number;
};

const TYPOGRAPHY_STYLE: CSSProperties = {
  fontWeight: 500,
  letterSpacing: FONTS.small.letterSpacing,
};

const SWITCHER_CONTAINER_STYLE: CSSProperties = {
  marginTop: SPACING.SIZE_XXL,
  marginBottom: SPACING.SIZE_MS,
};

const SWITCHER_THEME: ThemeConfig = {
  components: {
    Typography: {
      fontSize: FONTS.xSmall.size,
      colorText: COLORS.neutral[8],
    },
  },
};

const THEME: ThemeConfig = {
  components: {
    Card: {
      colorBgContainer: COLORS.primary[1],
    },
    Collapse: {
      headerPadding: SPACING.NONE,
      contentPadding: SPACING.NONE,
      paddingSM: SPACING.NONE,
    },
    Typography: {
      fontSize: FONTS.small.size,
    },
    Divider: {
      marginLG: SPACING.SIZE_XS,
      lineWidth: 1,
      colorSplit: COLORS.primary[8],
    },
  },
  token: {
    colorText: COLORS.primary[8],
  },
};

type ChecklistScoreProps = {
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<
          | {
              displayName: string;
              description: string;
              checkList: any;
              enableScoring?: undefined;
            }
          | {
              displayName: null;
              description?: string;
              enableScoring: boolean;
              checkList?: any;
            }
        >
      >;
  values:
    | {
        displayName: string;
        description?: string;
        checkList?: ChecklistTask[];
        enableScoring?: boolean;
      }
    | {
        displayName: null;
        description?: string;
        enableScoring: boolean;
        checkList?: ChecklistTask[];
      };
};

function expandIcon({ isActive }: { isActive?: boolean }) {
  return <DownOutlined rotate={isActive ? 180 : 0} />;
}

function collapseExtraComponent(tasks?: ChecklistTask[]) {
  const taskCount = tasks?.length;
  const totalCompletePoints = tasks?.reduce(
    (prev, { pointsComplete = 0 }) => prev + pointsComplete,
    0,
  );

  return (
    <Row align="middle" wrap={false} justify="space-between">
      <Col span={12} />
      <Col
        span={6}
        style={{
          textAlign: 'end',
          paddingRight: 33,
        }}
      >
        <Typography.Text>{taskCount}</Typography.Text>
      </Col>
      <Col
        span={6}
        style={{
          textAlign: 'end',
        }}
      >
        <Typography.Text>{totalCompletePoints ?? '-'}</Typography.Text>
      </Col>
    </Row>
  );
}

// TODO: percentage calculation for individual tasks: `${(pointsComplete / totalCompletedPoints) * 100}%`;

export function ChecklistScore({ setFieldValue, values }: ChecklistScoreProps) {
  const { t } = useTranslation(['common', 'checklist']);
  const { readOnly } = useContext(ChecklistModalContext);

  const { enableScoring, checkList } = values;

  const allTasks = checkList
    ?.filter(
      (task) =>
        !task.groupId &&
        task.taskType !== CHECKLIST_TYPES.groupHeader &&
        task.taskType !== CHECKLIST_TYPES.readOnly,
    )
    .filter((task) => task.label);

  const groupedTasks: { [key: string]: ChecklistTask[] } = useMemo(() => {
    if (!checkList || checkList?.length === 0) return {};

    const groups: { [key: string]: ChecklistTask[] } = {};

    let groupId: string;

    checkList.forEach((task) => {
      if (task.taskType === CHECKLIST_TYPES.groupHeader) {
        groupId = task.id;
        groups[groupId] = [];
      }

      if (task.taskType !== CHECKLIST_TYPES.groupHeader && groupId) {
        groups[groupId].push(task);
      }
    });
    return groups;
  }, [checkList]);

  const ungroupedTasks = useMemo(
    () =>
      allTasks
        ?.filter((task) => !Object.values(groupedTasks).flat().includes(task))
        .filter((task) => task.label),
    [allTasks, groupedTasks],
  );

  const totalTaskCount = useMemo(
    () =>
      checkList
        ?.filter(
          (task) =>
            !task.groupId &&
            task.taskType !== CHECKLIST_TYPES.groupHeader &&
            task.taskType !== CHECKLIST_TYPES.readOnly,
        )
        .filter((task) => task.label).length,
    [checkList],
  );

  const totalCompletedPoints = useMemo(
    () =>
      allTasks?.reduce((acc, { pointsComplete }) => acc + pointsComplete, 0),
    [allTasks],
  );

  const items: CollapseProps['items'] = useMemo(
    () =>
      Object.keys(groupedTasks)
        .map((parentId) => {
          const groupName = checkList?.find(
            (item) => item.id === parentId,
          )?.label;

          const childTasksArray = groupedTasks[parentId].filter(
            (task) => task.label,
          );

          return {
            key: parentId,
            style: {
              padding: SPACING.NONE,
            },
            label: (
              <Typography.Paragraph
                style={{
                  fontWeight: 500,
                  width: 108.35,
                  margin: SPACING.NONE,
                }}
                ellipsis={{
                  rows: 1,
                  tooltip: (
                    <Typography.Text
                      style={{
                        color: COLORS.neutral[1],
                      }}
                    >
                      {groupName}
                    </Typography.Text>
                  ),
                }}
              >
                {groupName}
              </Typography.Paragraph>
            ),
            children: childTasksArray?.map(({ id, label, pointsComplete }) => (
              <Row align="middle" key={id}>
                <Col span={12}>
                  <Typography.Paragraph
                    style={{
                      margin: SPACING.NONE,
                      marginLeft: SPACING.SIZE_XXL,
                    }}
                    ellipsis={{
                      rows: 1,
                      tooltip: (
                        <Typography.Text
                          style={{
                            color: COLORS.neutral[1],
                          }}
                        >
                          {label}
                        </Typography.Text>
                      ),
                    }}
                  >
                    {label}
                  </Typography.Paragraph>
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: 'end',
                  }}
                >
                  <Typography.Text>{pointsComplete ?? '-'}</Typography.Text>
                </Col>
              </Row>
            )),
            extra: collapseExtraComponent(childTasksArray),
          };
        })
        .concat(
          ungroupedTasks && ungroupedTasks.length > 0
            ? [
                {
                  key: '1',
                  label: (
                    <Typography.Text
                      style={{
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        width: 'auto',
                      }}
                    >
                      {t('checklist:Ungrouped Tasks')}
                    </Typography.Text>
                  ),

                  children:
                    ungroupedTasks?.map(({ label, id, pointsComplete }) => (
                      <Row align="middle" key={id}>
                        <Col md={12}>
                          <Typography.Text
                            style={{
                              marginLeft: SPACING.SIZE_XXL,
                            }}
                          >
                            {label}
                          </Typography.Text>
                        </Col>
                        <Col
                          md={12}
                          style={{
                            textAlign: 'end',
                          }}
                        >
                          <Typography.Text>
                            {pointsComplete ?? '-'}
                          </Typography.Text>
                        </Col>
                      </Row>
                    )) || [],
                  style: {
                    padding: SPACING.NONE,
                  },
                  extra: collapseExtraComponent(ungroupedTasks),
                },
              ]
            : [],
        ),
    [ungroupedTasks, groupedTasks, checkList, t],
  );

  return (
    <ConfigProvider theme={THEME}>
      <ConfigProvider theme={SWITCHER_THEME}>
        <Flex
          gap={SPACING.SIZE_DEFAULT}
          style={SWITCHER_CONTAINER_STYLE}
          align="center"
        >
          <Typography.Text
            style={{
              fontWeight: 500,
              letterSpacing: FONTS.xSmall.letterSpacing,
              lineHeight: FONTS.xSmall.lineHeight,
            }}
          >
            {t('checklist:Score Checklist')}
          </Typography.Text>
          <Switcher
            title="Score Checklist"
            value={enableScoring}
            disabled={!!readOnly}
            onChange={(value) => {
              setFieldValue('enableScoring', value);
            }}
          />
        </Flex>
      </ConfigProvider>
      {enableScoring ? (
        <Card
          styles={{
            body: {
              paddingInline: SPACING.SIZE_MD,
              paddingBlock: SPACING.SIZE_MS,
            },
          }}
        >
          <Row justify="space-between">
            <Col span={12}>
              <Typography.Text style={TYPOGRAPHY_STYLE}>
                {t('checklist:Scoring Summary')}
              </Typography.Text>
            </Col>
            <Col
              span={6}
              style={{
                textAlign: 'end',
              }}
            >
              <Typography.Text style={TYPOGRAPHY_STYLE}>
                {t('checklist:Tasks')}
              </Typography.Text>
            </Col>
            <Col
              span={6}
              style={{
                textAlign: 'end',
              }}
            >
              <Typography.Text style={TYPOGRAPHY_STYLE}>
                {t('checklist:Completed Points')}
              </Typography.Text>
            </Col>
          </Row>

          <Collapse
            bordered={false}
            expandIconPosition="start"
            expandIcon={expandIcon}
            items={items}
          />

          <Divider />
          <Row align="middle" justify="space-between">
            <Col span={12}>
              <Typography.Text strong>{t('common:Totals')}</Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: 'end' }}>
              <Typography.Text
                style={{
                  letterSpacing: FONTS.medium.letterSpacing,
                }}
              >
                {totalTaskCount}
              </Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: 'end' }}>
              <Typography.Text
                style={{
                  letterSpacing: FONTS.medium.letterSpacing,
                }}
              >
                {totalCompletedPoints}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      ) : null}
    </ConfigProvider>
  );
}
