import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, FormInstance, SPACING } from '@optii/ui-library';

type Props = {
  onCancel: () => void;
  setSendLater: Dispatch<SetStateAction<boolean>>;
  form: FormInstance;
  loading: boolean;
};

export function Footer({ setSendLater, onCancel, form, loading }: Props) {
  return (
    <Flex gap={SPACING.SIZE_MD} align="center" justify="flex-end">
      <Button type="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="default"
        htmlType="submit"
        loading={loading}
        disabled={loading}
        onClick={() => {
          setSendLater(true);
          form.submit();
        }}
      >
        Save and send later
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={loading}
        onClick={() => {
          setSendLater(false);
          form.submit();
        }}
      >
        Send invitation
      </Button>
    </Flex>
  );
}
