import { DeleteOutlined } from '@ant-design/icons';
import {
  SelectOption,
  useDepartmentsQuery,
  useListEmploymentTypesQuery,
  useRolesQuery,
} from '@optii/global';
import {
  Button,
  Col,
  COLORS,
  FormInstance,
  FormItem,
  FormItemProps,
  FormList,
  AntForm,
  Row,
  Select,
  SPACING,
} from '@optii/ui-library';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  form: FormInstance;
  propertyPath: number;
};

export function Positions({ propertyPath, form }: Props) {
  const { t } = useTranslation(['common']);

  const property = form.getFieldValue(['properties', propertyPath, 'property']);

  const { data: employmentTypesData, loading: employmentTypesLoading } =
    useListEmploymentTypesQuery({
      variables: {
        orderBy: 'name',
      },
      context: {
        _shard: property?.value,
      },
    });

  const { data: departmentsData, loading: departmentsLoading } =
    useDepartmentsQuery({
      context: {
        _shard: property?.value,
      },
      onError(error) {
        console.error(error);
      },
    });

  const { data: rolesData, loading: rolesLoading } = useRolesQuery({
    context: {
      _shard: property?.value,
    },
    onError(error) {
      console.error(error);
    },
  });

  const employmentTypeOptions: SelectOption[] =
    employmentTypesData?.page.edges?.map(({ node: { id, displayName } }) => ({
      label: displayName,
      value: id,
    })) || [];

  const departmentOptions: SelectOption[] =
    departmentsData?.page.edges?.map(({ node: { label, value } }) => ({
      label,
      value,
    })) || [];

  const roleOptions: SelectOption[] =
    rolesData?.page.edges?.map(({ node: { label, value } }) => ({
      label,
      value,
    })) || [];

  const showRequiredAtLeft = {
    required: true,
    label: true,
    colon: false,
    layout: 'horizontal' as FormItemProps['layout'],
  };

  return (
    <>
      <Row align="top" gutter={SPACING.SIZE_MD}>
        <Col md={24} xs={24} sm={24}>
          <FormItem
            name={[propertyPath, 'employmentType']}
            {...showRequiredAtLeft}
            required
            normalize={(v) => ({
              label: employmentTypeOptions.find((item) => item.value === v)
                ?.label,
              value: v,
            })}
            getValueProps={(valueProps) => ({
              value: valueProps?.value,
            })}
            rules={[
              {
                required: true,
                message: t('common:Employment Type is a required field'),
              },
            ]}
          >
            <Select
              options={employmentTypeOptions}
              loading={employmentTypesLoading}
              optionFilterProp="label"
              showSearch
              placeholder={t('common:Employment Type')}
            />
          </FormItem>
        </Col>
      </Row>
      <FormList
        name={[propertyPath, 'positions']}
        rules={[
          {
            validator(r, positions: { department: string; role: string }[]) {
              const formattedPositionsForCheck = positions
                .filter((item) => item)
                .map((item) => `${item?.department}-${item?.role}`);

              if (
                formattedPositionsForCheck.some(
                  (item, index) =>
                    formattedPositionsForCheck.indexOf(item) !== index,
                )
              ) {
                return Promise.reject(
                  new Error(
                    'There are duplicated positions, please change one of them.',
                  ),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(positionFields, { add, remove }, { errors }) => (
          <>
            {positionFields.map((positionField) => {
              const itemPath = [positionField.name];

              return (
                <Fragment key={positionField.key}>
                  <Row align="top" gutter={SPACING.SIZE_MD}>
                    <Col md={11} xs={11} sm={11}>
                      <FormItem
                        name={[...itemPath, 'department']}
                        {...showRequiredAtLeft}
                        required
                        normalize={(v) => ({
                          label: departmentOptions.find(
                            (department) => department.value === v,
                          )?.label,
                          value: v,
                        })}
                        getValueProps={(valueProps) => ({
                          value: valueProps?.value,
                        })}
                        rules={[
                          {
                            required: true,
                            message: t('common:Department is a required field'),
                          },
                        ]}
                      >
                        <Select
                          options={departmentOptions}
                          loading={departmentsLoading}
                          optionFilterProp="label"
                          showSearch
                          placeholder={t('common:Department')}
                        />
                      </FormItem>
                    </Col>
                    <Col md={11} xs={11} sm={11}>
                      <FormItem
                        name={[...itemPath, 'role']}
                        {...showRequiredAtLeft}
                        normalize={(v) => ({
                          label: roleOptions.find(
                            (department) => department.value === v,
                          )?.label,
                          value: v,
                        })}
                        getValueProps={(valueProps) => ({
                          value: valueProps?.value,
                        })}
                        rules={[
                          {
                            required: true,
                            message: t('common:Role is a required field'),
                          },
                        ]}
                      >
                        <Select
                          options={roleOptions}
                          loading={rolesLoading}
                          optionFilterProp="label"
                          showSearch
                          placeholder={t('common:Role')}
                        />
                      </FormItem>
                    </Col>

                    <Col md={2} xs={2} sm={2}>
                      <FormItem>
                        <Button
                          type="text"
                          styles={{
                            icon: {
                              color: COLORS.neutral[6],
                            },
                          }}
                          icon={<DeleteOutlined />}
                          disabled={positionFields.length === 1}
                          onClick={() => remove(positionField.name)}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Fragment>
              );
            })}

            <FormItem>
              <Button type="dashed" onClick={() => add()} block>
                {t('common:Add another position for {{propertyName}}', {
                  propertyName: property.label,
                })}
              </Button>
              <AntForm.ErrorList errors={errors} />
            </FormItem>
          </>
        )}
      </FormList>
    </>
  );
}
