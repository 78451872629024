import { useState } from 'react';

export function useColsSorting(
  colsData: any,
  id: string,
  currentColumnToStartSort: any,
) {
  const [cols, setCols] = useState(colsData);
  const [currentCol, setCurrentCol] = useState(
    currentColumnToStartSort || cols[0],
  );

  // Update fields by sort order
  const handleCols = (itemId: string, isSortDesc: boolean) => {
    setCols((curr: any) =>
      curr.map((item: any) => {
        if (item.id === itemId) {
          const current = { ...item, isSortDesc };
          setCurrentCol(current);
          return current;
        }
        return item;
      }),
    );
  };

  return { cols, setCols, currentCol, handleCols };
}
