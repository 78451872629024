import React from 'react';
import { useTranslation } from 'react-i18next';
import OptionType from './OptionType';
import { CHECKLIST_TASK_FIELDS } from '../../taskConstants';

function OnOff(props: any) {
  const { t } = useTranslation(['common', 'checklist']);

  const options = [
    {
      label: t('checklist: Yes'),
      value: 'YES',
    },
    {
      label: t('checklist: No'),
      value: 'NO',
    },
    {
      label: t('checklist: Other'),
      value: 'NOT_APPLICABLE',
    },
  ];

  return (
    <OptionType
      {...props}
      fieldName={CHECKLIST_TASK_FIELDS.dataYesNo}
      options={options}
    />
  );
}

export default OnOff;
