import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlexContainer,
  FlexItem,
  IconArrowDown,
  IconArrowUp,
  Title,
} from '../../elements';
import { ChecklistItemContext } from '../../contexts';
import TaskNameField from '../TaskNameField';
import SelectType from '../SelectType';

function GroupHeaderType() {
  const { formValues, isOpen, onToggleOpen, isFulfillment, groupId } =
    useContext(ChecklistItemContext);
  const { t } = useTranslation(['checklist']);

  return (
    <FlexContainer data-testid="checklist-task-group-header">
      <FlexContainer col>
        <FlexItem>
          <TaskNameField
            placeholder={t('checklist:Add Group Name')}
            renderTitle={({ goToEdit }) => (
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <Title onClick={goToEdit}>{formValues.label}</Title>
                {isOpen ? (
                  <IconArrowUp
                    onClick={() => onToggleOpen(groupId)}
                    data-testid="checklist-task-group-arrow-up"
                    aria-label={t('checklist:Close this group.')}
                  />
                ) : (
                  <IconArrowDown
                    onClick={() => onToggleOpen(groupId)}
                    data-testid="checklist-task-group-arrow-down"
                    aria-label={t('checklist:Open this group.')}
                  />
                )}
              </FlexContainer>
            )}
          />
        </FlexItem>
      </FlexContainer>
      {!isFulfillment && <SelectType />}
    </FlexContainer>
  );
}

export default GroupHeaderType;
