import {
  Card,
  Col,
  FormItem,
  Input,
  Row,
  Select,
  SPACING,
  TextArea,
  Typography,
} from '@optii/ui-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CARD_CONTAINER_STYLE,
  FormProps,
  GLOBAL_CARD_STYLES,
} from './constants';

export function GeneralSettingsForm({ form }: Pick<FormProps, 'form'>) {
  const { t } = useTranslation(['common']);

  return (
    <Card
      title={t('common:General Settings')}
      style={CARD_CONTAINER_STYLE}
      styles={GLOBAL_CARD_STYLES}
    >
      <FormItem
        name={['generalSettings', 'applicationID']}
        label={t('common:Application ID')}
      >
        <Typography.Text>[Application ID]</Typography.Text>
      </FormItem>

      <Row gutter={SPACING.SIZE_MD}>
        <Col md={12} xs={24} sm={24}>
          <FormItem
            label={t('common:Application Type')}
            name={['generalSettings', 'applicationType']}
          >
            <Select placeholder={t('common:Application Type')} />
          </FormItem>
        </Col>
        <Col md={12} xs={24} sm={24}>
          <FormItem
            label={t('common:Display Name')}
            name={['generalSettings', 'displayName']}
          >
            <Input placeholder={t('common:Display Name')} />
          </FormItem>
        </Col>
      </Row>

      <FormItem
        label={t('common:Description')}
        name={['generalSettings', 'description']}
      >
        <TextArea />
      </FormItem>
    </Card>
  );
}
