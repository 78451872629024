import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';

import GoogleAnalyticsClient from '../../utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '../../constants/gaEvents';
import { Session } from '../../contexts/SessionContext';
import { STORE_FILE } from '../../queries/file/storeFile';
import Loading from '../../components/atoms/Loading';
import { useMutation } from '@apollo/client';
import Icons from './Icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

const FileInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;

  /* + label {
    background-color: ${(props) => props.theme.colors.charcoal01};
    height: 12rem;
    width: 12rem;
    max-width: 12rem;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    padding-left: 2rem 4rem;
  }

  :focus + label,
  + label:hover {
  }

  :focus + label {
    outline: 1px dotted #000;
  } */
`;

const UploadFileLabel = styled.label<{ isAttached?: boolean }>`
  background-color: ${(props) =>
    props.isAttached ? '' : props.theme.colors.charcoal01};
  height: ${(props) => (props.isAttached ? '100%' : '12rem')};
  width: ${(props) => (props.isAttached ? '100%' : '12rem')};
  border-radius: ${(props) => (props.isAttached ? 0 : '.4rem')};
  padding: ${(props) => (props.isAttached ? '0' : '0 4rem')};
  max-width: 12rem;
  cursor: pointer;
  display: inline-block;

  button {
    cursor: pointer;
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: ${(props) => (props.isAttached ? 'none' : '1px dotted #000')};
  }
`;

const UploadText = styled.div`
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.weights.medium}
  width: 7rem;
  text-align: center;
`;
const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
`;
const UploadIcon = styled(Icons.Plus as 'div')`
  margin-bottom: 1rem;
`;
const UploadLoading = styled(Loading)`
  margin-bottom: 1rem;
  transform: scale(0.5);
`;
function FileUpload({
  handleUpload,
  analytics,
  isAttached,
  children,
  fileUploadRef,
  isDisabled,
}: any) {
  const [storeFile, { loading }] = useMutation(STORE_FILE);
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['fields', 'jobs']);

  // Used to guarantee uniqueness of the elements so that this will work with multiple uploads mounted at once
  const [uploadId] = useState(`file-upload-${uuidv4()}`);
  async function upload(file: any) {
    if (file.size >= 10000000) {
      globalSnack({
        message: t('jobs:This file is too large, please try another'),
        timeout: 6000,
      });
      return;
    }
    try {
      const response = await storeFile({
        variables: {
          file,
          name: file.name,
        },
      });

      handleUpload(response.data.file.UUID);
      GoogleAnalyticsClient.event(
        analytics || GA_EVENTS.uploadFileUnknownSource,
      );
    } catch (err) {
      console.error('Error uploading attachment:', err);

      globalSnack({
        message: t(`jobs:Could not upload attachment`),
        timeout: 5000,
        error: true,
      });
    }
  }

  return (
    <>
      <FileInput
        name="document"
        type="file"
        id={uploadId}
        title="Upload File or Photo"
        ref={fileUploadRef}
        tabIndex={-1}
        onChange={(e) => {
          e.preventDefault();
          const {
            target: { files },
          } = e;
          const file = (files as any)[0];
          file && upload(file);
          e.target.value = ''; // to have ability to upload same files in a row
        }}
        disabled={isDisabled}
        onClick={(e) => GoogleAnalyticsClient.event(GA_EVENTS.addFile)}
      />
      <UploadFileLabel htmlFor={uploadId} isAttached={isAttached}>
        {isAttached ? (
          children
        ) : (
          <UploadContainer>
            {loading ? (
              <UploadLoading loading modifiers={['relative']} />
            ) : (
              <>
                <UploadIcon />
                <UploadText data-testid="file-upload-text">
                  {t('fields:Upload File')}
                </UploadText>
              </>
            )}
          </UploadContainer>
        )}
      </UploadFileLabel>
    </>
  );
}

export default FileUpload;
