import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  bottom: () => `
    width: 100%;
  `,
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: baseline;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-top: 1.5rem;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Footer;
