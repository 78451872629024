import React, { useContext, useEffect } from 'react';

import { ChecklistModalContext } from '../contexts.ts';
import ReadOnlyChecklistTemplate from './ReadOnlyChecklistTemplate.tsx';
import ChecklistTemplateForm from './template/ChecklistTemplateForm.tsx';
import ChecklistModal from '../modal/index.tsx';

export default function ChecklistDetails() {
  const { isReadOnly, isEdit, isCopy, isAdd, data, close, isOpen, open } =
    useContext(ChecklistModalContext);

  useEffect(() => {
    if ((isReadOnly || isEdit || isCopy || isAdd) && !isOpen) {
      open(true);
    }
  }, [isReadOnly, isEdit, isCopy, isAdd, open, isOpen]);

  return (
    <>
      {isReadOnly && (
        <ChecklistModal
          modalWidth="67.7rem"
          modifiers={['right', 'childrenPadding']}
        >
          <ReadOnlyChecklistTemplate />
        </ChecklistModal>
      )}
      {isAdd && (
        <ChecklistModal
          modalWidth="67.7rem"
          modifiers={['right', 'childrenPadding']}
        >
          <ChecklistTemplateForm isAdd onFinish={close} />
        </ChecklistModal>
      )}
      {isEdit && (
        <ChecklistModal
          modalWidth="67.7rem"
          modifiers={['right', 'childrenPadding']}
        >
          <ChecklistTemplateForm isEdit data={data} onFinish={close} />
        </ChecklistModal>
      )}
      {isCopy && (
        <ChecklistModal
          modalWidth="67.7rem"
          modifiers={['right', 'childrenPadding']}
        >
          <ChecklistTemplateForm isCopy data={data} onFinish={close} />
        </ChecklistModal>
      )}
    </>
  );
}
