import styled from 'styled-components/macro';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: ${(props) => props.theme.colors.charcoal01};
    margin-bottom: 0;
    padding: 1rem;
  }
  h3 {
    margin: 0;
  }
`;

export default Header;
