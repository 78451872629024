import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  ConfigProvider,
  Drawer,
  FormItem,
  Form,
  useForm,
  SPACING,
  Segmented,
  SegmentedProps,
  COLORS,
  FONTS,
} from '@optii/ui-library';

import { useTranslation } from 'react-i18next';
import './styles.scss';

import { JobType } from '@optii/jobs/api/jobs';
import { Title } from './Title';
import { Footer } from './Footer';
import { Assistant } from './Assistant';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export function FormV2({ open, setOpen }: Props) {
  const [form] = useForm();
  const { t } = useTranslation(['jobs', 'common']);
  const [assistantDescription, setAssistantDescription] = useState('');

  const JOB_TYPE_OPTIONS: SegmentedProps['options'] = [
    {
      value: JobType.Guest,
      label: t('jobs:Guest'),
    },
    {
      value: JobType.Internal,
      label: t('jobs:Internal'),
    },
    {
      value: JobType.Housekeeping,
      label: t('jobs:Housekeeping'),
    },
  ];

  const onClose = () => {
    setOpen(false);
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            itemActiveBg: COLORS.primary[1],
            itemSelectedBg: COLORS.primary[1],
            itemSelectedColor: COLORS.primary[5],
            itemColor: COLORS.neutral[8],
            fontSizeLG: FONTS.medium.size,
            borderRadius: SPACING.SIZE_XXS,
          },
        },
      }}
    >
      <Drawer
        open={open}
        styles={{
          body: {
            paddingTop: SPACING.SIZE_MS,
          },
        }}
        onClose={onClose}
        title={<Title onClose={onClose} jobType="job" mode="add" />}
        footer={<Footer onCancel={onClose} onSave={() => {}} />}
        destroyOnClose
        width={575}
      >
        <Assistant
          assistantDescription={assistantDescription}
          setAssistantDescription={setAssistantDescription}
        />

        <Form form={form}>
          <FormItem
            name="jobType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Segmented
              data-testid="jobType"
              options={JOB_TYPE_OPTIONS}
              block
              size="large"
              style={{
                minHeight: 40,
                fontWeight: 500,
                letterSpacing: FONTS.medium.letterSpacing,
              }}
            />
          </FormItem>
        </Form>
      </Drawer>
    </ConfigProvider>
  );
}
