import styled from 'styled-components/macro';
import Button from '../Button';

export const ButtonQuaternaryStyled = styled(Button)`
  ${(props) => props.theme.fonts.sizes.small};
  ${(props) => props.theme.fonts.weights.medium};
  color: ${(props) => props.theme.colors.persianGreenMed};
  border: 1px solid ${(props) => props.theme.colors.persianGreenMed};
  background-color: ${(props) => props.theme.colors.white};
  :active,
  :hover {
    border-color: ${(props) => props.theme.colors.persianGreen};
  }
  :active {
    color: ${(props) => props.theme.colors.persianGreen};
    border-color: ${(props) => props.theme.colors.persianGreen};
  }
`;
