import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Edit from './Edit';
import Exit from './Exit';
import Delete from './Delete';
import Copy from './Copy';
import Arrow from './Arrow';

const MODIFIER_CONFIG = {
  right: () => `& :last-child: { margin-right: 0}`,
  alignCenter: () => `align-items: center;`,
};

const IconContainer = styled.div<{ modifiers?: string[] }>`
  display: flex;
  margin-left: auto;
  & > * {
    margin: 0 0.7rem;
    cursor: pointer;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

const Icons: FC<HTMLAttributes<HTMLDivElement> & { modifiers: string[] }> & {
  Edit: typeof Edit;
  Exit: typeof Exit;
  Delete: typeof Delete;
  Copy: typeof Copy;
  Arrow: typeof Arrow;
} = (props) => <IconContainer {...props} />;

Icons.Edit = Edit;
Icons.Exit = Exit;
Icons.Delete = Delete;
Icons.Copy = Copy;
Icons.Arrow = Arrow;

export default Icons;
