import { gql } from '@apollo/client';

export default gql`
  query ListLocationTypes(
    $filters: Map
    $first: Int
    $cursor: String
    $orderBy: String
    $limit: Int
    $page: Int
  ) {
    page: ListLocationTypes(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
      limit: $limit
      page: $page
    ) {
      totalCount
      edges {
        node {
          ... on LocationType {
            id
            name: displayName
            code
            note
            timelineCount
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
