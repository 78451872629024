import styled from 'styled-components/macro';
import { ReactComponent as Exit } from 'images/svg/close.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  right: ({ theme }: { theme: any }) => `
    margin-left: auto
  `,
  largeMobile: ({ theme }: { theme: any }) => `
    @media (max-width: ${theme.breakpoints.miniLg}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `,
};

const ExitIcon = styled(Exit)`
  cursor: pointer;
  :hover,
  :active {
    g {
      fill: ${(props) => props.theme.colors.tealMed};
    }
  }
  ${(props) => props.theme.animations.hoverUp()}

  ${applyStyleModifiers(CONFIG)};
`;
export default ExitIcon;
