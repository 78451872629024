import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  tight: ({ theme }: { theme: any }) => `
    margin-bottom: 2rem;
  `,
};

const Title = styled.h3`
  text-align: left;
  margin-bottom: 2rem;
  margin-top: 0;

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Title;
