import styled from 'styled-components/macro';
import { ReactComponent as EditIcon } from '@optii/shared/images/svg/editnew.svg';
import {
  ModifiersConfig,
  applyStyleModifiers,
} from 'styled-components-modifiers';

const MODIFIERS_CONFIG: ModifiersConfig = {
  big: () => `
  width: 2.2rem;
  height: 2.2rem;
`,
  medium: () => `
  width: 1.8rem;
  height: 1.8rem;
`,
  right: () => `
  margin-left: auto
`,
  largeMobile: ({ theme }) => `
  @media (max-width: ${theme.breakpoints.miniLg}) {
    width: 2.5rem;
    height: 2.5rem;
  }
`,
};

const Edit = styled(EditIcon)<{ active?: boolean }>`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  :hover,
  :active {
    g {
      fill: ${(props) => props.theme.colors.tealMed};
    }
  }
  ${({ active, theme }) =>
    active &&
    `g {
    fill: ${theme.colors.tealMed};
  }`}
  ${(props) => props.theme.animations.hoverUp()}
  ${applyStyleModifiers(MODIFIERS_CONFIG)};
`;

export default Edit;
