import styled from 'styled-components/macro';
import { PrimaryButton } from '@optii/shared';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  large: () => `
    min-width: 23rem;
  `,
  medium: () => `
    min-width: 17.25rem;
  `,
  tertiary: ({ theme }: { theme: any }) => `
  color: ${theme.colors.teal};
  border: 1px solid ${theme.colors.teal};
  background-color: transparent;
  :active,
  :hover {
    border-color: ${theme.colors.tealXDark};
  }
  :active {
    color: ${theme.colors.tealXDark};
    border-color: ${theme.colors.tealXDark};
  }`,
};

const Button = styled(PrimaryButton)`
  margin-left: 1.3rem;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Button;
