import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { breakpoints } from '../../../index.ts';

const MODIFIER_CONFIG = {
  jobFilters: () => `
    top: 1.8rem;
    @media(max-width:${breakpoints.mdMax902}) {
      top: 0.5rem;
    }
    width: calc(100% - 25rem); // minus search block width
  `,
};

// for GreyFilterWithArrows
const InlineFiltersGeneralContainer = styled.div`
  justify-content: flex-start;
  display: flex;
  margin-right: auto;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.miniLgMax}) {
    flex-wrap: wrap;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export default InlineFiltersGeneralContainer;
