import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as FilterIcon } from './filter-icon.svg';

const FilterIconWrapper = styled(FilterIcon)`
  min-width: 2.4rem;
  min-height: 2.1rem;
`;

function IconAlt() {
  return <FilterIconWrapper />;
}

export default IconAlt;
