import {
  CSSProperties,
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
} from 'react';
import {
  Card,
  COLORS,
  ConfigProvider,
  Flex,
  Form,
  FormInstance,
  FormItem,
  InputNumber,
  RADIUS,
  SPACING,
  ThemeConfig,
  Typography,
} from '@optii/ui-library';
import { TFunction, useTranslation } from 'react-i18next';

type Props = {
  setFormValues: Dispatch<SetStateAction<any>>;
  isRequired: boolean;
  pointsComplete?: number;
  pointsIncomplete?: number;
  form: FormInstance<{ pointsIncomplete: boolean }>;
  disabled: boolean;
};

type AddOnAfterProps = {
  t: TFunction<['common', 'checklist'], undefined>;
};

const BODY_STYLE: CSSProperties = {
  paddingInline: SPACING.SIZE_MD,
  paddingBlock: SPACING.SIZE_XS,
};

const FORM_ITEM_STYLE: CSSProperties = {
  margin: SPACING.NONE,
  width: 96,
};

const THEME: ThemeConfig = {
  components: {
    Card: {
      colorBgContainer: COLORS.primary[0],
    },
    Form: {
      labelColor: COLORS.primary[8],
    },
    InputNumber: {
      addonBg: COLORS.primary[0],
      colorPrimary: COLORS.primary[8],
      colorBorder: '#b2d5d1',
      borderRadius: RADIUS.RADIUS_DEFAULT,
    },
  },
};

function AddOnAfter({ t }: AddOnAfterProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: COLORS.primary[8],
        },
      }}
    >
      <Typography.Text>{t('common:pts')}</Typography.Text>
    </ConfigProvider>
  );
}

function CustomLabel({ text }: { text: string }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: COLORS.primary[8],
          },
        },
      }}
    >
      {text}
    </ConfigProvider>
  );
}

function TaskScore({
  setFormValues,
  isRequired,
  form,
  pointsComplete = 1,
  pointsIncomplete = 0,
  disabled,
}: Props) {
  const { t } = useTranslation(['common', 'checklist']);

  useEffect(() => {
    setFormValues((previous: { pointsIncomplete?: boolean }) => {
      if (isRequired) {
        delete previous.pointsIncomplete;
        form.resetFields(['pointsIncomplete']);
        return previous;
      }
      return {
        ...previous,
        ...form.getFieldsValue(),
      };
    });
  }, [isRequired, form, setFormValues]);

  return (
    <ConfigProvider theme={THEME}>
      <Card
        styles={{
          body: BODY_STYLE,
        }}
        style={{
          marginTop: SPACING.SIZE_MS,
        }}
      >
        <Form
          form={form}
          preserve
          disabled={disabled}
          initialValues={{
            pointsComplete,
            pointsIncomplete,
          }}
          onValuesChange={(changedValues) => {
            setFormValues(
              (prev: {
                pointsIncomplete?: number;
                pointsComplete: number;
              }) => ({
                ...prev,
                ...changedValues,
              }),
            );
          }}
        >
          <Flex gap={SPACING.SIZE_XL}>
            <FormItem
              style={FORM_ITEM_STYLE}
              name="pointsComplete"
              label={<CustomLabel text={t('common:Complete')} />}
            >
              <InputNumber
                min={0}
                max={100}
                controls={false}
                addonAfter={<AddOnAfter t={t} />}
              />
            </FormItem>

            {/* {!isRequired ? (
              <FormItem
                style={FORM_ITEM_STYLE}
                name="pointsIncomplete"
                label={<CustomLabel text={t('common:Incomplete')} />}
              >
                <InputNumber
                  controls={false}
                  max={100}
                  min={0}
                  addonAfter={<AddOnAfter t={t} />}
                />
              </FormItem>
            ) : null} */}
          </Flex>
        </Form>
      </Card>
    </ConfigProvider>
  );
}

export const Score = memo(TaskScore);
