import React, { useContext } from 'react';
import {
  ConfigProvider,
  FormItem,
  InputNumber,
  RadioGroup,
  SPACING,
  type FormInstance,
} from '@optii/ui-library';
import { SelectLocations } from '@optii/global';
import { useTranslation } from 'react-i18next';
import { HOUSEKEEPING_ACTION_OPTIONS } from './constants';
import { FormContext } from './context';

type Props = {
  form: FormInstance;
};

export function Housekeeping({ form }: Props) {
  const { t } = useTranslation(['jobs', 'common', 'fields']);

  const { shard } = useContext(FormContext);
  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            borderRadius: SPACING.NONE,
          },
          Upload: {
            padding: SPACING.SIZE_MS,
          },
        },
      }}
    >
      <FormItem
        name="action"
        required
        rules={[
          {
            required: true,
            message: t('jobs:Please select an action.'),
          },
        ]}
      >
        <RadioGroup
          optionType="button"
          buttonStyle="solid"
          options={HOUSEKEEPING_ACTION_OPTIONS}
          style={{
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
          }}
          block
        />
      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={(prev, curr) => prev.property !== curr.property}
      >
        {({ getFieldValue }) =>
          getFieldValue('property') ? (
            <>
              <SelectLocations
                form={form}
                fieldName="locations"
                propertyId={shard}
                label={t('fields:Location')}
                isJob
                multiple={false}
              />
              <FormItem name="creditValue" label={t('fields:Credit Value')}>
                <InputNumber
                  style={{
                    width: '100%',
                  }}
                />
              </FormItem>
            </>
          ) : undefined
        }
      </FormItem>
    </ConfigProvider>
  );
}
