import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from 'react';

import {
  Button,
  Flex,
  Input,
  SPACING,
  Typography,
  COLORS,
  Space,
  FONTS,
  Upload,
  UploadProps,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { AudioOutlined, CameraOutlined } from '@ant-design/icons';
import {
  FILE_SIZE_LIMIT,
  IMAGE_EXTENSIONS,
  New,
  useAnalyzeImageMutation,
  useStoreFileMutation,
} from '@optii/global';
import { Session } from '@optii/shared';

const BUTTON_STYLE: CSSProperties = {
  color: COLORS.primary[5],
  padding: SPACING.SIZE_MS,
};

const INFO_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  fontWeight: 500,
  letterSpacing: FONTS.medium.letterSpacing,
};

const INFO_CONTAINER_STYLE: CSSProperties = {
  backgroundColor: COLORS.secondary[1],
  paddingInline: SPACING.SIZE_SM,
  border: `1px solid ${COLORS.secondary[2]}`,
  marginBottom: SPACING.SIZE_MD,
};

const LABEL_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  letterSpacing: FONTS.medium.letterSpacing,
  fontWeight: 500,
};

const INPUT_EXAMPLE_STYLE: CSSProperties = {
  color: COLORS.neutral[7],
  letterSpacing: FONTS.xSmall.letterSpacing,
  fontSize: FONTS.xSmall.size,
};

const ASSISTANT_CONTAINER_STYLE: CSSProperties = {
  paddingInline: SPACING.SIZE_MD,
  paddingBlock: SPACING.SIZE_MS,
  backgroundColor: COLORS.neutral[2],
  marginBottom: SPACING.SIZE_MD,
};

const { Text, Title } = Typography;

type Props = {
  assistantDescription: string;
  setAssistantDescription: Dispatch<SetStateAction<string>>;
};

export function Assistant({
  assistantDescription,
  setAssistantDescription,
}: Props) {
  const { t } = useTranslation(['common', 'jobs']);
  const { globalSnack } = useContext(Session);
  const [analyzeImage, { loading: analyzeImageLoading }] =
    useAnalyzeImageMutation({
      context: {
        _instance: 'node',
      },
    });
  const [storeFile, { loading: storeFileLoading }] = useStoreFileMutation({
    onError(error) {
      console.error(error);
      return globalSnack({
        message: t('jobs:Could not upload attachment'),
        timeout: 5000,
        error: true,
      });
    },
  });

  const uploadFile = useCallback(
    async (file: Blob) => {
      if (file.size >= FILE_SIZE_LIMIT)
        return globalSnack({
          message: t(
            'common:This file is too large, please provide a file under 20MB',
          ),
          error: true,
          timeout: 5000,
        });

      const { data } = await storeFile({
        variables: {
          file,
          name: file.name,
        },
      });

      return data?.file.URL;
    },
    [globalSnack, storeFile, t],
  );

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, file } = options;

    if (typeof onSuccess === 'function') {
      const imagePath = await uploadFile(file as Blob);

      if (imagePath)
        await analyzeImage({
          variables: {
            imagePath,
          },
          onCompleted(data) {
            console.log(data.analyzeImage);
          },
          onError(error) {
            console.error(error);

            globalSnack({
              message: t(
                'common:The image does not possess any hotel related items, please try again with a hotel related image',
              ),
              error: true,
              timeout: 5000,
            });
          },
        });

      onSuccess(imagePath);
    }
  };

  return (
    <Space style={ASSISTANT_CONTAINER_STYLE}>
      <Title />

      <Flex vertical>
        <Flex gap={SPACING.SIZE_SM} align="center" style={INFO_CONTAINER_STYLE}>
          <New />
          <Text style={INFO_STYLE}>
            {t(
              'jobs:Upload a photo or enter a job description, using text or voice',
            )}
          </Text>
        </Flex>

        <Flex vertical>
          <Text style={LABEL_STYLE}>{t('common:Job Description')}</Text>
          <Flex align="center" gap={SPACING.SIZE_SM}>
            <Input
              allowClear
              size="large"
              value={assistantDescription}
              onChange={({ target: { value } }) =>
                setAssistantDescription(value)
              }
            />
            <Button
              icon={<AudioOutlined />}
              size="large"
              type="default"
              style={BUTTON_STYLE}
            />

            <Upload
              type="select"
              showUploadList={false}
              accept={IMAGE_EXTENSIONS.join(',')}
              customRequest={customRequest}
            >
              <Button
                icon={<CameraOutlined />}
                loading={storeFileLoading}
                size="large"
                type="default"
                style={BUTTON_STYLE}
              />
            </Upload>
          </Flex>
        </Flex>
        <Text style={INPUT_EXAMPLE_STYLE}>
          {t(
            "jobs:Ex, Replace the overhead light's lightbulb in room 421 by 1:31",
          )}
        </Text>
      </Flex>
    </Space>
  );
}
