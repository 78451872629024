import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as ArrowDown } from 'images/svg/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'images/svg/arrow-up.svg';
import { ReactComponent as DragAndDropGray } from 'images/svg/drag-and-drop-gray.svg';
import { Filters, LegacyCheckbox as FormCheckbox } from '@optii/shared';
import DeleteIcon from './icons/DeleteIcon';
// import Draggable from "./blocks/Draggable";

import Attachments from './blocks/Attachments';

export const Container = styled.div`
  margin-bottom: 0;
  background: #fff;
  min-width: 100%;
`;

export const ItemContainer = styled.div<any>`
  padding: 1.6rem;
  padding-bottom: ${({ dashed }) => (dashed ? '1.4rem' : 0)};
  background-image: ${({ dashed, theme }) =>
    dashed
      ? `linear-gradient(to right, ${theme.colors.charcoal03} 70%, rgba(255,255,255,0) 0%)`
      : 'none'};
  background-position: bottom;
  background-size: 0.7rem 0.1rem;
  background-repeat: repeat-x;
  position: relative;
  padding-top: ${({ dashed }) => (dashed ? '1.8rem' : '0')};
  border-left: ${({ subTask, theme }) =>
    subTask ? `1.2rem solid ${theme.colors.charcoal03}` : '0'};

  ${(props) => (props.last ? 'background-image: none;' : '')};
`;

export const Gallery = styled.div`
  display: flex;
  column-gap: 0.7rem;
  margin-left: 0.5rem;
`;

export const TextInput = styled.input`
  border: 0;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.charcoal02};
  padding: 0.2rem;
  font-size: 1.6rem;
  width: 100%;

  ::placeholder {
    color: ${(props) => props.theme.colors.charcoal03};
    font-style: italic;
  }

  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.persianGreen};
  }
`;

export const FulfillmentTextInput = styled.input`
  border: 0.1rem solid ${(props) => props.theme.colors.charcoal02};
  font-size: 1.6rem;
  font-family: 'Roboto';
  font-weight: 300;
  color: ${(props) => props.theme.colors.charcoal05};
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1.2rem;
  ::placeholder {
    color: ${(props) => props.theme.colors.charcoal03};
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.persianGreen};
  }
`;

export const Title = styled.h3`
  border: 0;
  padding: 0.2rem;
  font-size: 1.6rem;
  padding-right: 1.6rem;
  text-align: left;
  ${(props) => props.theme.fonts.type.primary};
  font-weight: 500;
`;

export const SubTitle = styled.div`
  ${(props) => props.theme.fonts.type.primary};
  ${(props) => props.theme.fonts.weights.ligth};
  ${(props) => props.theme.fonts.sizes.smallest};
  color: ${(props) => props.theme.colors.charcoal05};
  font-style: italic;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.8rem;
`;

export const InnerTitle = styled.h4`
  ${(props) => props.theme.fonts.type.boundary};
  ${(props) => props.theme.fonts.sizes.largest};
  color: ${(props) => props.theme.colors.charcoal06};
  padding: 2.4rem 0 0.8rem 0;
`;

export const TasksCompletionTitle = styled.h4`
  ${(props) => props.theme.fonts.type.primary};
  ${(props) => props.theme.fonts.sizes.largest};
  color: ${(props) => props.theme.colors.charcoal06};
  padding: 2.4rem 0 0.8rem 0;
`;

export const TasksCompletionRequired = styled.span`
  color: ${(props) => props.theme.colors.rose};
`;

export const TasksCompletionErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.rose};
  padding-top: 10px;
  display: block;
  ${(props) => props.theme.fonts.sizes.small};
`;

export const Description = styled.div`
  ${(props) => props.theme.fonts.type.primary};
  ${(props) => props.theme.fonts.sizes.large};
  color: ${(props) => props.theme.colors.charcoal};
  ${(props) => props.theme.fonts.weights.weak};
  margin-bottom: 0.8rem;
`;

export const TaskLabel = styled.h4`
  border: 0;
  padding: 0.2rem;
  font-size: 1.6rem;
  padding-right: 1.6rem;
  line-height: 2.5rem;
  text-align: left;
  ${(props) => props.theme.fonts.type.primary};
`;

export const ActionsContainer = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.charcoal01opct50};
  padding: 0.7rem 1rem 0.4rem;
  margin-top: 1.6rem;
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
  button {
    border: 0;
    cursor: ${({ readOnly }) => readOnly && 'default'};
    background: transparent;
  }
  ${({ readOnly }) =>
    readOnly &&
    `
    svg, img {
      filter: grayscale(100%);
      opacity: .8
    }
  `};
`;

export const FlexContainer = styled.div<any>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: ${({ alignItems }) => alignItems || 'initial'};
  flex: ${({ flex }) => flex || '1'};
  ${({ col }) => col && 'flex-direction: column;'}
  column-gap: ${({ colGap }) => colGap || '1rem'};
  row-gap: ${({ rowGap }) => rowGap || 'initial'};
`;

export const FlexItem = styled.div<any>`
  width: 100%;
  flex: ${({ flex }) => flex || '1'};
  ${({ col }) => col && 'display: flex;flex-direction: column;'}
  ${({ row }) => row && 'display: flex;flex-direction: row;'}
`;

export const TaskContent = styled(FlexItem as any)<any>`
  width: calc(100% - 1.5rem);

  ${({ isDragging, theme }) =>
    isDragging
      ? `
    background: ${theme.colors.persianGreenLight};
    border-radius: 1rem;
    opacity: 0.25;
    & * {
      opacity: 0;
    }
  `
      : ''};
`;

export const TaskTypeWrapper = styled.div<any>`
  [type='select'] {
    padding-right: ${({ canDelete, isLast }) =>
      canDelete && !isLast ? '2rem' : '0'};
  }
`;

export const Select = styled(Filters.GreenFilterWithArrows as any)`
  .react-select {
    margin: 0;
    min-width: 12rem;
  }
  .react-select__menu {
    min-width: 16rem;
    right: 0;
  }
`;

export const Error = styled.div`
  color: ${(props) => props.theme.colors.rose};
  ${(props) => props.theme.fonts.sizes.smallest};
  padding-top: 0.8rem;
`;

export const GroupItemsList = styled.div`
  padding-left: 1rem;
`;

export const IconDelete = styled(DeleteIcon as any)<any>`
  margin-top: 0.3rem;
  position: absolute;
  right: 1rem;
  opacity: ${({ isDragging }) => (isDragging ? '0' : 'initial')};
`;

export const IconDrag = styled(DragAndDropGray)<any>`
  min-width: 1.3rem;
  margin-top: 0.3rem;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  opacity: ${({ isDragging }) => (isDragging ? '0' : 'initial')};

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

export const IconArrowUp = styled(ArrowUp)`
  cursor: pointer;
`;

export const IconArrowDown = styled(ArrowDown)`
  cursor: pointer;
`;

export const RequiredFlag = styled.div`
  padding-right: 0.8rem;
  &:before {
    content: '\\002A';
    color: ${(props) => props.theme.colors.rose};
    font-size: 1.4rem;
    display: block;
    height: 1rem;
    margin-top: 0.7rem;
  }
`;

export const Switcher = styled.div`
  display: flex;
  align-items: center;
  label {
    color: ${(props) => props.theme.colors.charcoal04};
    font-size: 1.4rem;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 1.7rem;
    column-gap: 1rem;
  }

  label span {
    background-color: ${(props) => props.theme.colors.charcoal03};
    border-radius: 2rem;
    height: 2.5rem;
    top: -0.4rem;
    width: 4.3rem;
  }
  input + span:before {
    transform: translate(0.6rem, 0.4rem);
    background: white;
  }
  input:checked + span {
    background-color: ${(props) => props.theme.colors.persianGreen};
  }
  input:checked + span:before {
    transform: translate(2.4rem, 0.4rem);
    background: white;
  }
`;

export const CheckBox = styled.div`
  label span {
    width: 2.2rem;
    height: 2.2rem;
    background-color: ${(props) => props.theme.colors.charcoal01};
    transition: background 0.1s;
    box-shadow: none;
    border-color: ${(props) => props.theme.colors.charcoal03};
  }
  input:checked + span:after {
    left: 50%;
    top: 50%;
    width: 0.6rem;
    height: 1.2rem;
    margin-left: -0.4rem;
    margin-top: -0.9rem;
    border-width: 0 0.2rem 0.2rem 0;
  }
`;

export const TemplateMessage = styled.div`
  padding: 0.8rem;
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.colors.emeraldXXLight};
  color: ${(props) => props.theme.colors.emeraldDark};
  font-size: 1.4rem;
  line-height: 1.6rem;

  ul {
    margin: 0.5rem 0;
    padding: 0 1.6rem;
  }

  li {
    list-style: disc;
  }
`;

const TopModalContent = styled.div<any>`
  padding: 0.1rem 1.6rem 1.6rem 1.6rem;
  ${(props) => (props.pb0 ? 'padding-bottom: 0' : '')};
  ${(props) => (props.pt0 ? 'padding-top: 0' : '')};
  background-color: ${(props) => props.theme.colors.charcoal01opct50};
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.charcoal02};

  [class^='Label-sc'] {
    background: ${(props) => props.theme.colors.charcoal01opct50};
  }
`;

export const DraggableContainer = styled.div`
  position: relative;
  height: 100%;
  marginbottom: 2rem;
  .no-scroll-container {
    /* height of the item */
    padding-bottom: 165px;
  }
  .draggable {
    cursor: initial !important;
  }
  .draggable-test {
    background: ${({ theme }) => theme.colors.persianGreenLight} !important;
    border-radius: 1rem;
    opacity: 0.25;
    border: 0 !important;
    margin: 5px !important;
    min-height: 110px;
  }
  .draggable-active {
    background: #fff;
  }
  .react-select__menu-list {
    margin-top: -5px;
    max-height: 190px !important;
  }
  .drag-drop-context {
    > div,
    > div > div:first-child {
      min-height: calc(100vh - 290px) !important;
    }
  }
  [droppableid='droppableId'] {
    overflow: visible !important;
  }
`;

const elements = {
  Container,
  RequiredFlag,

  ItemContainer,
  Gallery,
  Group: (props: any) => props.children,
  GroupItemsList,
  ActionsContainer,
  IconDrag,
  IconDelete,
  IconArrowUp,
  IconArrowDown,
  Error,
  Title,
  SubTitle,
  TaskLabel,
  TextInput,
  FulfillmentTextInput,
  CheckBox: (props: any) => (
    <CheckBox {...props}>
      <FormCheckbox type="checkbox" {...props} />
    </CheckBox>
  ),
  Switcher: (props: any) => (
    <Switcher {...props}>
      <FormCheckbox isSwitcher omitLabelFor type="checkbox" {...props} />
    </Switcher>
  ),
  Select,
  FlexContainer,
  TaskContent,
  TaskTypeWrapper,
  FlexItem,
  Attachments,
  InnerTitle,
  TasksCompletionTitle,
  TasksCompletionRequired,
  TasksCompletionErrorMessage,
  TemplateMessage,
  TopModalContent,
  Description,
  DraggableContainer,
};

export default elements;
