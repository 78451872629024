import { useQuery } from '@apollo/client';
import { COLORS, Typography } from '@optii-solutions/ui-library';

//@ts-ignore
import { GET_LIST_LOCATION_MINIMAL_LOCATIONS } from '@optii/shared/queries/getListLocationMinimal';

/**
 * Hook for using GET_LIST_LOCATION_MINIMAL_LOCATIONS query.
 * @param {Object} props - Settings for the hook
 * @param {boolean} [props.skip=false] - Should skip or not the fetch.
 * @param {boolean} [props.withShortDisplayName=false]
 * @param {boolean} [props.withLongDisplayName=false]
 * @param {String} props.propertyShard
 * @returns {UseMinimalLocationsReturn}
 */
export function useMinimalLocations({
  skip = false,
  withShortDisplayName = false,
  withLongDisplayName = false,
  propertyShard = '',
} = {}) {
  const { data, loading, error } = useQuery(
    GET_LIST_LOCATION_MINIMAL_LOCATIONS,
    {
      skip,

      context: {
        _shard: propertyShard,
      },
    },
  );

  const getCurrentTitle = ({
    withShortDisplayName: _withShortDisplayName,
    withLongDisplayName: _withLongDisplayName,
    location,
  }: any) => {
    if (_withShortDisplayName) {
      return location.shortDisplayName;
    }
    if (_withLongDisplayName) {
      return location.longDisplayName;
    }
    return location.title;
  };

  const getCurrentTitleElement = ({
    withShortDisplayName: _withShortDisplayName,
    withLongDisplayName: _withLongDisplayName,
    location,
    isParent,
  }: any) => {
    if (_withShortDisplayName) {
      return (
        <>
          <Typography.Text
            style={{
              color: COLORS.neutral[9],
            }}
          >
            {location.shortDisplayName}
            {location.locationTypeName?.toLowerCase() === 'property' || isParent
              ? ' Only'
              : null}
          </Typography.Text>
          {location.childCount > 0 && !isParent && (
            <Typography.Text style={{ marginLeft: '5px' }}>
              (
              {location.locationTypeName?.toLowerCase() === 'property'
                ? data?.ListLocationMinimal?.length
                : location.childCount + 1}
              )
            </Typography.Text>
          )}
        </>
      );
    }
    if (_withLongDisplayName) {
      return (
        <>
          <Typography.Text
            style={{
              color: COLORS.neutral[9],
            }}
          >
            {location.longDisplayName}
            {location.locationTypeName?.toLowerCase() === 'property' || isParent
              ? ' Only'
              : null}
          </Typography.Text>
          {location.childCount > 0 && !isParent && (
            <Typography.Text style={{ marginLeft: '5px' }}>
              (
              {location.locationTypeName?.toLowerCase() === 'property'
                ? data?.ListLocationMinimal?.length
                : location.childCount + 1}
              )
            </Typography.Text>
          )}
        </>
      );
    }

    return (
      <>
        <Typography.Text
          style={{
            color: COLORS.neutral[9],
          }}
        >
          {location.title}
          {location.locationTypeName?.toLowerCase() === 'property' && isParent
            ? ' Only'
            : null}
        </Typography.Text>
        {location.childCount > 0 && !isParent && (
          <Typography.Text style={{ marginLeft: '5px' }}>
            (
            {location.locationTypeName?.toLowerCase() === 'property'
              ? data?.ListLocationMinimal?.length
              : location.childCount + 1}
            )
          </Typography.Text>
        )}
      </>
    );
  };

  const childrenLocations = data?.ListLocationMinimal?.map(
    (location: {
      childCount: number;
      id: number;
      pId: number;
      name: any;
      shortDisplayName: any;
      longDisplayName: any;
      locationTypeName: any;
      roomTypeName: any;
    }) => {
      const isParent = location.childCount > 0;

      const title = getCurrentTitleElement({
        withShortDisplayName,
        withLongDisplayName,
        location,
      });

      const titleText = getCurrentTitle({
        withShortDisplayName,
        withLongDisplayName,
        location,
      });

      const locationId = isParent ? location.id + 0.1 : location.id;
      return {
        ...location,
        pId: Number(location.pId + 0.1).toString(),
        id: locationId,
        value: locationId,
        title,
        titleText,
        displayName: location.name,
        name: location.name,
        shortDisplayName: location.shortDisplayName,
        longDisplayName: location.longDisplayName,
        locationTypeName: location.locationTypeName,
        roomType: location.roomTypeName,
      };
    },
  ).sort((a, b) =>
    a.titleText.localeCompare(b.titleText, undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
  );

  const parentLocations = data?.ListLocationMinimal?.filter(
    (location: { childCount: number }) => location.childCount > 0,
  ).map(
    (parentLocation: {
      id: number;
      name: any;
      shortDisplayName: any;
      longDisplayName: any;
      locationTypeName: any;
      roomTypeName: any;
    }) => ({
      ...parentLocation,
      pId: Number(parentLocation.id + 0.1).toString(),
      id: parentLocation.id,
      value: parentLocation.id,
      title: getCurrentTitleElement({
        withShortDisplayName,
        withLongDisplayName,
        location: parentLocation,
        isParent: true,
      }),
      titleText: getCurrentTitle({
        withShortDisplayName,
        withLongDisplayName,
        location: parentLocation,
      }),
      displayName: parentLocation.name,
      shortDisplayName: parentLocation.shortDisplayName,
      longDisplayName: parentLocation.longDisplayName,
      name: parentLocation.name,
      locationTypeName: parentLocation.locationTypeName,
      roomType: parentLocation.roomTypeName,
    }),
  );
  const formattedLocations = parentLocations?.concat(childrenLocations);

  return {
    locations: formattedLocations,
    loading,
    error,
  };
}
