import {
  Badge,
  COLORS,
  ConfigProvider,
  Flex,
  FONTS,
  Grid,
  Menu,
  MenuProps,
  SPACING,
  Tooltip,
  Typography,
} from '@optii-solutions/ui-library';
import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import Loading from '@optii/shared/components/atoms/Loading';
import { StopOutlined } from '@ant-design/icons';
import { UserAccessContext } from '@optii/shared';
import { Channel } from './types';
import { MessagePreview } from '../message/MessagePreview';
import { ChatContext } from '../../context/chat.context';
import { SearchAndCreate } from '../search/SearchAndCreate';
import { SelectOption } from '../message/types';

const WIDTHS = {
  desktop: 256,
  mobile: '100%',
};

const MENU_ITEM_TITLE_STYLE: CSSProperties = {
  fontFamily: 'Montserrat',
  marginTop: SPACING.NONE,
};

const MENU_ITEM_STYLE: CSSProperties = {
  color: 'transparent',
  backgroundColor: 'transparent',
};

const KEYS = {
  channels: 'channels',
  directMessages: 'directMessages',
};

type MenuItem = Required<MenuProps>['items'][number];

function MenuItemLink(
  { key, label, inactiveEmployees, recipientIds }: Channel,
  unreadMessageCount?: number,
  showEllipsis?: boolean,
  isOnline?: boolean,
  uniqueName?: string | null,
  employees?: SelectOption[],
  userId?: string,
): MenuItem {
  const isPrivateConversation =
    employees
      ?.filter((item) => uniqueName?.split('_').includes(item.value))
      .filter((item) => item.value !== userId)
      .map((item) => item.label)
      .join(', ') === label && recipientIds?.length === 1;

  const isPrivateInactiveConversation =
    isPrivateConversation && inactiveEmployees?.length;

  return {
    key,
    label: (
      <Tooltip title={showEllipsis && label && label.length > 20 ? label : ''}>
        <Link to={`/messages/${key}`}>
          <Flex align="center" justify="space-between">
            {isPrivateInactiveConversation ? (
              <StopOutlined
                style={{
                  color: COLORS.neutral[5],
                  fontSize: FONTS.small.size,
                }}
              />
            ) : null}
            <span
              style={
                showEllipsis
                  ? {
                      width: '200px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  : undefined
              }
            >
              {label}
              {isOnline && isPrivateConversation ? (
                <Badge
                  dot={isOnline}
                  color={COLORS.polarGreen[5]}
                  offset={[8, 0]}
                  styles={{
                    indicator: {
                      borderRadius: '',
                    },
                  }}
                />
              ) : null}
            </span>

            {unreadMessageCount ? (
              <Badge
                count={unreadMessageCount}
                color={COLORS.goldenPurple[5]}
                styles={{
                  indicator: {
                    paddingInline: SPACING.SIZE_DEFAULT,
                  },
                }}
              />
            ) : null}
          </Flex>
        </Link>
      </Tooltip>
    ),

    style: {
      paddingLeft: SPACING.SIZE_MD,
    },
    type: 'item',
  };
}

export function Channels() {
  const { useBreakpoint } = Grid;
  const { t } = useTranslation(['chat']);
  const {
    client,
    unreadConversations,
    channels,
    setUnreadConversations,
    employees,
  } = useContext(ChatContext);
  const { user } = useContext(UserAccessContext.Context);
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const [search] = useQueryParam('chat', StringParam);

  const sortPrivateChannels = useCallback(
    (a: Channel, b: Channel) => {
      const aCount = unreadConversations?.channels[a.key] ?? 0;
      const bCount = unreadConversations?.channels[b.key] ?? 0;

      if (aCount > 0 && bCount === 0) return -1;
      if (aCount === 0 && bCount > 0) return 1;

      return a.label?.localeCompare(b.label || '') || 0;
    },
    [unreadConversations],
  );

  const filterChannelsBySearch = useCallback(
    (channel: Channel) =>
      search
        ? channel.label?.toLowerCase().indexOf(search.toLowerCase()) !== -1
        : true,
    [search],
  );

  const [onlineStatusPreview, setOnlineStatusPreview] = useState<
    Array<{
      userId: string;
      isOnline: boolean;
    }>
  >([]);

  const { privateChannels, publicChannels } = channels;

  const getPrivateChannelOnlineStatus = useCallback(async () => {
    const statusPreview: Array<{ userId: string; isOnline: boolean }> = [];
    if (privateChannels.length > 0 && client) {
      const userIds = privateChannels.flatMap(
        ({ recipientIds }) => recipientIds || [],
      );

      const uniqueUserIds = Array.from(new Set(userIds));
      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          const userInfo = await client.getUser(userId);
          statusPreview.push({
            isOnline: userInfo.isOnline || false,
            userId,
          });

          return statusPreview;
        }),
      );
    }
    setOnlineStatusPreview(statusPreview);
  }, [privateChannels, client]);

  useEffect(() => {
    getPrivateChannelOnlineStatus();
  }, [getPrivateChannelOnlineStatus]);

  const items: MenuItem[] = useMemo(
    () => [
      {
        key: KEYS.channels,
        label: (
          <Typography.Title level={5} style={MENU_ITEM_TITLE_STYLE}>
            {t('chat:Channels')}
          </Typography.Title>
        ),
        style: MENU_ITEM_STYLE,
        children: publicChannels
          .filter(filterChannelsBySearch)
          ?.map((publicChannel) =>
            MenuItemLink(
              publicChannel,
              unreadConversations?.channels[publicChannel.key],
            ),
          ),
      },
      breakpoints.xs
        ? {
            type: 'divider',
            style: {
              color: COLORS.neutral[5],
            },
          }
        : null,
      {
        key: KEYS.directMessages,
        label: (
          <Typography.Title level={5} style={MENU_ITEM_TITLE_STYLE}>
            {t('chat:Direct Messages')}
          </Typography.Title>
        ),
        style: MENU_ITEM_STYLE,
        children: breakpoints.xs
          ? privateChannels
              .filter(filterChannelsBySearch)
              .sort(sortPrivateChannels)
              ?.map((channel) =>
                MessagePreview(
                  channel,
                  onlineStatusPreview.find(({ userId }) =>
                    channel?.recipientIds?.includes(userId),
                  )?.isOnline,
                  channel.recipientIds?.length,
                  unreadConversations?.channels[channel.key],
                  channel.uniqueName,
                  employees,
                  user?.id,
                ),
              )
          : privateChannels
              .filter(filterChannelsBySearch)
              .sort(sortPrivateChannels)
              .map((privateChannel) =>
                MenuItemLink(
                  privateChannel,
                  unreadConversations?.channels[privateChannel.key],
                  true,
                  onlineStatusPreview.find(({ userId }) =>
                    privateChannel.recipientIds?.includes(userId),
                  )?.isOnline,
                  privateChannel.uniqueName,
                  employees,
                  user?.id,
                ),
              ),
      },
    ],
    [
      breakpoints.xs,
      unreadConversations,
      onlineStatusPreview,
      privateChannels,
      publicChannels,
      t,
      filterChannelsBySearch,
      employees,
      user?.id,
      sortPrivateChannels,
    ],
  );

  const loading =
    channels.privateChannels.some(
      (item) => item.label?.replace(/[, ]+/g, ' ').trim() === '', // evil little trick to await formatting before displaying chat messages
    ) ||
    !channels.publicChannels.length ||
    channels.publicChannels[0].label?.toLowerCase()?.includes('All Team');

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            colorSplit: undefined,
            itemHeight: SPACING.SIZE_XXL,
            colorBgContainer: 'transparent',
            itemHoverBg: 'transparent',
            itemBg: 'transparent',
            subMenuItemBg: 'transparent',
            itemHoverColor: COLORS.primary[5],
            itemActiveBg: COLORS.primary[1],
            itemSelectedBg: COLORS.primary[1],
            itemColor: COLORS.neutral[8],
          },
          Badge: {
            fontSize: FONTS.xSmall.size,
            fontSizeSM: FONTS.xSmall.size,
          },
        },
      }}
    >
      {breakpoints.xs ? <SearchAndCreate /> : null}
      {loading ? (
        <Loading loading />
      ) : (
        <Menu
          defaultOpenKeys={Object.values(KEYS)}
          mode="inline"
          onClick={({ key }) => {
            setUnreadConversations(({ channels: unreadChannels, total }) => ({
              total: total - unreadChannels[key],
              channels: {
                ...unreadChannels,
                [key]: 0,
              },
            }));
          }}
          selectedKeys={[id]}
          items={items}
          inlineIndent={SPACING.NONE}
          style={{
            width: breakpoints.xs ? WIDTHS.mobile : WIDTHS.desktop,
          }}
        />
      )}
    </ConfigProvider>
  );
}
