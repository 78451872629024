import React, { CSSProperties, useEffect, useState } from 'react';
import {
  ConfigProvider,
  SPACING,
  COLORS,
  Flex,
  RADIUS,
  Image,
  Divider,
  Button,
  Typography,
} from '@optii/ui-library';
import {
  DeleteOutlined,
  EyeOutlined,
  FileTextOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { isPlatform } from '@ionic/react';
import {
  FALLBACK_VIDEO_ICON,
  generateBase64ImageFromVideo,
  isFileExtensionImageOrVideo,
} from '../../utils/message';

type CustomFileItemProps = {
  url: string;
  name: string;
  onDownload: () => void;
  onPreview: () => void;
  onRemoveFile: () => void;
};

const ICON_COLOR_STYLE: CSSProperties = {
  color: '#BFBFBFBF',
};

export function CustomFileItem({
  name,
  onDownload,
  onPreview,
  onRemoveFile,
  url,
}: CustomFileItemProps) {
  const [videoThumbnail, setVideoThumbnail] = useState('');
  const { isVideo, isImage } = isFileExtensionImageOrVideo(name);
  const isIOS = isPlatform('ios');

  const isVideoOrImage = isVideo || isImage;

  useEffect(() => {
    (async () => {
      const response = await generateBase64ImageFromVideo(url, isIOS);

      setVideoThumbnail(response);
    })();
  }, [isVideo, url, isIOS]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: COLORS.neutral[8],
          },
          Divider: {
            verticalMarginInline: SPACING.NONE,
          },
          Button: {
            paddingInlineLG: SPACING.NONE,

            paddingBlock: SPACING.NONE,
          },
        },
      }}
    >
      <Flex
        align="center"
        vertical={false}
        style={{
          border: `1px solid ${COLORS.neutral[5]}`,
          borderRadius: RADIUS.RADIUS_DEFAULT,
        }}
      >
        {isVideoOrImage ? (
          <>
            <Image
              placeholder={
                <Flex align="center" justify="center">
                  <LoadingOutlined loop />
                </Flex>
              }
              preview={{
                mask: <EyeOutlined />,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onPreview();
              }}
              src={isVideo ? videoThumbnail || FALLBACK_VIDEO_ICON : url}
              width={40}
              height={40}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
              }}
            />
          </>
        ) : (
          <>
            <FileTextOutlined
              style={{
                marginInline: SPACING.SIZE_DEFAULT,
                ...ICON_COLOR_STYLE,
              }}
            />

            <Button type="link" onClick={onDownload}>
              <Typography.Text
                underline
                style={{
                  maxWidth: 100,
                }}
                ellipsis={{
                  tooltip: name,
                }}
              >
                {name}
              </Typography.Text>
            </Button>
          </>
        )}

        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={(e) => {
            e.stopPropagation();
            onRemoveFile();
          }}
          theme={{
            components: {
              Button: {
                primaryColor: COLORS.red[8],
              },
            },
          }}
        />
      </Flex>
    </ConfigProvider>
  );
}
