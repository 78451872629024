import React, { CSSProperties } from 'react';
import {
  Button,
  COLORS,
  ConfigProvider,
  Divider,
  Flex,
  FONTS,
  ThemeConfig,
  Typography,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AppConfigurationTable from './Table';

const TITLE_STYLE: CSSProperties = {
  letterSpacing: FONTS.h4.letterSpacing,
  fontSize: FONTS.h4.size,
  fontWeight: 500,
  fontFamily: 'Montserrat',
  color: COLORS.neutral[8],
};

const THEME: ThemeConfig = {
  components: {
    Divider: {
      colorSplit: COLORS.neutral[5],
    },
  },
};

export function AppConfiguration() {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  return (
    <ConfigProvider theme={THEME}>
      <Flex align="center" justify="space-between">
        <Typography.Title style={TITLE_STYLE} level={4}>
          {t('common:Platform')}
        </Typography.Title>
        <Button
          type="default"
          onClick={() => {
            history.push('/above-property/app-configuration/create');
          }}
        >
          {t('common:Add App')}
        </Button>
      </Flex>
      <Divider />
      <AppConfigurationTable />
    </ConfigProvider>
  );
}
