import styled from 'styled-components/macro';
import { FC, HTMLAttributes } from 'react';
import Title from './Title';
import Top from './Top';

const Container = styled.div``;

const ModalElements: FC<HTMLAttributes<HTMLDivElement>> & {
  Title: typeof Title;
  Top: typeof Top;
} = (props) => <Container {...props} />;

ModalElements.Title = Title;
ModalElements.Top = Top;

export default ModalElements;
