import React, { memo, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import {
  ConfigProvider,
  Button,
  COLORS,
  Col,
  Flex,
  Row,
  SPACING,
  Typography,
} from '@optii-solutions/ui-library';

import type { JobStatus } from '@optii/jobs/api/jobs';
import { RoomSummary } from '@optii/jobs/api/repeatingJobs';
import { getEnvironment } from '@optii/shared/utils/config';

import { Tooltip } from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import {
  CustomCard,
  CustomCardTop,
  CustomTag,
  JOB_CARD_STYLES,
  JOB_CARD_THEME,
  JobCardStatusContainer,
} from './JobStatusCard.elements';
import {
  DONE_JOB_CARD_PRIORITY_ICONS,
  JOB_CARD_CLEANING_STATUS_ICONS,
  JOB_CARD_ICONS,
  JOB_CARD_JOB_TYPE_ICONS,
  JOB_CARD_PRIORITY_ICONS,
  JOB_CARD_RESERVATIONS_ICONS,
  JOB_CARD_STATUSES_ICONS,
} from './JobStatusCard.icons';
import type {
  TJobCardAsset,
  TJobCardAssignment,
  TJobCardBody,
  TJobCardChecklists,
  TJobCardDeleteButton,
  TJobCardEditButton,
  TJobCardHKCard,
  TJobCardHeader,
  TJobCardIcons,
  TJobCardIconsData,
  TJobCardIconsKeysComponent,
  TJobCardLocations,
  TJobCardProjectCard,
  TJobCardProps,
  TJobCardRowProps,
  TJobCardServiceCard,
  TJobCardStartTime,
  TJobCardStatus,
  TJobCardTime,
  TJobCardWrapper,
} from './JobStatusCard.types';
import {
  getJobStatusCardAction,
  getJobStatusCardStatus,
  getJobSource,
  getRoomStatus,
  getJobStatusCardCleaningStatus,
  getJobStatusCardReservationsIcons,
  getCorrectDroppableProps,
  getRoomReservationVIPStatus,
} from './JobStatusCard.helpers';
import {
  JOB_CARD_ACTION_COLORS,
  JOB_CARD_ICONS_CLEANING_STATUS_LABELS,
  JOB_CARD_ICONS_LABELS,
  JOB_CARD_ICONS_PRIORITY_LABELS,
  JOB_CARD_ICONS_RESERVATIONS_LABELS,
  JOB_CARD_JOB_TYPE_ICONS_LABELS,
} from './JobStatusCard.constants';
import {
  JOB_ADVANCED_STATUSES,
  JOB_STATUSES,
} from '../JobForm/JobForm.constants';

const TextField = styled.input<{ tagStyle?: boolean }>`
  white-space: nowrap;
  ${(props) =>
    !props.tagStyle
      ? props.theme.fonts.sizes.large
      : props.theme.fonts.sizes.largest};
  text-align: start;
  ${(props) => props.theme.fonts.weights.medium}
  border: none;
  font-family: 'Roboto';
  padding: 0;
  color: ${COLORS.neutral[8]};
  cursor: ${(props) => (!props.tagStyle ? 'text' : 'pointer')};
  background-color: transparent;
`;

const JobStatusCardWrapper = memo(
  ({
    id,
    children,
    isHousekeeping,
    isSmall,
    isEscalated,
    currentColor,
    handleView,
    style,
  }: TJobCardWrapper) => {
    const key = `jobs-status-card-${id}`;

    const escalatedStyle = isEscalated
      ? {
          border: `1px solid ${COLORS.red[5]}`,
        }
      : {};

    return isHousekeeping ? (
      <CustomCard
        key={key}
        className={isSmall ? 'small housekeeping-job' : ''}
        style={{
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          ...escalatedStyle,
          ...style,
        }}
        data-testid="job-card"
        onClick={() => {
          handleView?.({ id: id as string });
        }}
      >
        <CustomCardTop
          className="housekeeping-header-color"
          color={currentColor}
        />
        {children}
      </CustomCard>
    ) : (
      <CustomCard
        key={key}
        className={isSmall ? 'small' : ''}
        data-testid="job-card"
        style={{
          ...escalatedStyle,
          ...style,
        }}
        onClick={() => {
          handleView?.({ id: id as string });
        }}
      >
        {children}
      </CustomCard>
    );
  },
);

const JobStatusCardIcons = memo(
  ({
    data,
    canAccess,
    t,
    id: jobId,
    isHousekeeping,
    isCompleted,
  }: TJobCardIcons) => {
    const { canAccessProductPmsData } = canAccess;

    const icons = Object.keys(data)
      ?.reduce((previous: TJobCardIconsKeysComponent[], current) => {
        const next = [...previous];
        const currentComposedIcon = data[current as keyof typeof data];

        if (
          canAccessProductPmsData &&
          currentComposedIcon &&
          typeof currentComposedIcon === 'object' &&
          current === 'reservations' &&
          currentComposedIcon?.length > 0
        ) {
          currentComposedIcon?.forEach((currentComposedIconItem) => {
            const currentReservationsStatusLabel =
              JOB_CARD_ICONS_RESERVATIONS_LABELS[
                currentComposedIconItem as keyof typeof JOB_CARD_ICONS_RESERVATIONS_LABELS
              ];

            if (currentReservationsStatusLabel) {
              const reservationsStatusIcon = {
                name: currentComposedIconItem,
                label: t(`jobs:${currentReservationsStatusLabel}`),
                component:
                  JOB_CARD_RESERVATIONS_ICONS[
                    currentComposedIconItem as keyof typeof JOB_CARD_RESERVATIONS_ICONS
                  ],
              };

              if (current && reservationsStatusIcon) {
                next.push(reservationsStatusIcon);
              }
            }
          });
        }

        if (
          canAccessProductPmsData &&
          currentComposedIcon &&
          typeof currentComposedIcon === 'object' &&
          current === 'cleaningStatus' &&
          currentComposedIcon?.length > 0
        ) {
          currentComposedIcon?.forEach((currentComposedIconItem) => {
            const currentCleaningStatusLabel =
              JOB_CARD_ICONS_CLEANING_STATUS_LABELS[
                currentComposedIconItem as keyof typeof JOB_CARD_ICONS_CLEANING_STATUS_LABELS
              ];

            if (currentCleaningStatusLabel) {
              const cleaningStatusIcon = {
                name: current,
                label: t(`jobs:${currentCleaningStatusLabel}`),
                component:
                  JOB_CARD_CLEANING_STATUS_ICONS[
                    currentComposedIconItem as keyof typeof JOB_CARD_CLEANING_STATUS_ICONS
                  ],
              };

              if (current && cleaningStatusIcon) {
                next.push(cleaningStatusIcon);
              }
            }
          });
        }

        const currentLabel =
          JOB_CARD_ICONS_LABELS[current as keyof typeof JOB_CARD_ICONS_LABELS];
        const currentPriorityLabel =
          JOB_CARD_ICONS_PRIORITY_LABELS[
            currentComposedIcon as keyof typeof JOB_CARD_ICONS_PRIORITY_LABELS
          ];
        const sourceLabel =
          JOB_CARD_JOB_TYPE_ICONS_LABELS[
            currentComposedIcon as keyof typeof JOB_CARD_JOB_TYPE_ICONS_LABELS
          ];
        const currentIcon = {
          name: current,
          label: t(`jobs:${currentLabel}`),
          component: JOB_CARD_ICONS[current as keyof typeof JOB_CARD_ICONS],
        };
        const priorityIcon = {
          name: current,
          label: t(`jobs:${currentPriorityLabel}`),
          component: isCompleted
            ? DONE_JOB_CARD_PRIORITY_ICONS[
                currentComposedIcon as keyof typeof DONE_JOB_CARD_PRIORITY_ICONS
              ]
            : JOB_CARD_PRIORITY_ICONS[
                currentComposedIcon as keyof typeof JOB_CARD_PRIORITY_ICONS
              ],
        };
        const sourceIcon = {
          name: current,
          label: t(`jobs:${sourceLabel}`),
          component:
            JOB_CARD_JOB_TYPE_ICONS[
              currentComposedIcon as keyof typeof JOB_CARD_JOB_TYPE_ICONS
            ],
        };

        if (current === 'rush' && data[current] === true) {
          next.push(currentIcon);
        }

        if (current === 'priority' && priorityIcon && !isHousekeeping) {
          next.push(priorityIcon);
        }

        if (current === 'source' && sourceIcon) {
          next.push(sourceIcon);
        }

        /* TODO: Icons rules for checklists (repeating and hk jobs) */
        if (current === 'checklists' && data[current]?.length) {
          next.push(currentIcon);
        }

        if (current === 'attachments' && data[current]?.length) {
          next.push(currentIcon);
        }

        return next;
      }, [])
      .map((icon, i) => {
        const Component =
          icon?.component &&
          icon?.component({ style: { pointerEvents: 'none' } });

        return (
          icon?.component && (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={`job-details-card-icon-${jobId}-${icon.name}-${i}`}
              data-testid="priority-level"
              title={t(`jobs:${icon.label}`)}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {Component}
            </span>
          )
        );
      })
      .filter((iconItem) => !!iconItem);

    return icons.length ? icons : <span />;
  },
);

const JobStatusCardEditButton = memo(
  ({ data, handleClick }: TJobCardEditButton) => {
    const { t } = useTranslation(['common', 'jobs']);
    if (!data?.id || !handleClick || typeof handleClick !== 'function') {
      return null;
    }

    return (
      <Tooltip title={t('jobs:Edit job')} arrow={false}>
        <Button
          style={JOB_CARD_STYLES.toggleButton}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClick({ id: data.id });
          }}
        >
          {JOB_CARD_ICONS.editButton({ style: { pointerEvents: 'none' } })}
        </Button>
      </Tooltip>
    );
  },
);

const JobStatusCardDeleteButton = memo(
  ({ data, handleClick }: TJobCardDeleteButton) => {
    const { t } = useTranslation(['common', 'jobs']);
    if (!data?.id || !handleClick || typeof handleClick !== 'function') {
      return null;
    }

    return (
      <Tooltip title={t('jobs:Delete job')} arrow={false}>
        <Button
          style={JOB_CARD_STYLES.toggleButton}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClick({ id: data.id });
          }}
        >
          {JOB_CARD_ICONS.deleteButton({ style: { pointerEvents: 'none' } })}
        </Button>
      </Tooltip>
    );
  },
);

const JobStatusCardStatus = memo(
  ({ data, icon, t, isSmall }: TJobCardStatus) => {
    const status = useMemo(
      () => icon && getJobStatusCardStatus({ status: icon as JobStatus, t }),
      [icon, t],
    );

    return (
      data && (
        <JobCardStatusContainer
          className={status ? status.key : ''}
          data-testid="job-status"
          isSmall={isSmall}
        >
          {status &&
            JOB_CARD_STATUSES_ICONS[
              status.key as keyof typeof JOB_CARD_STATUSES_ICONS
            ]?.()}
          <Typography.Text>{data}</Typography.Text>
        </JobCardStatusContainer>
      )
    );
  },
);

const JobStatusCardToggleButton = memo(
  ({ onClick }: { onClick: () => void }) => (
    <Button onClick={onClick} style={JOB_CARD_STYLES.toggleButton}>
      {JOB_CARD_ICONS.toggleButton({ style: { pointerEvents: 'none' } })}
    </Button>
  ),
);

const JobStatusCardHeader = memo(
  ({
    data,
    t,
    id,
    isSmall,
    handleEdit,
    handleDelete,
    canAccess,
    isHousekeeping,
    showStatus,
    showToggleButton,
    showActionButtons,
    setShowActionButtons,
  }: TJobCardHeader) => {
    const { status } = data;

    const isCompleted =
      status === JOB_STATUSES.completed.value ||
      status === JOB_ADVANCED_STATUSES.completed?.value ||
      status === JOB_STATUSES.cancelled.value;

    const {
      canProductEdit,
      canProductDelete,
      canAddEditHousekeepingJobs,
      canDeleteHousekeepingJobs,
    } = canAccess;

    const editButtonProps = {
      data: {
        id,
      },
      handleClick: handleEdit,
    };

    const deleteButtonProps = {
      data: {
        id,
      },
      handleClick: handleDelete,
    };

    const EditButton =
      (canProductEdit && !isHousekeeping) ||
      (isHousekeeping && canAddEditHousekeepingJobs) ? (
        <JobStatusCardEditButton {...editButtonProps} />
      ) : null;
    const DeleteButton =
      ((canProductDelete && !isHousekeeping) ||
        (isHousekeeping && canDeleteHousekeepingJobs)) &&
      (status === JOB_STATUSES.notStarted.value ||
        status === JOB_ADVANCED_STATUSES.new?.value ||
        status === JOB_ADVANCED_STATUSES.pending?.value) ? (
        <JobStatusCardDeleteButton {...deleteButtonProps} />
      ) : null;

    const ToggleActionButton = showActionButtons ? (
      <Flex
        style={{
          gap: SPACING.SIZE_DEFAULT,
        }}
      >
        {EditButton}
        {DeleteButton}
      </Flex>
    ) : (
      <JobStatusCardToggleButton onClick={() => setShowActionButtons(true)} />
    );

    const ActionButtons = showToggleButton ? (
      ToggleActionButton
    ) : (
      <Flex
        style={{
          gap: SPACING.SIZE_DEFAULT,
        }}
      >
        {EditButton}
        {DeleteButton}
      </Flex>
    );

    return isSmall ? (
      <Flex style={JOB_CARD_STYLES.icons} className="job-status-card-header">
        <Flex
          style={{
            flex: 1,
            gap: SPACING.SIZE_DEFAULT,
          }}
          className="job-status-card-header-icons"
        >
          {data ? (
            <JobStatusCardIcons
              t={t}
              data={data}
              canAccess={canAccess}
              id={id}
              isHousekeeping={isHousekeeping}
              isCompleted={isCompleted}
            />
          ) : (
            ''
          )}
        </Flex>

        {showStatus && (
          <JobStatusCardStatus
            t={t}
            icon={data?.status}
            data={data?.statusLabel}
            isSmall={isSmall}
          />
        )}

        {ActionButtons}
      </Flex>
    ) : (
      <Flex style={JOB_CARD_STYLES.icons} className="job-status-card-header">
        <Flex
          style={{
            flex: 1,
            gap: SPACING.SIZE_DEFAULT,
          }}
          className="job-status-card-header-icons"
        >
          <JobStatusCardIcons
            t={t}
            data={data}
            canAccess={canAccess}
            id={id}
            isHousekeeping={isHousekeeping}
          />
        </Flex>

        <Flex
          style={{
            gap: SPACING.SIZE_DEFAULT,
          }}
        >
          <JobStatusCardStatus
            t={t}
            icon={data?.status}
            data={data?.statusLabel}
          />
        </Flex>
      </Flex>
    );
  },
);

const JobStatusCardTitle = memo(
  ({ children, handleClick, data }: TJobCardRowProps) => (
    <Row data-testid="job-description">
      {data?.id && handleClick && typeof handleClick === 'function' ? (
        <Typography.Title
          onClick={() =>
            handleClick({
              id: data.id,
            })
          }
          level={4}
          style={JOB_CARD_STYLES.titleLink}
        >
          {children}
        </Typography.Title>
      ) : (
        <Typography.Title level={4} style={JOB_CARD_STYLES.title}>
          {children}
        </Typography.Title>
      )}
    </Row>
  ),
);

const JobStatusCardLocations = memo(
  ({
    data,
    roomsData,
    showAll,
    setShowAll,
    handleLocation,
    canAccess,
    generateInPropertyTime,
  }: TJobCardLocations) => {
    const displayedTags =
      !showAll && data?.length ? [...data].slice(0, 2) : data;
    const showMoreButton = !!(data?.length > displayedTags?.length);
    const exceedingTagsQty = data && data.length - displayedTags.length;

    const { canAccessLocationDetails, canAccessProductPmsData } = canAccess;

    return (
      <Row data-testid="card-location" style={JOB_CARD_STYLES.locations}>
        {displayedTags
          ?.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id),
          )
          ?.map(({ id, displayName }, index) => {
            const roomStatus = roomsData?.length
              ? roomsData?.find((room) => room?.id === id)
              : roomsData;
            const roomStatusLabel =
              canAccessProductPmsData &&
              roomStatus &&
              getRoomStatus({
                room: roomStatus as RoomSummary,
                generateInPropertyTime,
              });
            const vipStatus =
              canAccessProductPmsData &&
              roomStatus &&
              (getRoomReservationVIPStatus({
                room: roomStatus as RoomSummary,
              }) as string);

            return (
              <>
                <CustomTag
                  // eslint-disable-next-line react/no-array`-`index-key
                  key={`job-details-card-locations-${id || displayName}-${index}`}
                  className={
                    !canAccessLocationDetails ||
                    !(typeof handleLocation === 'function')
                      ? 'disabled'
                      : ''
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (
                      typeof handleLocation === 'function' &&
                      canAccessLocationDetails
                    ) {
                      handleLocation({
                        location: id || '',
                      });
                    }
                  }}
                >
                  <TextField
                    value={displayName || ''}
                    type="button"
                    disabled={
                      !canAccessLocationDetails ||
                      !(typeof handleLocation === 'function')
                    }
                    tagStyle
                  />
                  {roomsData && roomStatusLabel && (
                    <span
                      style={{
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '1px',
                        borderLeftColor: COLORS.neutral[5],
                        paddingLeft: SPACING.SIZE_XS,
                        marginLeft: SPACING.SIZE_XS,
                        color: COLORS.neutral[8],
                      }}
                    >
                      {/* <TextField value={roomStatusLabel} /> */}
                      {roomStatusLabel}
                    </span>
                  )}
                </CustomTag>
                {vipStatus && <CustomTag color="orange">{vipStatus}</CustomTag>}
              </>
            );
          })}
        {showMoreButton && !showAll ? (
          <Typography.Link
            onClick={() => {
              setShowAll(true);
            }}
          >{`+${exceedingTagsQty}`}</Typography.Link>
        ) : null}
      </Row>
    );
  },
);

const JobStatusCardNotes = memo(({ children }: TJobCardRowProps) =>
  children ? (
    <Row data-testid="latest-note">
      <Typography.Paragraph style={JOB_CARD_STYLES.notes}>
        {children}
      </Typography.Paragraph>
    </Row>
  ) : null,
);

const JobStatusCardChecklists = memo(({ data, t }: TJobCardChecklists) => {
  const {
    done = 0,
    total = 0,
    requiredTotal = 0,
    requiredDone = 0,
  } = data || {};

  if (!total) {
    return null;
  }

  return (
    <Row data-testid="card-checklist-counter">
      <Typography.Paragraph style={JOB_CARD_STYLES.checklists}>
        {JOB_CARD_ICONS.checklists({ style: { pointerEvents: 'none' } })}
        <Typography.Text style={JOB_CARD_STYLES.checklistsCompleted}>
          {t('checklist: Tasks Completed')}
        </Typography.Text>
        <Typography.Text>
          {t('checklist: {{totalCompleted}}/{{total}} Total', {
            totalCompleted: done,
            total,
          })}
        </Typography.Text>
        {requiredTotal ? (
          <Typography.Text style={JOB_CARD_STYLES.checklistsRequired}>
            {t('checklist: {{totalCompleted}}/{{total}} Required', {
              totalCompleted: requiredDone,
              total: requiredTotal,
            })}
          </Typography.Text>
        ) : null}
      </Typography.Paragraph>
    </Row>
  );
});

const JobStatusCardAsset = memo(({ data, t, handleAsset }: TJobCardAsset) => {
  const displayName = data?.displayName;

  return (
    displayName && (
      <Row>
        <Typography.Paragraph
          style={JOB_CARD_STYLES.asset}
          data-testid="job-asset"
        >
          {JOB_CARD_ICONS.asset({ style: { pointerEvents: 'none' } })}
          <Typography.Text style={JOB_CARD_STYLES.assetLabel}>
            {t('assets:Asset')}
          </Typography.Text>
          <Typography.Link
            style={JOB_CARD_STYLES.assetLink}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (
                handleAsset &&
                typeof handleAsset === 'function' &&
                data?.id
              ) {
                handleAsset({ asset: data.id });
              }
            }}
          >
            {displayName}
          </Typography.Link>
        </Typography.Paragraph>
      </Row>
    )
  );
});

const JobStatusCardTimeAndCredits = memo(
  ({ data, t, isSmall }: TJobCardTime) => {
    const { month, credits, timeWindowStart, timeWindowEnd } = data;
    const label = 'Time';
    const hasTime = month && timeWindowStart && timeWindowEnd;

    return (
      <Row
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Col data-testid="housekeeping-time-window">
          {hasTime && (
            <Typography.Paragraph
              style={isSmall ? JOB_CARD_STYLES.smallTime : JOB_CARD_STYLES.time}
            >
              <Typography.Text
                style={
                  isSmall
                    ? JOB_CARD_STYLES.smallTimeLabel
                    : JOB_CARD_STYLES.timeLabel
                }
              >
                {t(`jobs:${label}`)}:
              </Typography.Text>
              <span>
                {month} {timeWindowStart} {JOB_CARD_ICONS.timeSeparator()}{' '}
                {timeWindowEnd}
              </span>
            </Typography.Paragraph>
          )}
        </Col>
        {!isSmall && credits && (
          <Col data-testid="housekeeping-credits">
            <Typography.Paragraph style={JOB_CARD_STYLES.time}>
              {credits} {t('jobs:credits')}
            </Typography.Paragraph>
          </Col>
        )}
      </Row>
    );
  },
);

const JobStatusCardDueBy = memo(({ data, t }: TJobCardTime) => {
  if (!data) {
    return null;
  }

  const { month, timestamp, type, isOverdue } = data;

  const label = type === 'done' ? 'jobs:Done' : 'common:Due by';

  return (
    <Row>
      <Typography.Paragraph
        style={isOverdue ? JOB_CARD_STYLES.overDueTime : JOB_CARD_STYLES.time}
        data-testid="due-by-time"
      >
        <Typography.Text style={JOB_CARD_STYLES.timeLabel}>
          {t(`${label}`)}:
        </Typography.Text>
        {month} {timestamp}
      </Typography.Paragraph>
    </Row>
  );
});

const JobStatusCardStartTime = memo(({ data, t }: TJobCardStartTime) => {
  if (!data) {
    return null;
  }

  const { month, timestamp } = data;

  if (!month || !timestamp) {
    return null;
  }

  return (
    <Row data-testid="start-time">
      <Typography.Paragraph style={JOB_CARD_STYLES.time}>
        <Typography.Text style={JOB_CARD_STYLES.timeLabel}>
          {t('jobs:Start Time')}:
        </Typography.Text>
        {month} {timestamp}
      </Typography.Paragraph>
    </Row>
  );
});

const JobStatusCardAssignmentAndRoomType = memo(
  ({ data, isSmall, t }: TJobCardAssignment) => {
    if (!data) {
      return null;
    }

    const { department, assignee, roomType, roomTypeCode } = data;
    const departmentCode = department?.code;
    const departmentDisplayName = department?.displayName;
    const assigneeDisplayName = assignee?.displayName;
    const ROOM_TYPE = 'Room';

    return (
      <Row
        style={{
          justifyContent: 'space-between',
        }}
      >
        {(department || assignee) && (
          <Col>
            <Typography.Paragraph
              style={JOB_CARD_STYLES.assignment}
              data-testid="job-assigned-to"
            >
              <Typography.Text style={JOB_CARD_STYLES.assignmentDepartment}>
                <TextField
                  type="button"
                  value={departmentCode || departmentDisplayName}
                />
              </Typography.Text>
              {assignee && (
                <Typography.Text style={JOB_CARD_STYLES.assignmentEmployee}>
                  |
                </Typography.Text>
              )}
              <Typography.Text>{assigneeDisplayName}</Typography.Text>
            </Typography.Paragraph>
          </Col>
        )}
        {roomType && roomType === ROOM_TYPE && (
          <Col data-testid="room-type-code">
            {isSmall && data?.credits && (
              <Typography.Paragraph style={JOB_CARD_STYLES.creditsAndRoomType}>
                {data?.credits} {t('jobs:credits')} {roomTypeCode}
              </Typography.Paragraph>
            )}

            {!isSmall && (
              <Typography.Paragraph style={JOB_CARD_STYLES.roomType}>
                {roomTypeCode}
              </Typography.Paragraph>
            )}
          </Col>
        )}
      </Row>
    );
  },
);

const JobStatusCardServiceCard = memo(
  ({
    id,
    data,
    t,
    showAllLocations,
    setShowAllLocations,
    handleAsset,
    handleLocation,
    handleView,
    generateInPropertyTime,
    canAccess,
    showAssetInfo,
  }: TJobCardServiceCard) => {
    if (!data) {
      return null;
    }

    const action = data?.job?.action;
    const jobName = data?.job?.action
      ? `${getJobStatusCardAction({ action, t })} ${data?.job?.displayName}`
      : data?.job?.displayName;
    const locations = data?.locations?.filter(
      (location) => !!location.id || !!location.displayName,
    );
    const {
      notes,
      asset,
      room,
      time,
      startTime,
      assignment,
      checklistsSummary,
    } = data;

    const roomArray = Array.isArray(room) ? room : [room!];
    const roomsData = Array.isArray(data?.roomsData)
      ? data.roomsData
      : roomArray;

    return (
      <Flex vertical gap={SPACING.SIZE_SM}>
        <JobStatusCardTitle
          handleClick={typeof handleView === 'function' ? handleView : () => {}}
          data={{ id: data?.job?.id }}
        >
          {jobName}
        </JobStatusCardTitle>
        <JobStatusCardLocations
          id={id}
          canAccess={canAccess}
          data={locations || []}
          roomsData={roomsData}
          handleLocation={handleLocation}
          showAll={showAllLocations}
          setShowAll={setShowAllLocations}
          generateInPropertyTime={generateInPropertyTime}
        />
        <JobStatusCardNotes>{notes}</JobStatusCardNotes>
        <JobStatusCardChecklists data={checklistsSummary} t={t} />
        {showAssetInfo && (
          <JobStatusCardAsset data={asset} t={t} handleAsset={handleAsset} />
        )}
        <JobStatusCardDueBy data={time} t={t} />
        <JobStatusCardStartTime data={startTime} t={t} />
        <JobStatusCardAssignmentAndRoomType data={assignment} t={t} />
      </Flex>
    );
  },
);

const JobStatusCardHousekeepingCard = memo(
  ({
    id,
    data,
    t,
    showAllLocations,
    setShowAllLocations,
    handleLocation,
    handleView,
    generateInPropertyTime,
    isSmall,
    canAccess,
  }: TJobCardHKCard) => {
    if (!data) {
      return null;
    }

    const action = data?.job?.action;

    const jobName = data?.job?.action
      ? `${getJobStatusCardAction({ action, t })} ${data?.job.displayName}`
      : data?.job.displayName;
    const locations = data?.locations || [];
    const { notes, time, checklistsSummary, room } = data;

    const assignment = { ...data.assignment, credits: time?.credits };

    const roomArray = Array.isArray(room) ? room : [room!];
    const roomsData = Array.isArray(data?.roomsData)
      ? data.roomsData
      : roomArray;

    return (
      <Flex vertical gap={SPACING.SIZE_SM}>
        <JobStatusCardTitle
          handleClick={typeof handleView === 'function' ? handleView : () => {}}
          data={{ id: data?.job?.id }}
        >
          {jobName}
        </JobStatusCardTitle>
        <JobStatusCardLocations
          id={id}
          data={locations}
          roomsData={roomsData}
          handleLocation={handleLocation}
          showAll={showAllLocations}
          setShowAll={setShowAllLocations}
          generateInPropertyTime={generateInPropertyTime}
          canAccess={canAccess}
        />
        <JobStatusCardNotes>{notes}</JobStatusCardNotes>
        <JobStatusCardChecklists data={checklistsSummary} t={t} />
        <JobStatusCardTimeAndCredits data={time} t={t} isSmall={isSmall} />
        <JobStatusCardAssignmentAndRoomType
          data={assignment}
          isSmall={isSmall}
          t={t}
        />
      </Flex>
    );
  },
);

const JobStatusCardRepeatingCard = memo(
  ({
    id,
    data,
    t,
    showAllLocations,
    setShowAllLocations,
    handleAsset,
    handleLocation,
    generateInPropertyTime,
    canAccess,
  }: TJobCardServiceCard) => {
    if (!data) {
      return null;
    }

    const action = data?.job?.action;
    const roomsData = data?.roomsData;

    const jobName = data?.job?.action
      ? `${getJobStatusCardAction({ action, t })} ${data?.job.displayName}`
      : data?.job.displayName;
    const locations = data?.locations || [];
    const { notes, asset, time, startTime, assignment, checklistsSummary } =
      data;

    return (
      <Flex vertical gap={SPACING.SIZE_SM}>
        <JobStatusCardTitle>{jobName}</JobStatusCardTitle>
        <JobStatusCardLocations
          id={id}
          data={locations}
          roomsData={roomsData}
          handleLocation={handleLocation}
          showAll={showAllLocations}
          setShowAll={setShowAllLocations}
          generateInPropertyTime={generateInPropertyTime}
          canAccess={canAccess}
        />
        <JobStatusCardNotes>{notes}</JobStatusCardNotes>
        <JobStatusCardChecklists data={checklistsSummary} t={t} />
        <JobStatusCardAsset data={asset} t={t} handleAsset={handleAsset} />
        <JobStatusCardDueBy data={time} t={t} />
        <JobStatusCardStartTime data={startTime} t={t} />
        <JobStatusCardAssignmentAndRoomType data={assignment} t={t} />
      </Flex>
    );
  },
);

const JobStatusCardProjectCard = memo(
  ({
    id,
    data,
    t,
    showAllLocations,
    setShowAllLocations,
    handleAsset,
    handleLocation,
    generateInPropertyTime,
    canAccess,
  }: TJobCardProjectCard) => {
    if (!data) {
      return null;
    }

    const action = data?.job?.action;
    const roomsData = data?.roomsData;

    const jobName = data?.job?.action
      ? `${getJobStatusCardAction({ action, t })} ${data?.job.displayName}`
      : data?.job.displayName;
    const locations = data?.locations || [];
    const { notes, asset, time, startTime, assignment, checklistsSummary } =
      data;

    return (
      <Flex vertical gap={SPACING.SIZE_SM}>
        <JobStatusCardTitle>{jobName}</JobStatusCardTitle>
        <JobStatusCardLocations
          id={id}
          data={locations}
          roomsData={roomsData}
          handleLocation={handleLocation}
          showAll={showAllLocations}
          setShowAll={setShowAllLocations}
          generateInPropertyTime={generateInPropertyTime}
          canAccess={canAccess}
        />
        <JobStatusCardNotes>{notes}</JobStatusCardNotes>
        <JobStatusCardChecklists data={checklistsSummary} t={t} />
        <JobStatusCardAsset data={asset} t={t} handleAsset={handleAsset} />
        <JobStatusCardDueBy data={time} t={t} />
        <JobStatusCardStartTime data={startTime} t={t} />
        <JobStatusCardAssignmentAndRoomType data={assignment} t={t} />
      </Flex>
    );
  },
);

const JobStatusCardBody = memo((props: TJobCardBody) => {
  const { isHousekeeping, isProject, isRepeating, isService } = props;

  const source = getJobSource({
    isHousekeeping,
    isProject,
    isRepeating,
    isService,
  });

  const jobSources = {
    service: JobStatusCardServiceCard,
    housekeeping: JobStatusCardHousekeepingCard,
    repeating: JobStatusCardRepeatingCard,
    project: JobStatusCardProjectCard,
  };

  const JobStatusCardSource = jobSources[source as keyof typeof jobSources];

  return <JobStatusCardSource {...props} />;
});

export const JobStatusCard = memo(
  ({
    index,
    data,
    handleAsset,
    handleLocation,
    handleEdit,
    handleDelete,
    handleView,
    isHousekeeping,
    isRepeating,
    isProject,
    isService,
    t,
    property,
    canAccess,
    isSmall,
    updating,
    showStatus,
    isDraggable,
    showToggleButton,
    style,
    showAssetInfo,
  }: TJobCardProps) => {
    const { generateInPropertyTime } = property;
    const [showAllLocations, setShowAllLocations] = useState<boolean>(false);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(false);

    if (!data) {
      return null;
    }

    const { isEscalated } = data;

    const locations = data?.locations || [];

    const {
      checklists,
      rush,
      priority,
      attachments,
      status,
      job,
      roomsData,
      source,
    } = data;

    const currentStatus = status || (job?.status as JobStatus);
    const statusLabel = (currentStatus &&
      getJobStatusCardStatus({
        status: currentStatus,
        t,
      })) || { label: '', key: '' };

    const cleaningStatus = getJobStatusCardCleaningStatus({ roomsData });
    const reservations = getJobStatusCardReservationsIcons({
      roomsData: roomsData as RoomSummary[],
    });

    const headerData: TJobCardIconsData & {
      status?: string;
      statusLabel: string;
    } = {
      rush,
      locations,
      priority,
      source,
      checklists,
      attachments,
      status: status as string,
      statusLabel: statusLabel?.label,
      cleaningStatus,
      reservations,
    };

    const actionColors =
      JOB_CARD_ACTION_COLORS[
        job?.action as keyof typeof JOB_CARD_ACTION_COLORS
      ];
    const currentColor = actionColors
      ? actionColors[statusLabel?.key as keyof typeof actionColors]
      : COLORS.primary[1];
    const id = job?.id;

    let draggableId = `${job.id}`;
    if (String(data?.assignment?.assignee?.id) !== '-88') {
      draggableId = draggableId.concat(`_${data?.assignment?.assignee?.id}`);
    }
    const isDragDisabled =
      updating ||
      (navigator &&
        (navigator as Navigator & { deviceMemory: number })?.deviceMemory &&
        (navigator as Navigator & { deviceMemory: number })?.deviceMemory < 2);

    const innerCard = (
      <ConfigProvider theme={JOB_CARD_THEME}>
        <JobStatusCardWrapper
          id={id}
          isEscalated={isEscalated}
          isHousekeeping={isHousekeeping}
          isSmall={isSmall}
          currentColor={currentColor}
          style={style}
          handleView={handleView}
        >
          <JobStatusCardHeader
            t={t}
            data={headerData}
            id={data?.job?.id}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            canAccess={canAccess}
            isHousekeeping={isHousekeeping}
            isSmall={isSmall}
            showStatus={showStatus}
            showToggleButton={showToggleButton}
            showActionButtons={showActionButtons}
            setShowActionButtons={setShowActionButtons}
          />

          <JobStatusCardBody
            id={id}
            t={t}
            canAccess={canAccess}
            data={data}
            property={property}
            showAllLocations={showAllLocations}
            generateInPropertyTime={generateInPropertyTime}
            setShowAllLocations={setShowAllLocations}
            handleAsset={handleAsset}
            handleLocation={handleLocation}
            handleView={handleView}
            isService={isService}
            isHousekeeping={isHousekeeping}
            isProject={isProject}
            isRepeating={isRepeating}
            isSmall={isSmall}
            showAssetInfo={showAssetInfo}
          />
        </JobStatusCardWrapper>
      </ConfigProvider>
    );

    return isSmall && isDraggable ? (
      <Draggable
        index={index}
        draggableId={draggableId}
        isDragDisabled={isDragDisabled}
      >
        {(provided: any, snapshot: any) => {
          const draggableProps = getCorrectDroppableProps(provided, snapshot);
          return (
            <div
              ref={provided.innerRef}
              {...draggableProps}
              {...provided.dragHandleProps}
            >
              {innerCard}
            </div>
          );
        }}
      </Draggable>
    ) : (
      innerCard
    );
  },
);
