import {
  Card,
  Col,
  FormItem,
  Input,
  Row,
  Select,
  SPACING,
  Typography,
} from '@optii/ui-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CARD_CONTAINER_STYLE,
  FormProps,
  GLOBAL_CARD_STYLES,
} from './constants';

export function GuestPrivacySettingsForm({ form }: Pick<FormProps, 'form'>) {
  const { t } = useTranslation(['common']);
  return (
    <Card
      style={CARD_CONTAINER_STYLE}
      styles={GLOBAL_CARD_STYLES}
      title={t('common:Guest Information Privacy (PII) Settings')}
    >
      <Typography.Text>{t('common:Guest Name Display')}</Typography.Text>

      <Row>
        <Col span={12}>
          <FormItem label={t('common:Title')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={SPACING.SIZE_MD}>
        <Col span={12}>
          <FormItem label={t('common:First Name')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={t('common:Last Name')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
      </Row>

      <Typography.Text>{t('common:Guest Address Display')}</Typography.Text>

      <Row gutter={SPACING.SIZE_MD}>
        <Col span={12}>
          <FormItem label={t('common:Address')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={t('common:City')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={SPACING.SIZE_MD}>
        <Col span={12}>
          <FormItem label={t('common:State')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label={t('common:Postal Code')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormItem label={t('common:Country')}>
            <Select placeholder={t('common:Select...')} />
          </FormItem>
        </Col>
      </Row>

      <Typography.Text>{t('common:Contact Details')}</Typography.Text>

      <Row>
        <Col span={12}>
          <FormItem
            label={t('common:Email Address')}
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input placeholder={t('common:Email')} type="email" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={SPACING.SIZE_MD}>
        <Col span={12}>
          <FormItem
            label={t('common:Phone Number')}
            rules={[
              {
                type: 'number',
              },
            ]}
          >
            <Input placeholder={t('common:Phone Number')} />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label={t('common:Mobile Number')}
            rules={[
              {
                type: 'number',
              },
            ]}
          >
            <Input placeholder={t('common:Mobile Number')} />
          </FormItem>
        </Col>
      </Row>
    </Card>
  );
}
