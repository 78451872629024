import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Drawer,
  HeaderActions,
  Row,
  SPACING,
  Typography,
} from '@optii-solutions/ui-library';

import { Offline } from '@optii/ui-library';
import { GetJobByIdQuery } from '@optii/jobs/api/jobs';
import { PERMISSIONS } from '@optii/shared';
import { PRODUCT_ACCESS } from '@optii/shared/constants/productAccess';
import { getEnvironment } from '@optii/shared/utils/config';
import { JOB_STATUS } from '@optii/shared/index';
import { JobStatusCard } from '../JobStatusCard';

import {
  CancelJobConfirmation,
  JobDetailsCustomStyles,
} from './JobDetails.elements';
import PassWithFailConfirmation from '../PassAndFail/PassWithFailConfirmation';
import { JobDetailsSkeleton } from './JobDetails.skeleton';
import { JobDetailsAttachments } from './JobDetailsAttachments';
import { JobDetailsTabs } from './JobDetailsTabs';
import type {
  LegacyLog,
  TJobDetailsAttachmentsData,
  TJobDetailsTabsData,
} from './JobDetails.types';
import { useJobDetails } from './JobDetails.hooks';
import {
  transformAttachmentsData,
  transformTabsData,
} from './JobDetails.helpers';
import { JobDetailsFulfillButtons } from './JobDetailsFulfillButtons';
import { JobDetailsReservationDetails } from './JobDetailsReservationDetails';
import { AddCorrectionModal } from '../PassAndFail/AddCorrectionModal';

const FLAG = ['DEV', 'LOCAL'].includes(getEnvironment());

type TJobDetailsProps = {
  open: boolean;
  disableBackdrop?: boolean;
  onClose: (
    e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void;
  legacyLogs: LegacyLog[];
};

export function JobDetailsComponent(props: TJobDetailsProps) {
  const { open, disableBackdrop, onClose, legacyLogs } = props;

  const { t } = useTranslation(['assets', 'common', 'jobs'], {
    useSuspense: false,
  });

  const customStyles = JobDetailsCustomStyles();

  const {
    loading,
    isChecklistIncomplete,
    job: contextJob,
    audit,
    cardData,
    roomsData,
    handleEdit,
    handleCancelJob,
    handleTakeJob,
    handleTakeAndStartJob,
    handleStartJob,
    handlePauseJob,
    handleCompleteJob,
    handleReopenJob,
    handleLocation,
    handleAsset,
    handleRemoveFile,
    handleAddFile,
    handleCompletePassJob,
    handleCompleteFailJob,
    jobRefetch,
    failedTasksQty,
    showPassWithFailedConfirmation,
    setShowPassWithFailedConfirmation,
    updateChecklistTask: [updateChecklistTask],
    showCancelJobConfirmation,
    setShowCancelJobConfirmation,
    isHousekeeping,
    isRepeating,
    isProject,
    isService,
    canEdit,
    timezone,
    generateInPropertyTime,
    can,
    canProduct,
    showFailConfirmation,
    setShowFailConfirmation,
    getFailedTasksQty,
    setActiveTab,
    activeTab,
  } = useJobDetails({
    onClose,
  });

  const canAccessLocationDetails = can(
    PERMISSIONS.settings.floorplan.locationDetailView,
  );
  const canChangeHkJob =
    can(PERMISSIONS.house_keeping.add_edit) ||
    can(PERMISSIONS.house_keeping.delete);
  const canAccessProductPmsData = canProduct([PRODUCT_ACCESS.pms_Data]);

  const job = contextJob as GetJobByIdQuery['job'];

  const isQueuedJob = job.status === JOB_STATUS.queued;

  const isEscalated = !!job?.isEscalated;

  const displayReservations =
    job &&
    job?.locations?.length === 1 &&
    canProduct([PRODUCT_ACCESS.pms_Data]);
  const reservationsData =
    roomsData && roomsData[0] ? roomsData[0].reservations : [];

  const attachmentsData: TJobDetailsAttachmentsData | null = job
    ? transformAttachmentsData({ job })
    : null;

  const tabsData: TJobDetailsTabsData = transformTabsData({ job, audit });

  return (
    <Drawer
      destroyOnClose
      width={675}
      mask={!disableBackdrop}
      open={open}
      onClose={onClose}
      data-testid="job-modal"
      id="job-details-drawer"
      onClick={(e) => {
        if (disableBackdrop) {
          e.stopPropagation();
        }
      }}
      title={
        <>
          {FLAG && (
            <Offline
              style={{
                margin: '-16px 0 0 -24px',
                width: 'calc(100% + 47px)',
              }}
            />
          )}
          <Row align="middle" justify="space-between" wrap={false}>
            <Typography.Title
              level={3}
              style={{
                marginTop: SPACING.NONE,
                fontFamily: 'Montserrat',
              }}
            >
              {job?.id ? `${t('jobs:Job')}: #${job?.id}` : ''}
            </Typography.Title>
            <HeaderActions
              onEdit={canEdit && !isQueuedJob ? handleEdit : undefined}
              onClose={(e) => onClose(e)}
              responsive
            />
          </Row>
        </>
      }
      footer={
        <Row
          align="middle"
          justify="end"
          wrap={false}
          gutter={SPACING.SIZE_MS}
          style={{
            paddingBlock: SPACING.SIZE_DEFAULT,
          }}
          data-testid="fulfill-buttons"
        >
          <JobDetailsFulfillButtons
            job={job}
            setShowCancelJobConfirmation={setShowCancelJobConfirmation}
            handleTakeJob={handleTakeJob}
            handleStartJob={handleStartJob}
            handleTakeAndStartJob={handleTakeAndStartJob}
            handlePauseJob={handlePauseJob}
            handleCompleteJob={handleCompleteJob}
            handleReopenJob={handleReopenJob}
            isChecklistIncomplete={isChecklistIncomplete}
            handleCompleteFailJob={() => setShowFailConfirmation(true)}
            handleCompletePassJob={handleCompletePassJob}
            canChangeHkJob={canChangeHkJob}
            t={t}
          />
        </Row>
      }
      styles={{
        body: customStyles.drawerBody,
      }}
    >
      {loading ? (
        <JobDetailsSkeleton />
      ) : (
        <div
          data-testid="view-job"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: SPACING.SIZE_MS,
            flex: 1,
          }}
        >
          <JobStatusCard
            t={t}
            data={cardData}
            handleAsset={handleAsset}
            handleLocation={handleLocation}
            isService={isService}
            isHousekeeping={isHousekeeping}
            isRepeating={isRepeating}
            isProject={isProject}
            isEscalated={isEscalated}
            canAccess={{
              canAccessLocationDetails,
              canAccessProductPmsData,
            }}
            property={{
              timezone,
              generateInPropertyTime,
            }}
            showAssetInfo
          />

          {displayReservations && (
            <JobDetailsReservationDetails t={t} data={reservationsData} />
          )}

          <JobDetailsAttachments
            data={attachmentsData}
            handleRemoveFile={handleRemoveFile}
            handleAddFile={handleAddFile}
            isAddEnabled={isQueuedJob}
          />

          <JobDetailsTabs
            t={t}
            isQueuedJob={isQueuedJob}
            data={tabsData}
            jobRefetch={jobRefetch}
            legacyLogs={legacyLogs}
            updateChecklistTask={updateChecklistTask}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />

          {showCancelJobConfirmation && (
            <CancelJobConfirmation
              hide={() => {
                setShowCancelJobConfirmation(false);
              }}
              onCancelJob={async () => {
                await handleCancelJob({
                  job: job as GetJobByIdQuery['job'],
                });
              }}
              values={job as GetJobByIdQuery['job']}
              t={t}
            />
          )}

          {showPassWithFailedConfirmation && (
            <PassWithFailConfirmation
              hide={() => {
                setShowPassWithFailedConfirmation(false);
              }}
              onCompleteJob={async () => {
                await handleCompletePassJob(true);
              }}
              t={t}
              qty={failedTasksQty}
            />
          )}

          {showFailConfirmation && (
            <AddCorrectionModal
              handleCompleteFailJob={handleCompleteFailJob}
              getFailedTasksQuantity={getFailedTasksQty}
              job={job}
              onClose={() => {
                setShowFailConfirmation(false);
              }}
            />
          )}
        </div>
      )}
    </Drawer>
  );
}

export const JobDetails = memo(JobDetailsComponent);
