import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  alignLeft: () => `
    align-items: flex-start;
  `,
  long: () => `
    width: 50rem;
    & > div {
      width: 100%;
    }
  `,
  mediumMargin: () => `
    margin-bottom: 3rem;
  `,
  smallMargin: () => `
    margin-bottom: 1.5rem;
  `,
  noMargin: () => `
    margin-bottom: 0;
  `,
  relative: (_) => `
    position: relative;
  `,
};

const Section = styled.div`
  width: 42rem;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-bottom: 5rem;
  & fieldset {
    border: none;
    padding: 0;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Section;
