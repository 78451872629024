export const RushInactive = () => (
  <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M11.4185 20.4999V14.9185L9.46505 13.0581L8.53482 17.1511L2.11621 15.8488L2.4883 13.9883L6.95342 14.9185L8.44179 7.38366L6.76737 8.03482V11.1976H4.90691V6.82552L8.58133 5.24412C9.12396 5.01157 9.52319 4.8604 9.779 4.79064C10.0348 4.72087 10.2712 4.68599 10.4883 4.68599C10.8139 4.68599 11.1162 4.77126 11.3953 4.9418C11.6744 5.11234 11.8992 5.33715 12.0697 5.61622L12.9999 7.10459C13.403 7.75575 13.9495 8.29064 14.6395 8.70924C15.3294 9.12785 16.1162 9.33715 16.9999 9.33715V11.1976C15.9767 11.1976 15.0193 10.9844 14.1278 10.5581C13.2364 10.1317 12.4883 9.56971 11.8837 8.87203L11.3255 11.6627L13.279 13.5232V20.4999H11.4185ZM11.8837 4.22087C11.372 4.22087 10.934 4.0387 10.5697 3.67436C10.2054 3.31002 10.0232 2.87203 10.0232 2.3604C10.0232 1.84878 10.2054 1.41079 10.5697 1.04645C10.934 0.68211 11.372 0.499939 11.8837 0.499939C12.3953 0.499939 12.8333 0.68211 13.1976 1.04645C13.5619 1.41079 13.7441 1.84878 13.7441 2.3604C13.7441 2.87203 13.5619 3.31002 13.1976 3.67436C12.8333 4.0387 12.3953 4.22087 11.8837 4.22087Z"
      fill="#BFBFBF"
    />
  </svg>
);

export const RushActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M11.4185 20.4999V14.9185L9.46505 13.0581L8.53482 17.1511L2.11621 15.8488L2.4883 13.9883L6.95342 14.9185L8.44179 7.38366L6.76737 8.03482V11.1976H4.90691V6.82552L8.58133 5.24412C9.12396 5.01157 9.52319 4.8604 9.779 4.79064C10.0348 4.72087 10.2712 4.68599 10.4883 4.68599C10.8139 4.68599 11.1162 4.77126 11.3953 4.9418C11.6744 5.11234 11.8992 5.33715 12.0697 5.61622L12.9999 7.10459C13.403 7.75575 13.9495 8.29064 14.6395 8.70924C15.3294 9.12785 16.1162 9.33715 16.9999 9.33715V11.1976C15.9767 11.1976 15.0193 10.9844 14.1278 10.5581C13.2364 10.1317 12.4883 9.56971 11.8837 8.87203L11.3255 11.6627L13.279 13.5232V20.4999H11.4185ZM11.8837 4.22087C11.372 4.22087 10.934 4.0387 10.5697 3.67436C10.2054 3.31002 10.0232 2.87203 10.0232 2.3604C10.0232 1.84878 10.2054 1.41079 10.5697 1.04645C10.934 0.68211 11.372 0.499939 11.8837 0.499939C12.3953 0.499939 12.8333 0.68211 13.1976 1.04645C13.5619 1.41079 13.7441 1.84878 13.7441 2.3604C13.7441 2.87203 13.5619 3.31002 13.1976 3.67436C12.8333 4.0387 12.3953 4.22087 11.8837 4.22087Z"
      fill="#CF1322"
    />
  </svg>
);

export const Priority = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="2" fill="#F2722C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99783 11.0877C8.61148 11.0877 9.10894 11.5904 9.10894 12.2105C9.10894 12.8306 8.61148 13.3333 7.99783 13.3333C7.38418 13.3333 6.88672 12.8306 6.88672 12.2105C6.88672 11.5904 7.38418 11.0877 7.99783 11.0877ZM8.70292 2.66666C8.88887 2.66666 9.03962 2.81137 9.03962 2.98989C9.03962 2.99701 9.03937 3.00413 9.03888 3.01124L8.56789 9.84103C8.55618 10.0109 8.40925 10.1429 8.23193 10.1429H7.76517C7.58787 10.1429 7.44095 10.0109 7.4292 9.84109L6.95685 3.0113C6.94453 2.83318 7.08496 2.67919 7.27051 2.66737L8.70292 2.66666Z"
      fill="white"
    />
  </svg>
);

export const Recurring = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.00021" cy="8.00006" r="2.43478" fill="#FADB14" />
    <path
      d="M1.3913 8.00007C1.38918 5.59065 2.69965 3.37137 4.81051 2.20965C6.92137 1.04793 9.49743 1.12825 11.5318 2.4192L10.4783 3.31277C10.38 3.39588 10.3357 3.52642 10.363 3.65215C10.3904 3.77788 10.4848 3.87826 10.6087 3.91312L14.3885 4.96633C14.4188 4.97496 14.4502 4.97929 14.4817 4.9792C14.6738 4.9792 14.8296 4.82348 14.8296 4.63138C14.8295 4.61684 14.8286 4.60232 14.8268 4.5879L14.3443 0.738507C14.3285 0.611692 14.2444 0.503774 14.1253 0.457439C14.0062 0.411105 13.8713 0.433818 13.7739 0.516594L12.6372 1.48112C10.1976 -0.254308 6.99315 -0.48229 4.3324 0.890265C1.67166 2.26282 -1.66273e-05 5.00617 0 8.00007C5.725e-09 8.38427 0.311454 8.69572 0.695652 8.69572C1.07985 8.69572 1.3913 8.38427 1.3913 8.00007Z"
      fill="#158EB6"
    />
    <path
      d="M15.3042 7.30441C14.92 7.30441 14.6085 7.61587 14.6085 8.00007C14.6108 10.4095 13.3004 12.6288 11.1896 13.7906C9.07875 14.9523 6.50269 14.872 4.46835 13.5809L5.52157 12.6874C5.61979 12.6043 5.66411 12.4737 5.6368 12.348C5.60948 12.2223 5.51499 12.1219 5.39114 12.087L1.60992 11.0338C1.4978 11.0026 1.37753 11.0296 1.28945 11.1057C1.20138 11.1817 1.15717 11.2968 1.17166 11.4122L1.65548 15.2616C1.6714 15.3883 1.75544 15.4961 1.87442 15.5424C1.9934 15.5887 2.1282 15.5661 2.22557 15.4835L3.36261 14.519C5.80224 16.2544 9.00668 16.4824 11.6674 15.1099C14.3282 13.7373 15.9998 10.994 15.9998 8.00007C15.9998 7.61587 15.6884 7.30441 15.3042 7.30441Z"
      fill="#158EB6"
    />
  </svg>
);

export const ImagesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2.49994C0.5 1.67151 1.17157 0.999939 2 0.999939H18C18.8284 0.999939 19.5 1.67151 19.5 2.49994V18.4999C19.5 19.3284 18.8284 19.9999 18 19.9999H2C1.17157 19.9999 0.5 19.3284 0.5 18.4999V2.49994Z"
      fill="white"
    />
    <path
      d="M0.5 2.49994C0.5 1.67151 1.17157 0.999939 2 0.999939H18C18.8284 0.999939 19.5 1.67151 19.5 2.49994V18.4999C19.5 19.3284 18.8284 19.9999 18 19.9999H2C1.17157 19.9999 0.5 19.3284 0.5 18.4999V2.49994Z"
      stroke="#BFBFBF"
    />
    <path
      d="M8.75153 8.62494C9.61447 8.62494 10.314 7.92538 10.314 7.06244C10.314 6.19949 9.61447 5.49994 8.75153 5.49994C7.88858 5.49994 7.18903 6.19949 7.18903 7.06244C7.18903 7.92538 7.88858 8.62494 8.75153 8.62494Z"
      fill="#FADB14"
    />
    <path
      d="M12.3951 14.8542L7.80746 9.8361C7.75643 9.77945 7.69511 9.73515 7.62747 9.70606C7.55982 9.67697 7.48736 9.66375 7.41476 9.66723C7.34238 9.67032 7.27136 9.68972 7.20614 9.72423C7.14092 9.75874 7.08291 9.80761 7.03574 9.8678L3.87728 13.9021C3.81157 13.9858 3.76917 14.0883 3.75513 14.1976C3.74109 14.3068 3.756 14.4182 3.79807 14.5185C3.84015 14.6187 3.90762 14.7037 3.99249 14.7632C4.07736 14.8227 4.17604 14.8543 4.27682 14.8542H12.3951Z"
      fill="#158EB6"
    />
    <path
      d="M16.1524 13.9843L12.0548 7.81585C12.0153 7.75595 11.9654 7.70517 11.9079 7.66644C11.8505 7.62771 11.7866 7.60179 11.72 7.59017C11.6533 7.57855 11.5853 7.58146 11.5197 7.59873C11.4541 7.616 11.3922 7.64729 11.3377 7.6908C11.299 7.72282 11.2645 7.76056 11.2353 7.80296L6.20697 14.8749H15.738C15.8325 14.8749 15.9252 14.8463 16.0057 14.7922C16.0863 14.7382 16.1516 14.6608 16.1945 14.5686C16.2374 14.4765 16.2561 14.3731 16.2487 14.27C16.2413 14.1669 16.208 14.068 16.1524 13.9843Z"
      fill="#73BBD3"
    />
  </svg>
);

export const NotesIcon = () => (
  <svg 
    width="16"
    height="16" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" fill="white"/>
    <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z" stroke="#BFBFBF"/>
    <rect x="4" y="4" width="8" height="1.71429" rx="0.857143" fill="#73BBD3"/>
    <rect x="4" y="7.42847" width="8" height="1.71429" rx="0.857143" fill="#73BBD3"/>
    <rect x="4" y="10.8571" width="5.45455" height="1.71429" rx="0.857143" fill="#FCD15C"/>
  </svg>
);

export const Close = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3871 11.9987L20.4183 3.61749C20.5362 3.47821 20.4371 3.2666 20.2549 3.2666H18.1174C17.9916 3.2666 17.871 3.32285 17.788 3.41928L11.9889 10.3327L6.18977 3.41928C6.10941 3.32285 5.98887 3.2666 5.8603 3.2666H3.7228C3.54066 3.2666 3.44155 3.47821 3.55941 3.61749L10.5907 11.9987L3.55941 20.38C3.53301 20.411 3.51607 20.449 3.51061 20.4894C3.50514 20.5298 3.51138 20.5709 3.52859 20.6078C3.54579 20.6448 3.57323 20.676 3.60766 20.6978C3.64209 20.7196 3.68205 20.7311 3.7228 20.7309H5.8603C5.98619 20.7309 6.10673 20.6746 6.18977 20.5782L11.9889 13.6648L17.788 20.5782C17.8683 20.6746 17.9889 20.7309 18.1174 20.7309H20.2549C20.4371 20.7309 20.5362 20.5193 20.4183 20.38L13.3871 11.9987Z"
      fill="#BFBFBF"
    />
  </svg>
);

export const Filter = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7254 15.9775H9.8038C8.72089 15.9775 7.84302 16.8437 7.84302 17.9122C7.84302 18.9807 8.72089 19.8468 9.8038 19.8468H13.7254C14.8083 19.8468 15.6862 18.9807 15.6862 17.9122C15.6862 16.8437 14.8083 15.9775 13.7254 15.9775Z"
      fill="#FBC633"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6471 8.23828H5.88242C4.7995 8.23828 3.92163 9.10445 3.92163 10.1729C3.92163 11.2414 4.7995 12.1076 5.88242 12.1076H17.6471C18.73 12.1076 19.6079 11.2414 19.6079 10.1729C19.6079 9.10445 18.73 8.23828 17.6471 8.23828Z"
      fill="#44A5C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5686 0.5H1.96078C0.877873 0.5 0 1.36617 0 2.43464C0 3.50311 0.877873 4.36928 1.96078 4.36928H21.5686C22.6515 4.36928 23.5294 3.50311 23.5294 2.43464C23.5294 1.36617 22.6515 0.5 21.5686 0.5Z"
      fill="#44A5C5"
    />
  </svg>
);

export const ProjectsIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9" width="7" height="1.5" rx="0.75" fill="#158EB6" />
    <rect x="14.5" width="1.5" height="7" rx="0.75" fill="#158EB6" />
    <rect x="9" y="14.5" width="7" height="1.5" rx="0.75" fill="#73BBD3" />
    <rect x="14.5" y="9" width="1.5" height="7" rx="0.75" fill="#73BBD3" />
    <rect
      x="7"
      y="16.0001"
      width="7"
      height="1.5"
      rx="0.75"
      transform="rotate(180 7 16.0001)"
      fill="#158EB6"
    />
    <rect
      x="1.5"
      y="16.0001"
      width="1.5"
      height="7"
      rx="0.75"
      transform="rotate(-180 1.5 16.0001)"
      fill="#158EB6"
    />
    <rect
      x="7"
      y="1.50012"
      width="7"
      height="1.5"
      rx="0.75"
      transform="rotate(180 7 1.50012)"
      fill="#73BBD3"
    />
    <rect
      x="1.5"
      y="7.00012"
      width="1.5"
      height="7"
      rx="0.75"
      transform="rotate(-180 1.5 7.00012)"
      fill="#73BBD3"
    />
    <path
      d="M7.44 9.84L10.26 7.02L9.7 6.46L7.44 8.72L6.3 7.58L5.74 8.14L7.44 9.84ZM8 12C7.44667 12 6.92667 11.895 6.44 11.685C5.95333 11.475 5.53 11.19 5.17 10.83C4.81 10.47 4.525 10.0467 4.315 9.56C4.105 9.07333 4 8.55333 4 8C4 7.44667 4.105 6.92667 4.315 6.44C4.525 5.95333 4.81 5.53 5.17 5.17C5.53 4.81 5.95333 4.525 6.44 4.315C6.92667 4.105 7.44667 4 8 4C8.55333 4 9.07333 4.105 9.56 4.315C10.0467 4.525 10.47 4.81 10.83 5.17C11.19 5.53 11.475 5.95333 11.685 6.44C11.895 6.92667 12 7.44667 12 8C12 8.55333 11.895 9.07333 11.685 9.56C11.475 10.0467 11.19 10.47 10.83 10.83C10.47 11.19 10.0467 11.475 9.56 11.685C9.07333 11.895 8.55333 12 8 12Z"
      fill="#FADB14"
    />
  </svg>
);

export const TableIcons = {
  edit: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.625 19.5938H3.375C2.96016 19.5938 2.625 19.9289 2.625 20.3438V21.1875C2.625 21.2906 2.70937 21.375 2.8125 21.375H21.1875C21.2906 21.375 21.375 21.2906 21.375 21.1875V20.3438C21.375 19.9289 21.0398 19.5938 20.625 19.5938ZM6.03984 17.625C6.08672 17.625 6.13359 17.6203 6.18047 17.6133L10.1227 16.9219C10.1695 16.9125 10.2141 16.8914 10.2469 16.8563L20.182 6.92109C20.2038 6.89941 20.221 6.87366 20.2328 6.8453C20.2445 6.81695 20.2506 6.78656 20.2506 6.75586C20.2506 6.72516 20.2445 6.69477 20.2328 6.66642C20.221 6.63806 20.2038 6.61231 20.182 6.59063L16.2867 2.69297C16.2422 2.64844 16.1836 2.625 16.1203 2.625C16.057 2.625 15.9984 2.64844 15.9539 2.69297L6.01875 12.6281C5.98359 12.6633 5.9625 12.7055 5.95312 12.7523L5.26172 16.6945C5.23892 16.8201 5.24707 16.9493 5.28545 17.071C5.32384 17.1927 5.39132 17.3032 5.48203 17.393C5.63672 17.543 5.83125 17.625 6.03984 17.625ZM7.61953 13.5375L16.1203 5.03906L17.8383 6.75703L9.3375 15.2555L7.25391 15.6234L7.61953 13.5375Z"
        fill="#8C8C8C"
      />
    </svg>
  ),
  delete: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6H17.25V4.125C17.25 3.29766 16.5773 2.625 15.75 2.625H8.25C7.42266 2.625 6.75 3.29766 6.75 4.125V6H3.75C3.33516 6 3 6.33516 3 6.75V7.5C3 7.60313 3.08437 7.6875 3.1875 7.6875H4.60312L5.18203 19.9453C5.21953 20.7445 5.88047 21.375 6.67969 21.375H17.3203C18.1219 21.375 18.7805 20.7469 18.818 19.9453L19.3969 7.6875H20.8125C20.9156 7.6875 21 7.60313 21 7.5V6.75C21 6.33516 20.6648 6 20.25 6ZM8.4375 4.3125H15.5625V6H8.4375V4.3125ZM17.1398 19.6875H6.86016L6.29297 7.6875H17.707L17.1398 19.6875Z"
        fill="#8C8C8C"
      />
    </svg>
  ),
  parent: () => (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Parent Location</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="table/-row/-parent/-default"
          transform="translate(-42.000000, -18.000000)"
        >
          <g
            id="icons/-hierarchy/parent"
            transform="translate(42.000000, 18.000000)"
          >
            <g id="hierarchy-54">
              <path
                d="M15.8571429,8.57142857 L9.42857143,8.57142857 L9.42857143,6.42857143 C9.42857143,6.19168714 9.23667857,6 9,6 C8.76332143,6 8.57142857,6.19168714 8.57142857,6.42857143 L8.57142857,8.57142857 L2.14285714,8.57142857 C1.90617857,8.57142857 1.71428571,8.76311571 1.71428571,9 L1.71428571,11.5714286 C1.71428571,11.8083129 1.90617857,12 2.14285714,12 C2.37953571,12 2.57142857,11.8083129 2.57142857,11.5714286 L2.57142857,9.42857143 L8.57142857,9.42857143 L8.57142857,11.5714286 C8.57142857,11.8083129 8.76332143,12 9,12 C9.23667857,12 9.42857143,11.8083129 9.42857143,11.5714286 L9.42857143,9.42857143 L15.4285714,9.42857143 L15.4285714,11.5714286 C15.4285714,11.8083129 15.6204643,12 15.8571429,12 C16.0938214,12 16.2857143,11.8083129 16.2857143,11.5714286 L16.2857143,9 C16.2857143,8.76311571 16.0938214,8.57142857 15.8571429,8.57142857 Z"
                id="Path"
                fill="#CDCDCD"
              ></path>
              <path
                d="M10.7142857,13.7142857 L7.28571429,13.7142857 C7.04903571,13.7142857 6.85714286,13.9059729 6.85714286,14.1428571 L6.85714286,17.5714286 C6.85714286,17.8083129 7.04903571,18 7.28571429,18 L10.7142857,18 C10.9509643,18 11.1428571,17.8083129 11.1428571,17.5714286 L11.1428571,14.1428571 C11.1428571,13.9059729 10.9509643,13.7142857 10.7142857,13.7142857 Z"
                id="Path"
                fill="#FBC633"
              ></path>
              <path
                d="M17.5714286,13.7142857 L14.1428571,13.7142857 C13.9061786,13.7142857 13.7142857,13.9059729 13.7142857,14.1428571 L13.7142857,17.5714286 C13.7142857,17.8083129 13.9061786,18 14.1428571,18 L17.5714286,18 C17.8081071,18 18,17.8083129 18,17.5714286 L18,14.1428571 C18,13.9059729 17.8081071,13.7142857 17.5714286,13.7142857 Z"
                id="Path"
                fill="#FBC633"
              ></path>
              <path
                d="M3.85714286,13.7142857 L0.428571429,13.7142857 C0.191892857,13.7142857 0,13.9059729 0,14.1428571 L0,17.5714286 C0,17.8083129 0.191892857,18 0.428571429,18 L3.85714286,18 C4.09382143,18 4.28571429,17.8083129 4.28571429,17.5714286 L4.28571429,14.1428571 C4.28571429,13.9059729 4.09382143,13.7142857 3.85714286,13.7142857 Z"
                id="Path"
                fill="#FBC633"
              ></path>
              <circle
                id="Oval"
                fill="#44A5C5"
                cx="9"
                cy="2.14285714"
                r="2.14285714"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  child: () => (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.2168V0.55013H-8.9407e-08"
        stroke="#CDCDCD"
        strokeLinejoin="round"
        strokeDasharray="1.75 1.75"
      />
      <rect
        x="0.5"
        y="5.7168"
        width="13"
        height="8.33333"
        rx="0.5"
        fill="#FCD15C"
        stroke="#FCD15C"
      />
    </svg>
  ),
  virtual: () => (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.950195"
        width="8.33333"
        height="8.33333"
        rx="0.5"
        stroke="#FCD15C"
      />
      <rect
        x="5.16675"
        y="5.61719"
        width="8.33333"
        height="8.33333"
        rx="0.5"
        stroke="#FCD15C"
      />
      <rect
        x="5.16675"
        y="5.61719"
        width="3.66667"
        height="3.66667"
        rx="0.5"
        fill="#FCD15C"
        stroke="#FCD15C"
      />
    </svg>
  ),
};

export const Priorities = {
  all: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#CDCDCD" />
      <rect width="12" height="12" transform="translate(4 4)" fill="#CDCDCD" />
      <circle cx="10" cy="10" r="5" stroke="white" strokeWidth="2" />
    </svg>
  ),
  highest: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365694)">
        <rect width="20" height="20" rx="2" fill="#CE0000" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0632 6.1673C12.9912 6.07915 12.9274 6.0001 12.8736 5.93045C12.8072 5.84378 12.6982 5.80109 12.5906 5.81959C12.483 5.83809 12.3945 5.91474 12.3608 6.01861C12.1991 6.51243 11.9531 6.97455 11.6338 7.38448C11.6526 6.21726 11.4214 4.15758 9.72988 3.04825C9.63941 2.98883 9.52361 2.98394 9.42846 3.03554C9.33331 3.08714 9.27423 3.18686 9.27468 3.29509C9.27468 4.25779 8.75896 5.28366 7.86238 6.10883C6.9949 6.90754 4.9793 9.01836 5.00016 11.3566C5.01485 12.9846 5.84384 15.2356 7.94672 16.1292C7.97816 15.1075 8.42395 14.3819 8.86004 13.6825C9.27439 13.0172 9.66611 12.3895 9.70196 11.5112C9.70654 11.3961 9.7779 11.2944 9.88451 11.2508C9.99111 11.2073 10.1133 11.23 10.1971 11.309C11.3823 12.4257 12.0173 14.0801 12.0485 16.099C13.5568 15.4557 14.7922 14.0537 14.9744 11.773C15.2098 8.81119 13.7599 7.02538 13.0632 6.1673Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365694">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  high: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365698)">
        <rect width="20" height="20" rx="2" fill="#F2722C" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 14.3421C10.8284 14.3421 11.5 15.049 11.5 15.9211C11.5 16.7931 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.7931 8.5 15.9211C8.5 15.049 9.17157 14.3421 10 14.3421ZM10.9519 2.5C11.2029 2.5 11.4064 2.70351 11.4064 2.95455C11.4064 2.96456 11.4061 2.97458 11.4054 2.98457L10.7696 12.589C10.7538 12.8278 10.5554 13.0135 10.316 13.0135H9.6859C9.44655 13.0135 9.24821 12.8279 9.23236 12.589L8.59468 2.98466C8.57805 2.73417 8.76763 2.51763 9.01811 2.501L10.9519 2.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365698">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  medium: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365702)">
        <rect width="20" height="20" rx="2" fill="#FBC633" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11.4C14.5523 11.4 15 11.8477 15 12.4V12.6C15 13.1523 14.5523 13.6 14 13.6H6C5.44772 13.6 5 13.1523 5 12.6V12.4C5 11.8477 5.44772 11.4 6 11.4H14ZM14 7C14.5523 7 15 7.44772 15 8V8.2C15 8.75228 14.5523 9.2 14 9.2H6C5.44772 9.2 5 8.75228 5 8.2V8C5 7.44772 5.44772 7 6 7H14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365702">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  low: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365706)">
        <rect width="20" height="20" rx="2" fill="#A1D2E2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9514 10.4651C13.8759 10.316 13.7227 10.2222 13.5556 10.2222H10.8889V4.44444C10.8889 4.19898 10.6899 4 10.4445 4H9.55555C9.31009 4 9.1111 4.19898 9.1111 4.44444V10.2222H6.4444C6.27729 10.2222 6.12408 10.316 6.04856 10.4651C5.97261 10.6139 5.98649 10.793 6.08502 10.9282L9.64062 15.8171C9.72439 15.9319 9.85764 16 10 16C10.1424 16 10.2756 15.9319 10.3594 15.8171L13.915 10.9282C14.0135 10.793 14.0274 10.6139 13.9514 10.4651Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365706">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
} as const;
