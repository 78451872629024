import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ChecklistItemContext } from '../../contexts';
import {
  FlexContainer,
  FlexItem,
  TextInput,
  Error,
  TaskLabel,
} from '../../elements';
import SelectType from '../SelectType';
import {
  CHECKLIST_ACTION_STATES,
  CHECKLIST_TASK_FIELDS,
} from '../../taskConstants';

const errorMessageComponent = ({
  errorMessage,
}: {
  errorMessage: ReactNode;
}) => <Error>{errorMessage}</Error>;

function ReadOnlyTaskType() {
  const [focus, setFocus] = useState(false);
  const {
    formValues,
    onFormChange,
    setActionState,
    readOnly,
    actionState,
    isFulfillment,
    isValid,
  } = useContext(ChecklistItemContext);
  const { t } = useTranslation(['common', 'checklist']);

  const canEdit = actionState === CHECKLIST_ACTION_STATES.addingItem;

  const goToView = useCallback(() => {
    if (formValues.label !== '') {
      setActionState(CHECKLIST_ACTION_STATES.viewingItem);
      setFocus(false);
    }
  }, [setActionState, setFocus, formValues]);

  const goToEdit = useCallback(() => {
    if (
      !readOnly &&
      actionState !== CHECKLIST_ACTION_STATES.fulfillment &&
      !isFulfillment
    ) {
      setActionState(CHECKLIST_ACTION_STATES.addingItem);
      setFocus(true);
    }
  }, [readOnly, actionState, isFulfillment, setActionState]);

  const onChangeTaskName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onFormChange({
        name: CHECKLIST_TASK_FIELDS.label,
        value: event.target.value,
      });
    },
    [onFormChange],
  );

  const taskInputValue = useMemo(
    () => formValues[CHECKLIST_TASK_FIELDS.label as keyof typeof formValues],
    [formValues],
  );

  const errorMessage = t('checklist:Field Name is required');

  return (
    <FlexContainer data-testid="task-type-container">
      <FlexContainer col>
        <FlexContainer>
          <FlexItem>
            {canEdit ? (
              <>
                <TextInput
                  name={CHECKLIST_TASK_FIELDS.label}
                  placeholder={t('checklist:Add Task')}
                  value={taskInputValue as string}
                  tabIndex={0}
                  onChange={onChangeTaskName}
                  onBlur={goToView}
                  autoFocus={focus}
                  data-testid="checklist-task-input"
                />
                {!isValid && errorMessageComponent({ errorMessage })}
              </>
            ) : (
              <TaskLabel onClick={goToEdit}>
                {taskInputValue as string}
              </TaskLabel>
            )}
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
      {!isFulfillment && <SelectType />}
    </FlexContainer>
  );
}

export default ReadOnlyTaskType;
