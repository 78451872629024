import {
  GA_EVENTS,
  GoogleAnalyticsClient,
  useDebounce,
  LegacyField as Field,
} from '@optii/shared';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import {
  RequiredFlag as CustomRequiredFlag,
  FlexContainer,
  FlexItem,
} from '../../elements';
import { ChecklistItemContext } from '../../contexts';
import SelectType from '../SelectType';
import TaskNameField, { ErrorMessage } from '../TaskNameField';
import { CHECKLIST_TASK_FIELDS, CHECKLIST_TYPES } from '../../taskConstants';

const RequiredFlag = styled(CustomRequiredFlag as 'div')`
  position: absolute;
  margin-left: -1.4rem;
  margin-top: 1.8rem;
`;

const TaskField = styled(Field as any)<{ readOnly: boolean }>`
  margin-top: ${(props) => (props.readOnly ? '0px' : '5px')};
`;

function NumericalType() {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    isFulfillment,
    formValues,
    isValid,
    isReadOnly,
  } = useContext(ChecklistItemContext);
  const { t } = useTranslation(['common', 'checklist']);
  const [touched, setTouched] = useState(false);

  const taskType = useMemo(() => {
    if (formValues.taskType === CHECKLIST_TYPES.numerical)
      return CHECKLIST_TASK_FIELDS.dataNumerical;
    return CHECKLIST_TASK_FIELDS.dataText;
  }, [formValues]);

  const [value, setValue] = useState<string>(
    fulfillmentValues[taskType as keyof typeof fulfillmentValues],
  );
  const debouncedValue = useDebounce(value);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    GoogleAnalyticsClient.event(GA_EVENTS.numericTaskValueChange);
    setTouched(() => true);
    setValue(event.target.value);
  };

  const isRequired =
    String(
      formValues[CHECKLIST_TASK_FIELDS.required as keyof typeof formValues],
    ) === 'true';
  const readOnly = !isFulfillment || isReadOnly;

  const isInvalidNumericField = useMemo(
    () =>
      formValues.taskType === CHECKLIST_TYPES.numerical &&
      typeof value === 'string' &&
      value.replace('-', '').replace(/[0-9.,]/g, '').length > 0 &&
      Number.isNaN(Number(value.replace(/[.,]/g, ''))),
    [formValues, value],
  );

  useEffect(() => {
    if (!isInvalidNumericField && touched)
      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataNumerical]: debouncedValue,
      });
  }, [debouncedValue]);

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container" col>
      <FlexContainer>
        <FlexItem>
          <TaskNameField />
        </FlexItem>
        {!isFulfillment && <SelectType />}
      </FlexContainer>
      <FlexContainer>
        {isRequired && <RequiredFlag />}

        <FlexItem>
          <TaskField
            name={taskType}
            placeholder={t('checklist:Enter Number')}
            value={value}
            tabIndex={isFulfillment ? 0 : -1}
            readOnly={readOnly}
            onChange={onChange}
            data-testid="checklist-task-add-name"
          />
          {!isValid && (
            <ErrorMessage errorMessage={t('checklist:Required field')} />
          )}
          {isInvalidNumericField && (
            <ErrorMessage errorMessage={t('checklist:Numbers only')} />
          )}
        </FlexItem>
      </FlexContainer>
    </FlexContainer>
  );
}

export default NumericalType;
