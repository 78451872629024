import React from 'react';
import { ButtonQuaternaryStyled } from './styles';

const ButtonQuaternary: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
  return <ButtonQuaternaryStyled {...props}>{children}</ButtonQuaternaryStyled>;
};

export default ButtonQuaternary;
