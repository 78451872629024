import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ConfigProvider,
  Flex,
  Input,
  Select,
  SPACING,
  Grid,
  COLORS,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { Conversation, JSONValue } from '@twilio/conversations';
import {
  PERMISSIONS,
  Session,
  useAccess,
  UserAccessContext,
} from '@optii/shared';
import { isPlatform } from '@ionic/react';
import { ChatContext } from '../../context/chat.context';
import { ConversationActions } from '../../types';
import { PRIVATE_CHAT_REGEX } from '../../constants';
import { generateAttributes, sendMessage } from '../../utils/message';

type Participants = {
  participantsToAdd: string[];
  participantsToRemove: string[];
};

type Props = {
  action: ConversationActions;
  channel?: Conversation;
  setAction: Dispatch<SetStateAction<ConversationActions | undefined>>;
};

export function ChannelActions({ action, channel, setAction }: Props) {
  const { employees, chatEmployeeIds, setChannels } = useContext(ChatContext);
  const { user } = useContext(UserAccessContext.Context);
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['chat', 'common']);
  const { can } = useAccess();
  const currentEmployees = channel?.uniqueName?.split('_');

  const { useBreakpoint } = Grid;
  const isIOS = isPlatform('ios');
  const { xs } = useBreakpoint();

  const [selectedEmployees, setSelectedEmployees] = useState(
    currentEmployees?.filter((item) => item !== user?.id),
  );
  const employeeNames = employees
    .filter((item) => selectedEmployees?.includes(item.value))
    .map((employee) => employee.label)
    .sort()
    .join(', ');
  const [participants, setParticipants] = useState<Participants>({
    participantsToAdd: [],
    participantsToRemove: [],
  });
  const [name, setName] = useState(
    channel?.friendlyName?.match(PRIVATE_CHAT_REGEX)
      ? employeeNames
      : channel?.friendlyName || '',
  );
  const [loading, setLoading] = useState(false);

  const canAccessAllTeamMembers = can(
    PERMISSIONS.communications.accessAllTeamMembers,
  );

  const availableEmployees = useMemo(
    () =>
      employees
        .map((employee) => ({
          label: employee.label,
          value: employee.value,
        }))
        .filter((employee) => employee.value !== user?.id)
        .filter((employee) =>
          canAccessAllTeamMembers
            ? true
            : chatEmployeeIds.includes(employee.value),
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [employees, chatEmployeeIds, canAccessAllTeamMembers, user?.id],
  );

  const updateChannelName = () => {
    setChannels((prev) => ({
      publicChannels: prev.publicChannels,
      privateChannels: prev.privateChannels
        .map((privateChannel) => {
          if (privateChannel.key === channel?.sid)
            return {
              ...privateChannel,
              label:
                action === ConversationActions.Rename
                  ? name || employeeNames
                  : name || employeeNames,
            };

          return privateChannel;
        })
        .sort((a, b) => {
          if (a.label && b.label) return a.label.localeCompare(b.label);
          return 0;
        }),
    }));
    setLoading(false);
  };

  const sendParticipantAddedMessage = useCallback(
    async (names: string, numberOfParticipantsToAdd: number) => {
      const attributes = generateAttributes(
        [],
        true,
        channel,
        user,
      ) as JSONValue;

      const message = `${names} ${numberOfParticipantsToAdd > 1 ? 'were' : 'was'} added to ${channel?.friendlyName?.match(PRIVATE_CHAT_REGEX) ? 'the channel' : name} by ${user?.firstName} ${user?.lastName}`;
      await sendMessage(
        () => {},
        [],
        t,
        attributes,
        channel,
        message,
        () => {},
      );
    },
    [channel, user, t, name],
  );

  async function onSave() {
    setLoading(true);
    if (action === ConversationActions.Rename && channel?.uniqueName) {
      await channel?.updateFriendlyName(name || channel?.uniqueName);

      setAction(undefined);
      updateChannelName();
    }

    if (action === ConversationActions.Edit && !!selectedEmployees?.length) {
      const newUniqueName = selectedEmployees
        .concat([user?.id || ''])
        .join('_');
      await channel?.updateUniqueName(newUniqueName);
      if (channel?.friendlyName?.match(PRIVATE_CHAT_REGEX))
        await channel?.updateFriendlyName(newUniqueName);

      try {
        await Promise.all([
          participants.participantsToAdd.forEach((participant) =>
            channel?.add(participant),
          ),
          participants.participantsToRemove.forEach((participant) =>
            channel?.removeParticipant(participant),
          ),
        ]);

        const addedParticipantsName = availableEmployees
          .filter((item) => participants.participantsToAdd.includes(item.value))
          .map((participant) => participant.label)
          .join(', ');

        if (addedParticipantsName) {
          globalSnack({
            message: t('chat:{{participants}} added to the conversation', {
              participants: addedParticipantsName,
            }),
          });
          updateChannelName();
          sendParticipantAddedMessage(
            addedParticipantsName,
            participants.participantsToAdd.length,
          );
        }
        setAction(undefined);
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            fontFamily: 'Roboto',
            colorTextPlaceholder: COLORS.neutral[6],
          },
        },
      }}
    >
      <Flex
        style={{
          width: '100%',
          marginBlock: SPACING.SIZE_SM,
          marginTop: isIOS ? SPACING.SIZE_MD : SPACING.SIZE_SM,
        }}
        wrap={xs ? 'wrap' : undefined}
        align="center"
        gap={SPACING.SIZE_SM}
      >
        {action === 'rename' ? (
          <Input
            value={name}
            onChange={({ target: { value } }) => {
              setName(value);
            }}
            placeholder={t('chat:Add a custom channel name')}
          />
        ) : (
          <Select
            style={{
              width: '100%',
              fontWeight: '400',
            }}
            onSelect={(value: string) => {
              setParticipants((prev) => ({
                participantsToAdd: prev.participantsToAdd
                  .concat(value)
                  .filter(
                    (participant) => !currentEmployees?.includes(participant),
                  ),

                participantsToRemove: prev.participantsToRemove.filter(
                  (item) => item !== value,
                ),
              }));
              setSelectedEmployees((prev) => prev?.concat(value));
            }}
            onDeselect={(value: string) => {
              setSelectedEmployees((prev) =>
                prev?.filter((item) => item !== value),
              );

              setParticipants((prev) => ({
                participantsToAdd: prev.participantsToAdd.filter(
                  (item) => item !== value,
                ),
                participantsToRemove: prev.participantsToRemove
                  .concat([value])
                  .filter((participant) =>
                    currentEmployees?.includes(participant),
                  ),
              }));
            }}
            allowClear
            mode="multiple"
            value={selectedEmployees}
            options={availableEmployees}
            placeholder={t('common:Select Team Members')}
            optionFilterProp="label"
            showSearch
          />
        )}

        <Flex align="center" gap={SPACING.SIZE_SM}>
          <Button
            onClick={() => {
              setAction(undefined);
            }}
            type="text"
          >
            {t('common:Cancel')}
          </Button>
          <Button
            onClick={onSave}
            type="primary"
            loading={loading}
            disabled={loading}
          >
            {t('common:Save')}
          </Button>
        </Flex>
      </Flex>
    </ConfigProvider>
  );
}
